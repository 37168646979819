import { toast } from "react-toastify";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import * as HELPER from "../../../../config/helper";
import { useSelector } from "react-redux";
import { useSectionList } from "@src/hooks/useSectionList";
import InsuranceService from "@src/services/InsuranceService";
import {
    APP_TYPE_AGENCY, INS_TYPE_COMPREHENSIVE, INS_TYPE_CTPL,
    LEAD_TYPE_WEB_APP, REGEX_DECIMAL_VALIDATION,
    REGEX_DECIMAL_VALIDATION_8,
    WITHOUT_RULE_ENGINE
} from "@src/config/constant";

type PlanProps = {
    setLoading: Function,
    setPage: Function,
    setActiveKey: Function,
    agency_id:number
}

export const Plans = forwardRef((props: PlanProps, ref) => {
    let { setLoading } = props
    const { fetchSectionItem, fetchItem } = useSectionList({})

    const [inputField, setInputField] = useState<any>({})
    const vehicleDetails = fetchSectionItem('vehicle_details', 'vehicle_info')
    const [editField, setEditField] = useState<any>(null)
    const [value, setValue] = useState(0)
    const [tempBaseRate, setTempBaseRate] = useState(inputField['base_issue_rate'])

    const insurance = useSelector((state: any) => state.insurance)
    let { insurance_type, rule_engine,
        partner_id, base_issue_rate,
        addon_detail } = insurance?.createInsuranceAgency

    const fetchVehicleItem = (key: string) => {
        const field = fetchItem(key, vehicleDetails?.fields || [])
        return typeof field.value === 'object' ? field?.value?.value : field.value
    }

    const fetchPlans = async (reqParam?: any) => {
        setLoading(true)
        let flag = true
        let editableFields = ['discount', 'add_on_rate']
        if (rule_engine === WITHOUT_RULE_ENGINE) editableFields.push('net_premium')
        let params: any = {
            vehicle_usage: fetchVehicleItem('vehicle_usage'),
            insurance_type,
            rule_engine,
            total_fmv: fetchVehicleItem('total_fmv'),
            lead_type: LEAD_TYPE_WEB_APP,
            role_id: 3,
            apptype: APP_TYPE_AGENCY,
            addon_detail: addon_detail,
            vehicle_type: fetchVehicleItem('vehicle_type'),
            make_year: fetchVehicleItem('make_year'),
            partner_id,
            agency_id:props.agency_id

        }
        if (params.insurance_type === INS_TYPE_CTPL) {
            params['tenure'] = fetchVehicleItem('tenure')
        }

        editableFields.forEach((field: any) => {
            if (reqParam?.[field] || insurance?.createInsuranceAgency?.[field === 'add_on_rate' ? 'base_issue_rate' : field]) {
                if (field === 'net_premium' && !reqParam?.[field]) { }
                else {
                    params[field] = reqParam?.[field] || insurance?.createInsuranceAgency?.[field === 'add_on_rate' ? 'base_issue_rate' : field]
                }
            }
        })
        let result = await InsuranceService.getAddonDetails(params)
        let message: any = null
        if (result?.data?.data) {
            //save total premium in state for installment validation
            // let { total_premium, base_issue_rate, discount, net_premium } = result.data.data
            setInputField({ ...result.data.data, validation: true })
            setValue((prev: any) => prev + 1)
            setTempBaseRate(result?.data?.data?.base_issue_rate)

        } else {
            message = result.data.message
            flag = false
            toast.error(message)
            setInputField((prev: any) => ({ ...prev, validation: false }))
            //if first time api call & no valid response
            //then redirect back to addons page
            if (value === 0) {
                props.setPage(1)
                props.setActiveKey('1')
            }
        }
        setLoading(false)
        return new Promise((resolve) => resolve({ checkValue: flag, message }))
    }

    const handleAmountChange = async (key: string, value: any) => {
        if (key === 'temp_base_rate') {
            if ((REGEX_DECIMAL_VALIDATION_8.test(value)) || REGEX_DECIMAL_VALIDATION.test(value) || value === '') {
                setTempBaseRate(value)
            }
            return
        }

        if ((key === "add_on_rate" && REGEX_DECIMAL_VALIDATION_8.test(value)) || REGEX_DECIMAL_VALIDATION.test(value) || value === '') {
            let { basic_rate_min, basic_rate_max, discount, base_issue_rate } = inputField

            const fields: any = {
                discount,
                add_on_rate: +base_issue_rate,
            }

            // if (rule_engine === WITHOUT_RULE_ENGINE) fields['net_premium'] = net_premium
            //for handling discount
            // if (key === 'discount') {
            //     let response: any = null
            //     let editFlag = true
            //     if (+value >= 10) {
            //         response = await fetchPlans({ ...fields, discount: +value })
            //         if (!response?.checkValue) {
            //             editFlag = false
            //         }
            //     }
            //     if (editFlag) {
            //         setInputField((prev: any) => ({ ...prev, discount: +value }))
            //     }
            // }

            //for addonrate
            if (key === 'add_on_rate') {
                if ((value && value[value?.length - 1] === '.') || !value || +value === 0 || (value && value[value?.length - 1] === '0')) {
                    setInputField((prev: any) => ({ ...prev, base_issue_rate: value }))
                    return
                }

                if (+value !== 0 && (+value < basic_rate_min || +value > basic_rate_max)) {
                    toast.error(`Base issue rate should be between ${basic_rate_min} - ${basic_rate_max}`)
                    return
                }
                fetchPlans({ ...fields, add_on_rate: +value })

            }

            if (key === 'net_premium') {
                fetchPlans({ ...fields, net_premium: +value })
            }
        }
    }

    useEffect(() => {
        fetchPlans()
        // eslint-disable-next-line
    }, [])

    useImperativeHandle(ref, () => ({
        getData: () => inputField
    }))

    return (
        <>
            {
                insurance_type !== INS_TYPE_CTPL
                    ?
                    <div className="row p-lg-t">
                        <fieldset className={'col-md-6 p-sm-t'}>
                            <div className="material">
                                {
                                    <input
                                        maxLength={10}
                                        disabled={false}
                                        type='text'
                                        placeholder=" "
                                        name={'Base Issue Rate'}
                                        className="form-input"
                                        value={tempBaseRate || ''}
                                        onChange={(event) => {
                                            const text = event.target.value
                                            handleAmountChange('temp_base_rate', text)
                                        }}
                                    // onBlur={() => {
                                    //     if (!inputField['base_issue_rate'] || inputField['base_issue_rate'] === 0) {
                                    //         setInputField((prev: any) => ({ ...prev, base_issue_rate }))
                                    //     }
                                    // }}
                                    />
                                }
                                <label data-label={'Base Issue Rate *'} className="form-label"></label>
                            </div>
                        </fieldset>
                        <div className="col-md-2">
                            <button className="btn-primary quote-apply-btn" onClick={() => {
                                handleAmountChange('add_on_rate', tempBaseRate)
                            }}>Apply</button>
                        </div>
                    </div>
                    : null
            }
            <div className='financer-quote-data w-100'>
                <div className="agency-quote-list">
                    <ul>
                        {
                            insurance_type === INS_TYPE_COMPREHENSIVE ?
                                <li>
                                    <span>Act of Nature</span>
                                    <label>{(addon_detail?.filter((el: any) => el.id === 6).length > 0) ? `Included` : `Not Included`}</label>
                                </li> : null
                        }
                        <li>
                            <span>FMV of Car</span>
                            <label title={`${HELPER.addCurrencyFormatting(inputField.total_fmv)}`}>  {HELPER.addCurrencyFormatting(inputField.total_fmv)}</label>
                        </li>

                        <li>
                            <span>Own Theft Damage</span>
                            <label title={`${HELPER.addCurrencyFormatting(inputField.own_theft_damage)}`}>  {HELPER.addCurrencyFormatting(inputField.own_theft_damage)}</label>
                        </li>

                        {
                            inputField.bi_coverage &&
                            <li>
                                <span>BI Coverage Amount</span>
                                <label title={`${HELPER.addCurrencyFormatting(inputField.bi_coverage)}`}>  {HELPER.addCurrencyFormatting(inputField.bi_coverage)}</label>
                            </li>
                        }

                        <li>
                            <span>BI Premium</span>
                            <label title={`${HELPER.addCurrencyFormatting(inputField.bi_amt)}`}>  {HELPER.addCurrencyFormatting(inputField.bi_amt)}</label>
                        </li>
                        {
                            inputField.hasOwnProperty('pa_amt') && inputField.pa_amt > 0 ?
                                <>
                                    {
                                        inputField.pa_coverage &&
                                        <li>
                                            <span>PA Coverage Amount</span>
                                            <label title={`${HELPER.addCurrencyFormatting(inputField.pa_coverage)}`}>  {HELPER.addCurrencyFormatting(inputField.pa_coverage)}</label>
                                        </li>
                                    }

                                    {
                                        inputField?.total_coverage_amount_pa ?
                                            <li>
                                                <span>Total Coverage Amount (PA)</span>
                                                <label title={`${HELPER.addCurrencyFormatting(inputField?.total_coverage_amount_pa)}`}>  {HELPER.addCurrencyFormatting(inputField?.total_coverage_amount_pa)}</label>
                                            </li>
                                            : null
                                    }
                                    <li>
                                        <span>PA Premium</span>
                                        <label title={`${HELPER.addCurrencyFormatting(inputField.pa_amt)}`}>  {HELPER.addCurrencyFormatting(inputField.pa_amt)}</label>
                                    </li>
                                </>
                                : null
                        }
                        {
                            inputField.hasOwnProperty('pd_amt') ?
                                <>
                                    {
                                        inputField.pd_coverage &&
                                        <li>
                                            <span>PD Coverage Amount</span>
                                            <label title={`${HELPER.addCurrencyFormatting(inputField.pd_coverage)}`}>  {HELPER.addCurrencyFormatting(inputField.pd_coverage)}</label>
                                        </li>
                                    }
                                    <li>
                                        <span>PD Premium</span>
                                        <label title={`${HELPER.addCurrencyFormatting(inputField.pd_amt)}`}>  {HELPER.addCurrencyFormatting(inputField.pd_amt)}</label>
                                    </li>
                                </>
                                : null
                        }
                        {
                            (inputField.hasOwnProperty('tra_amt')) ?
                                <li>
                                    <span>TRA Premium</span>
                                    <label title={`${HELPER.addCurrencyFormatting(inputField.tra_amt)}`}>  {HELPER.addCurrencyFormatting(inputField.tra_amt)}</label>
                                </li> : null
                        }
                        {
                            (inputField.hasOwnProperty('rscc_amount')) ?
                                <li>
                                    <span>RSCC Premium</span>
                                    <label title={`${HELPER.addCurrencyFormatting(inputField.rscc_amount)}`}>  {HELPER.addCurrencyFormatting(inputField.rscc_amount)}</label>
                                </li> : null
                        }
                        {
                            insurance_type === INS_TYPE_COMPREHENSIVE ?
                                <li>
                                    <span>{inputField.hasOwnProperty('rscc_amount') ? 'BI + PD + PA + RSCC' : 'BI + PD + PA'}</span>
                                    <label title={`${HELPER.addCurrencyFormatting(inputField.bi_pd_pa)}`}>  {HELPER.addCurrencyFormatting(inputField.bi_pd_pa)}</label>
                                </li> : null
                        }

                        <li>
                            <span>Net Premium</span>
                            {
                                editField === 'net_premium' ?
                                    <label>
                                        <fieldset>
                                            <div className="material">
                                                <input type="number" name={'net_premium'} className="form-input input-edit" value={+inputField.net_premium || ''} onChange={(e) => {
                                                    handleAmountChange('net_premium', e.target.value)
                                                }} onBlur={() => setEditField(null)} />
                                            </div>
                                        </fieldset>
                                    </label>
                                    :
                                    <label>  {HELPER.addCurrencyFormatting(+inputField.net_premium)}
                                        {insurance_type === INS_TYPE_COMPREHENSIVE && rule_engine === WITHOUT_RULE_ENGINE &&
                                            <i className='ic-edit pointer edit-icon' onClick={() => setEditField('net_premium')}></i>
                                        }
                                    </label>
                            }
                        </li>

                        <li>
                            <span>VAT</span>
                            <label title={`${HELPER.addCurrencyFormatting(inputField.vat)}`}>  {HELPER.addCurrencyFormatting(inputField.vat)}</label>
                        </li>
                        <li>
                            <span>Stamp Duty Govt</span>
                            <label title={`${HELPER.addCurrencyFormatting(inputField.stamp_duty)}`}>  {HELPER.addCurrencyFormatting(inputField.stamp_duty)}</label>
                        </li>
                        <li>
                            <span>Local Govt Taxes</span>
                            <label title={`${HELPER.addCurrencyFormatting(inputField.local_govt_taxes)}`}>  {HELPER.addCurrencyFormatting(inputField.local_govt_taxes)}</label>
                        </li>
                        {
                            inputField.tariff > 0 &&
                            <li>
                                <span>Tariff</span>
                                <label title={`${HELPER.addCurrencyFormatting(inputField.tariff)}`}>  {HELPER.addCurrencyFormatting(inputField.tariff)}</label>
                            </li>
                        }

                        <li>
                            <span>Gross Premium</span>
                            <label title={`${HELPER.addCurrencyFormatting(inputField.gross_premium)}`}>  {HELPER.addCurrencyFormatting(inputField.gross_premium)}</label>
                        </li>
                        {
                            inputField.participation_fees &&
                            <li>
                                <span>Participation Fees</span>
                                <label title={`${HELPER.addCurrencyFormatting(inputField.participation_fees)}`}>  {HELPER.addCurrencyFormatting(inputField.participation_fees)}</label>
                            </li>
                        }
                        {/* <li>
                            <span>Discount to Customer</span>
                            {
                                editField === 'discount' ?
                                    <label>
                                        <fieldset>
                                            <div className="material">
                                                <input type="number" name={editField} className="form-input input-edit" value={inputField.discount}
                                                    onChange={async (e) => {
                                                        let text = e.target.value
                                                        text = text.replace(/^0+(?!$)/, "")
                                                        handleAmountChange('discount', text)
                                                    }}
                                                    onBlur={() => {
                                                        setEditField(null)
                                                        if (+inputField.discount !== 0 && +inputField.discount < 10) {
                                                            toast.error("Please enter a valid discount amount")
                                                            setInputField((prev: any) => ({ ...prev, discount: 0 }))
                                                        }
                                                    }} />
                                            </div>
                                        </fieldset>
                                    </label>
                                    :
                                    <label>  {HELPER.addCurrencyFormatting(inputField.discount)}
                                        {
                                            // inputField.partner_id === item.id &&
                                            <i className='ic-edit pointer edit-icon' onClick={() => {
                                                setEditField('discount')
                                            }}></i>
                                        }

                                    </label>
                            }
                        </li> */}
                        {
                            inputField.total_premium
                                ?
                                <li>
                                    <span>Total Premium</span>
                                    <label title={`${HELPER.addCurrencyFormatting(inputField.total_premium)}`}>
                                        {HELPER.addCurrencyFormatting((inputField.total_premium))}
                                    </label>
                                </li>
                                : null
                        }

                    </ul>
                </div>
            </div>
        </>
    )
})