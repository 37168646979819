import { Calendra2, ExpectedInstallmentDate } from '../../../common/DatePicker'; 
import { APP_TYPE_AGENCY, APP_TYPE_RENEWAL, APP_TYPE_STANDALONE, APP_TYPE_WITH_UCF, FIELD_TYPE, INS_TYPE_CTPL, PAYMENT_TERM_INSTALLMENT } from 'config/constant';
import React,{ useEffect, useState } from 'react';
import InsuranceService from 'services/InsuranceService';
import { getMMVList } from '@src/store/slices/common';
import { useSelector, useDispatch } from 'react-redux';
import ReactSelect from '@common/ReactSelect';
import {INS_TYPE_COMPREHENSIVE, WITHOUT_RULE_ENGINE} from '@src/config/constant';
import MultiSelect from '@src/common/MultiSelect';
import { updateInsuranceForm } from './FunctionList';
import { times } from 'lodash';
import { nthNumber, removeCurrencyFormatting } from '@src/config/helper';
import CurrencyInputField from '@src/common/CurrencyInputField';

const DynamicFormField = (props: any) => {
     /* eslint-disable @typescript-eslint/no-unused-vars */
    const [refresh, setRefresh] = useState(1) 
    const [localityList, setLocalityList] = useState([]);
   /* eslint-enable @typescript-eslint/no-unused-vars */
    const dispatch: any = useDispatch();
    const commonState = useSelector((state: any) => state.common);
    const insuranceState = useSelector((state: any) => state.insurance);
    const mmvData = (commonState.mmvList) ? commonState.mmvList : [] 
    const [typedValue, setTypedValue] = useState('');
    const [totalInstallments, setTotalInstallments] = useState(0);

    useEffect(() => {
        //WITH UCF CASE AND RENEWAL CASE
        if (props?.apptype !== APP_TYPE_STANDALONE && props?.callfrom === 'personal_details') {
            let is_renewal = props.apptype === APP_TYPE_RENEWAL ? true : false
            let customer_details = !is_renewal ? insuranceState?.activeLoanID?.customer_details : {
                per_city: +insuranceState?.leadDetail?.per_city,
                cur_city: +insuranceState?.leadDetail?.cur_city,
                per_barangay: +insuranceState?.leadDetail?.per_barangay,
                cur_barangay: +insuranceState?.leadDetail?.cur_barangay,
            }

            // if renewal & already set then no need to update again
            if(is_renewal && insuranceState?.renewalUpdateFlag?.customerDetails) {
                return
            }

            //for fetching per barangay
            if (customer_details['per_city']) {
                let city = props?.fields?.find((e: any) => e.name === "per_city")
                let isPerSameAsCur = customer_details['per_barangay'] === customer_details['cur_barangay']
                fetchPermanentLocality({ ...city, value: { value: `${customer_details['per_city']}` } }, { per_sameas_cur: isPerSameAsCur })
            }

            //for fetching cut barangay
            if (customer_details['cur_city'] && customer_details['per_barangay'] !== customer_details['cur_barangay']) {
                let city = props?.fields?.find((e: any) => e.name === "cur_city")
                fetchCurrentLocality({ ...city, value: { value: `${customer_details['cur_city']}` } })
            }

            //populate payment term/tenure options
            if (is_renewal) {
                let payTerm = +insuranceState?.leadDetail?.payment_term
                fetchPaymentTerm()
                if(payTerm === PAYMENT_TERM_INSTALLMENT) {
                    fetchPaymentTenure(null, { payment_method: +insuranceState?.leadDetail?.payment_method, is_renewal, payment_term: +insuranceState?.leadDetail?.payment_term, payment_tenure: +insuranceState?.leadDetail?.payment_tenure })
                }
            }

        }
       // eslint-disable-next-line
    }, [props.apptype, props.callfrom, props.activeLoanID, insuranceState.leadDetail])


    useEffect(() => {
        //update total installments
        let paymentTerm = getFieldItem('payment_tenure')
        if (paymentTerm && paymentTerm?.value) {
            let label = paymentTerm?.value?.label
            let installment = label?.split(' ')?.[0] || 0 
            setTotalInstallments(installment)
        }
       // eslint-disable-next-line
    }, [])

    const isShowDynamicField = (detail: any,fieldType:any) => {
        //hide payment term if not installment
        if(detail?.name === 'payment_tenure') {
            let paymentTermItem = getFieldItem('payment_term')
            let isInstallment = paymentTermItem?.value?.value === PAYMENT_TERM_INSTALLMENT ? true : false

            return isInstallment ? true : false
        }

        if (detail?.field_show) {
            //if editing quote detail page && apptype is agency
            //then hide financing to/from date
            if (props.formType === 'EDIT' &&
                ['finance_from_date', 'finance_to_date'].includes(detail.name)
                && props?.apptype === APP_TYPE_AGENCY) {
                return false
            }

            const splitType = detail?.field_show?.split('/')

            if (splitType?.length > 1) {
                const filterFieldItem = props?.fields?.filter((each: any) => each.name === splitType[0])?.[0]
                if (filterFieldItem?.value?.value == splitType[1] || filterFieldItem?.value == splitType[1]) {  // eslint-disable-line
                    return true;
                }
                else return false;
            }
        }
        if(detail &&  fieldType?.includes(detail.type)){
            return false
        }
        // for hiding input field
        if(detail &&  fieldType?.includes(detail?.name)){
            return false
        }
        //show proposed fmv if Apptype is with UCF
        if(detail?.name === 'proposed_fmv') {
            if(props.apptype === APP_TYPE_WITH_UCF) {
                detail['is_show'] = true
                return true
            }
            detail['is_show'] = false
            return false
        }

        if(detail?.name === 'expected_installment') {
            if([APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(props.apptype)) {
                const filterFieldItem = props?.fields?.filter((each: any) => each.name === 'payment_tenure')?.[0]
                const payTerm = props?.fields?.filter((each: any) => each.name === 'payment_term')?.[0]
                if(filterFieldItem?.value?.value && payTerm?.value?.value === PAYMENT_TERM_INSTALLMENT) {
                    return true
                }
                return false
            }
            return false
        }

        return !!detail?.['is_show']
    }

    const onValueSelect = (fieldItem: any, event: any, params?: any) => {

        //if vehicle type sedan & suv and user is changing vehicle usage
        //then edit vehicle type (sedan, suv) ID to be of new selected vehicle usage 
        if (fieldItem.name === 'vehicle_usage' && props.apptype === APP_TYPE_RENEWAL) {
            const isQuoteDetailPage = props?.formType === 'EDIT' ? true : false //if quote detail page then true else false
            const vehicleTypeItem = getFieldItem('vehicle_type')
            const vehicleTypeValue = vehicleTypeItem?.value
            const vehicleTypeForEdit = isQuoteDetailPage ? vehicleTypeItem?.options?.find((e: any) => e.value === vehicleTypeValue) : null
            const currVehicleUsage = vehicleTypeValue?.vehicle_usage || vehicleTypeForEdit?.vehicle_usage
            const newVehicleUsage = event?.value

            //if newly selected vehicle type and vehicle usage is not same
            //then update vehicle type according to new vehicle usage
            if (currVehicleUsage !== newVehicleUsage) {
                const vehicleTypes = vehicleTypeItem?.options
                const newVehicleType = vehicleTypes?.find((e: any) => e.label === (vehicleTypeValue?.label || vehicleTypeForEdit?.label) && e.vehicle_usage === newVehicleUsage)
                if (newVehicleType) {
                    vehicleTypeItem['value'] = { ...newVehicleType }
                    //update value of vehicle type in parent
                    if(isQuoteDetailPage) {
                        props.onValueChange(vehicleTypeItem)
                    }
                }
            }
        }

        if (fieldItem.name === 'expected_installment') {
            let { key: changedField } = params
            fieldItem['value'] = { ...fieldItem?.value, ...event }

            //reset validation error
            for (let key in event) {
                let date = event[key]['expected_installment_date']
                let amt = event[key]['expected_installment_amount']
                //clear error of date if date is present
                date && delete fieldItem['error_message']?.[key]?.['expected_installment_date']
                //clear error of amount if amount is present
                amt && delete fieldItem['error_message']?.[key]?.['expected_installment_amount']
            }

            //reset all next expected date fields
            let tenure = getFieldItem('payment_tenure')
            let totalInstallments = tenure?.value?.label?.split(' ')?.[0] || null
            if(totalInstallments) {
                let currInstallmentNum = Object.keys(event)?.[0] || null
                //reset only when date is changed
                if(currInstallmentNum && changedField === 'date') {
                    for(let idx=+currInstallmentNum+1; idx <= totalInstallments; idx++) {
                        let value = fieldItem['value']
                        let nextDateVal = new Date(value[idx]?.expected_installment_date).getTime()
                        let currDateVal = new Date(event[currInstallmentNum]?.expected_installment_date).getTime()
                        if(nextDateVal <= currDateVal) {
                            delete value[idx]?.expected_installment_date
                        }
                    }
                }
            }
            setRefresh(refresh + 1)
            return
        }

        //reset expected date on payment tenure change
        if (fieldItem.name === 'payment_tenure' && [APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(props.apptype)) {
            //reset validation error
            let expectedDates = getFieldItem('expected_installment')
            for (let key in expectedDates?.['error_message']) delete expectedDates?.['error_message']?.[key]
            // expectedDates['value'] = {}

            let totalInstallments = event?.label?.split(' ')?.[0] || 0
            setTotalInstallments(totalInstallments)
        }

        if (fieldItem.name === 'other_doc') {
                fieldItem.value = event.map((selected: any) => selected.value);
        }

        if (fieldItem.name === 'make' && event.value === 0) {

            let modelItem = getFieldItem('model')
            modelItem['value'] = event
            modelItem['value_selected'] = event

            let versionItem = getFieldItem('version')
            versionItem['value'] = event
        } else if (fieldItem.name === 'model' && event.value === 0) {
            let versionItem = getFieldItem('version')
            versionItem['value'] = event
        }
        // reset finance_to_date"
        if(fieldItem.name === "finance_from_date"){
            let tillDateItem = getFieldItem('finance_to_date');
            if (tillDateItem) {
                tillDateItem.value = null;
            }
        }
        if (["finance_from_date", "finance_to_date"].includes(fieldItem.name)) {
            let selectedDate: any = new Date(event).toLocaleDateString();
            selectedDate = selectedDate.split('/');

            fieldItem['value'] = selectedDate[2] + '-' + selectedDate[1] + '-' + selectedDate[0];
        } else {
            fieldItem['value'] = event
        }
        fieldItem['value'] = event
        fieldItem['error_message'] = ''
        resetChildDependecies(fieldItem)
        // if (["make", "model","version"].includes(fieldItem.name)) {
        //     let carFmvItem = getFieldItem('car_fmv')
        //     if(carFmvItem){
        //         carFmvItem['value'] = '';
        //     }  
        // }
        // if (["make", "model","version","insurance_type"].includes(fieldItem.name)) {
        //     let vehicleTypeItem = getFieldItem('vehicle_type')
        //     if(vehicleTypeItem){
        //         vehicleTypeItem['value'] = '';
        //     } 
        // }

        if(fieldItem.name === "payment_method") {
            let paymentTermItem = getFieldItem('payment_term')
            let paymentMethodItem = getFieldItem('payment_method')
            let paymentTenureItem = getFieldItem('payment_tenure')


            paymentTenureItem['is_show'] = false;
            paymentTermItem.value = "";
            paymentTenureItem.value = "";
            //IF BOTH SELECTED THEN SHOW
            if(paymentTermItem.value === PAYMENT_TERM_INSTALLMENT && paymentMethodItem.value){
                paymentTenureItem['is_show'] = true;
            } else {
                paymentTenureItem['is_show'] = false;
                paymentTenureItem['value'] = ''
            }
        }

        if(fieldItem.name === "payment_term") {
            let paymentTenureItem = getFieldItem('payment_tenure')
            let paymentMethodItem = getFieldItem('payment_method')
            let paymentTermItem = getFieldItem('payment_term')

            paymentTenureItem['is_show'] = false;

            //IF BOTH SELECTED THEN SHOW

            if(paymentTermItem.value && paymentMethodItem.value){

                paymentTenureItem['is_show'] = true;
            } else {
                paymentTenureItem['value'] = ''
            }
        }
        if (props.onValueChange)
            props.onValueChange(fieldItem)
        // setRefresh(!refresh)

        if (fieldItem?.name === 'version' && props?.onVersionSelect) {
            props?.onVersionSelect(event)
        }
        switch (fieldItem?.name) {
            case 'make_year':
            case 'vehicle_usase':
            case 'version':
                // fetchCarFMV() //not need to fetch car price
                break
            case 'per_city':
                fetchPermanentLocality(fieldItem)
                break
            case 'cur_city':
                fetchCurrentLocality(fieldItem)
                break
            case 'addon_detail':
                if (+event.value === 0) {
                    resetAddonList()
                }
                break
            case 'per_barangay':
                preFillPostalCode(event?.pin_code, fieldItem?.child_depedencies,fieldItem?.name);
                break;
            case 'cur_barangay':
                preFillPostalCode(event?.pin_code, fieldItem?.child_depedencies,fieldItem?.name)
                break;
            case 'insurance_type' :
                fetch_mgf_year(fieldItem)
                break;  
            case 'payment_term' :
                fetchPaymentTenure(fieldItem)
                break; 
            case 'payment_method' :
                fetchPaymentTerm(fieldItem)
                break;  
            default:
                break
        }

        if (isSameAsSelected()) {
            prefillSameAsAddress(fieldItem)
        }

        if(fieldItem.name === "car_under_finance") {
            const curfieldData = getFieldItem('financier')
            const curfieldDataFinancierName = getFieldItem('financier_name')
            if (curfieldData) {
                
                if(fieldItem.value?.value === 1){

                    if(curfieldData['value']?.value>0 || curfieldData['value']>0){
                        
                        curfieldDataFinancierName['is_show'] = false
                        curfieldDataFinancierName['value'] = ""
                        if (curfieldDataFinancierName && props.onValueChange) props.onValueChange(curfieldDataFinancierName);
                    }else if(curfieldData['value']===0 || curfieldData['value']?.value===0){
                        curfieldDataFinancierName['is_show'] = true
                        if (curfieldDataFinancierName && props.onValueChange) props.onValueChange(curfieldDataFinancierName);
                        
                    }else{
                        curfieldData['is_required'] = true

                        if (curfieldData && props.onValueChange) props.onValueChange(curfieldData);
                    }
                }else{
                    if(curfieldDataFinancierName){

                        curfieldDataFinancierName['is_show'] = false
                        curfieldDataFinancierName['value'] = ""
                        curfieldDataFinancierName['error'] = ""
                    }
                    if (curfieldDataFinancierName && props.onValueChange) props.onValueChange(curfieldDataFinancierName);
                    curfieldData['value'] = ""
                    curfieldData['error'] = ""

                    if (curfieldData && props.onValueChange) props.onValueChange(curfieldData);

                }
            
                // curfieldData['error_message'] = ''
            }

        }

        // if(fieldItem.name === "financier") {
        //     const curfieldData = getFieldItem('financier')
        //     curfieldData['value'] = fieldItem?.value
        //     // if (curfieldData && props.onValueChange) props.onValueChange(curfieldData);

        // }
    }

    const preFillPostalCode = (pinCode: any, child_depedencies: any,fieldName:any) => {
        const fieldData = getFieldItem(child_depedencies[0]);
        if (fieldData) {
            fieldData['value'] = pinCode + ''
            fieldData['error_message'] = ''
            if (props.onValueChange) props.onValueChange(fieldData)
        }
 
        if (isSameAsSelected() || fieldName ==='cur_barangay') {
            const curfieldData = getFieldItem('cur_postal_code')
            if (curfieldData) {
                curfieldData['value'] = pinCode + ''
                curfieldData['error_message'] = ''
                if (props.onValueChange) props.onValueChange(curfieldData)
            }
        }
    }
    const resetAddonList = () => {
        let addonFieldItem = getFieldItem('addon_detail')
        if (addonFieldItem) {
            addonFieldItem['original_list'] = []
        }
        if (props?.resetAddonList) props.resetAddonList()
    }

    const fetchPermanentLocality = async (fieldItem?: any, params?: any) => {
        let response = await InsuranceService.getlocalityByCity({ city: [fieldItem.value.value] })
        if (response?.status === 200) {
            if (response?.data?.data) {
                setLocalityList(response.data.data)
            }
        }
        let perBarangayField = getFieldItem('per_barangay')
        let bothBaragay = [perBarangayField]
        if (perBarangayField) {
            perBarangayField['options'] = response?.data?.data
        }

        if (isSameAsSelected() || params?.per_sameas_cur) {
            let perBarangayField = getFieldItem('cur_barangay')
            if (perBarangayField) {
                perBarangayField['options'] =response?.data?.data
            }
            bothBaragay.push(perBarangayField)
        }
        updateBarangay(bothBaragay, response, 'per_barangay')
    }

    const fetchCurrentLocality = async (fieldItem?: any) => {
        //fetch by city id =  fieldItem.value.value
        let perBarangayField = getFieldItem('cur_barangay')
        if (perBarangayField) {
            let response = await InsuranceService.getlocalityByCity({ city: [fieldItem.value.value] })
            if (response?.status === 200) {
                if (response?.data?.data) {
                    perBarangayField['options'] = response.data.data;
                    setRefresh((prev) => prev+1);
                    updateBarangay([perBarangayField], response, 'cur_barangay')
                }
            }
        }
    }

    const isSameAsSelected = () => {
        let sameAsField = props?.fields?.filter((item: any) => item.name === 'per_sameas_cur')?.[0]
        if (sameAsField)
            return sameAsField?.value
        return false
    }

    const prefillSameAsAddress = (fieldItem: any) => {
        if (fieldItem.name.includes('per_')) {
            const keyName = fieldItem?.name?.replace('per_', 'cur_');
            let fields = props?.fields?.filter((item: any) => item.name === keyName)  
            if (fields.length) {
                if (isSameAsSelected()) {
                    fields[0]['is_enable'] = false
                } else {
                    fields[0]['is_enable'] = true
                }
                if(fields[0].name === "cur_postal_code"){
                    fields[0]['is_enable'] = false
                }
                fields[0].value = fieldItem.value
                fields[0].error_message = ""

            }
        }
    }

    const onChangeText = (fieldItem: any, text: any) => {
                //number validation for input fields 
        if((['per_postal_code','cur_postal_code'].includes(fieldItem.name))){
            if(text.length>4){
                return true;
            }
        }
        if (fieldItem && fieldItem.type !== 'tb') {
            if (text) {
                const regex = /^[0-9]+(\.[0-9]{0,2})?$/;             
               if (!regex.test(text)){
                return
               }
            }
        } 
        if(fieldItem.label === 'Customer Name'){
            if(!text || (text && !text.trim())){
                fieldItem['value'] = ''; 
                //return
                // let isValidInput = ALPHABETIC_PATTERN.test(text); //eslint-disable-line
                // if (!isValidInput) return
            }
        } 
        fieldItem['value'] = text;
        fieldItem['error_message'] = '';
        if (isSameAsSelected()) {
            prefillSameAsAddress(fieldItem)
        }
        if(['make_name','model_name','version_name'].includes(fieldItem.name)){
            setRefresh((prev) => prev+1);
        }else{
            resetChildDependecies(fieldItem);
        } 
        if (props.onValueChange) props.onValueChange(fieldItem)

    }

    const resetChildDependecies = (fieldItem: any) => {
        let childDependencies = fieldItem?.child_depedencies || [];
        if (fieldItem.name === 'vehicle_usage') {
            // fetchMMV(true);
            childDependencies.push('vehicle_type');
        }
        
        if (fieldItem.name === 'vehicle_type') {
            fetchMMV();
            childDependencies = [...childDependencies, 'make', 'model', 'version'];
            
        }
        
        if (props.apptype === APP_TYPE_WITH_UCF) {
            childDependencies = childDependencies?.filter((e: any) => ['addon_detail', 'vehicle_type'].includes(e))
        }

        //for renewal apptype do not reset fields on selection (only allow reset of below child fields)
        if(props.apptype === APP_TYPE_RENEWAL && !['per_region', 'cur_region', 'per_city', 'cur_city'].includes(fieldItem.name)) childDependencies = []

        // eslint-disable-next-line
        childDependencies.map((key: any) => {
            const fieldData = props?.fields?.filter((fieldData: any) => fieldData.name === key)?.[0]
            if (fieldData) {
                fieldData['error'] = ''
                fieldData['value'] = ''
                if (fieldData.name === "addon_detail") {

                    fieldData['selected_addon'] = []
                    fieldData['original_list'] = []
                    if (props?.resetAddonList) props.resetAddonList()
                }
            }
        })

        setRefresh((prev) => prev+1);
    }

    const getFieldItem = (key: any) => {
        let filterItem = props?.fields?.filter((item: any) => item.name === key)?.[0]
        return filterItem
    }

    const getOptionList = (fieldItem: any) => {
        let vehicleTypeItem = getFieldItem('vehicle_type') 
        let vehicleCategoryId = vehicleTypeItem?.value?.category_id;

        let mmvDataRecord: any = mmvData?.[vehicleCategoryId] || {};
        if (fieldItem.name === "make") {
            return mmvDataRecord?.make
        } else if (fieldItem.name === "model") {
            let makeItem = getFieldItem('make')
            if (makeItem) {
                let value  = typeof makeItem?.value ==='object' ? makeItem?.value?.value: makeItem?.value;
                let modelList = mmvDataRecord?.model?.filter((modelItem: any) => modelItem.make === value || modelItem.value === 0)

                return modelList || []
            }
        } else if (fieldItem.name === 'version') {
            // let makeItem = getFieldItem('make')
            let modelItem = getFieldItem('model')
            if (modelItem) {
                let value  = typeof modelItem?.value ==='object' ? modelItem?.value?.value: modelItem?.value;
                let modelList = mmvDataRecord?.version?.filter((versionItem: any) => versionItem.model == value || versionItem.value == 0)   // eslint-disable-line

                return modelList || []
            }
        } else if (fieldItem.name === 'addon_detail') {
            // let makeItem = getFieldItem('make')
            return [{ value: 1, label: 'Yes' }, { value: 0, label: 'No' }]
        } else if (fieldItem.name === 'per_region' || fieldItem.name === 'cur_region') {
            let tempList: any = []
            props?.stateCityList?.forEach((item: any) => {
                let param = { label: item.value, value: item.id, city_list: item.city_list }
                tempList.push(param)
            })
            return tempList
        } else if (fieldItem.name === 'per_city') {
            let regionItem = getFieldItem('per_region');
            let value  = typeof regionItem?.value ==='object' ? regionItem?.value?.value: regionItem?.value;
            let cityList = props?.stateCityList?.filter((item: any) => item.id === value)?.[0]?.['city_list'];
            let tempList: any = []
            cityList?.forEach((item: any) => {
                let param = { label: item.value, value: item.id }
                tempList.push(param)
            })
            return tempList
        } else if (fieldItem.name === 'cur_city') {
            let regionItem = getFieldItem('cur_region');
            let value  = typeof regionItem?.value ==='object' ? regionItem?.value?.value: regionItem?.value;
            let cityList = props?.stateCityList?.filter((item: any) => item.id === value)?.[0]?.['city_list'];
            let tempList: any = []
            cityList?.forEach((item: any) => {
                let param = { label: item.value, value: item.id }
                tempList.push(param)
            })
            return tempList
        } else if (fieldItem.name === 'vehicle_type') {
          
            let vehicleUsaseItem = getFieldItem('vehicle_usage')
            if (vehicleUsaseItem) {
                let value  = typeof vehicleUsaseItem?.value ==='object' ? vehicleUsaseItem?.value?.value: vehicleUsaseItem?.value;
                let tempOptionList = fieldItem?.options?.filter((optionItem: any) => optionItem[vehicleUsaseItem.name] == value)   // eslint-disable-line
                return tempOptionList || []
            }
            return []
        } else {
            return fieldItem.options || []
        }
    }

    const fetchMMV = async (isfetch=false) => {
        let vehicleUsaseItem = getFieldItem('vehicle_usage') 
        let vehicleTypeItem = getFieldItem('vehicle_type') 

        if (vehicleUsaseItem?.value) { 

            let vehicle_usage = (typeof vehicleUsaseItem.value ==="object")? vehicleUsaseItem.value.value:vehicleUsaseItem.value; 
            let vehicle_type = (typeof vehicleTypeItem.value ==="object")? vehicleTypeItem.value.value:vehicleTypeItem.value; 
            let params = { rule_engine: props.applicationType, vehicle_usage, apptype: props.apptype, vehicle_type, category_id: vehicleTypeItem?.value?.category_id  }
            // if ((commonState.mmvList).length === 0 || isfetch) {
            if (!(commonState?.mmvList?.[params['category_id']] && Object?.keys(commonState?.mmvList?.[params['category_id']])?.length) || isfetch) {
                props.setLoading(true)
                dispatch(getMMVList(params)).then((res:any)=> props.setLoading(false)).catch((err:any)=> props.setLoading(false))
            }
        }
    }

    const fetchCarFMV = async () => {
        let vehicleUsaseItem = getFieldItem('vehicle_usage')
        let versionItem = getFieldItem('version')
        let manufacturingItem = getFieldItem('make_year');
        if ((typeof vehicleUsaseItem.value ==="object")? vehicleUsaseItem.value.value:vehicleUsaseItem.value && ((typeof versionItem.value ==="object")? versionItem.value.value:versionItem?.value) && ((typeof manufacturingItem.value ==="object")? manufacturingItem.value.value:manufacturingItem?.value)) {
            const params = {
                rule_engine: props.applicationType,
                vehicle_usage: (typeof vehicleUsaseItem.value ==="object")? vehicleUsaseItem.value.value:vehicleUsaseItem.value,
                version_id: ((typeof versionItem.value ==="object")? versionItem.value.value:versionItem?.value),
                mfg_year: manufacturingItem.value.value || insuranceState?.leadDetail?.mfg_year,
                apptype: props.apptype
            }
            let response: any = params.version_id ? await InsuranceService.getCarFMV(params) : {}
            if (response?.data?.status === 200) {
                if (response?.data?.data?.price?.price > 0) {
                    let carFMVItem = getFieldItem('car_fmv')
                    if (carFMVItem) {
                        carFMVItem['value'] = (response.data.data.price.price + '')
                        carFMVItem['is_enable'] = true                     //FMV should be editable
                        setRefresh((prev) => prev+1);
                        // calculateSumForCarFMV(carFMVItem)
                    }
                } else {
                    resetCarFMV()
                }
            } else {
                resetCarFMV()
            }
        }
    }

    const resetCarFMV = () => {
        let carFMVItem = getFieldItem('car_fmv')
        if (carFMVItem) {
            carFMVItem['value'] = ''
            carFMVItem['is_enable'] = true
            setRefresh((prev) => prev+1);
            // calculateSumForCarFMV(carFMVItem)
        }
    }

    const onChangeCheckbox = (fieldItem?: any) => {
        fieldItem['value'] = !fieldItem.value
        setRefresh((prev) => prev+1);
        if (fieldItem?.name === 'per_sameas_cur') {
            setPrefillDataOnCheckboxPress()
        }
    }
    const setPrefillDataOnCheckboxPress = () => {
        props?.fields?.forEach((fieldItem: any) => {
            prefillSameAsAddress(fieldItem)
        })
    }

    const getFieldValue = (fieldItem: any) => {
        if (fieldItem.name === "total_fmv") {
            let carFMVItem = getFieldItem('car_fmv')
            let accPriceItem = getFieldItem('acc_price')
            let totalValue = 0
            if (carFMVItem.value)
                totalValue = parseFloat(carFMVItem.value)

            if (accPriceItem?.value)
                totalValue += parseFloat(accPriceItem.value)
            // Round to two decimal places
            totalValue = parseFloat(totalValue.toFixed(2));
            fieldItem["value"] = totalValue;
            fieldItem["error_message"] = "";
            return totalValue
        }

        return fieldItem.value || ''
    }
    const isEnable = (fieldItem: any) => {
        if(fieldItem['not_set']) return true
        // if (fieldItem.name === 'seat_capacity' || fieldItem.name === 'transmission' || fieldItem.name === 'fuel_type') {
        //     if (fieldItem.value) return false
        //     else return true
        // } else { 
        //     if (fieldItem?.name?.includes('cur_') && isSameAsSelected()) { return false } 
        //     return fieldItem.is_enable
        // }

        //if on quote detail page & apptype renewal, then
        //disable following fields
        if (props.formType === 'EDIT' && props.apptype === APP_TYPE_RENEWAL) {
            if (['make', 'model', 'version', 'insurance_exists', 'insurer_name', 'expiration_date', 'make_year', 'vehicle_type'].includes(fieldItem.name)) {
                return false
            }

            //if renewal & vehicle type is not sedan suv, then disable vehicle usage
            if(fieldItem.name === "vehicle_usage") {
                const vehicleType = getFieldItem('vehicle_type')
                const vehicleTypeVal = typeof vehicleType?.value === 'object' ? vehicleType?.value?.value : vehicleType?.value
                if (![1, 2, 6, 7].includes(vehicleTypeVal)) {
                    return false
                }
            }
        }

        if (fieldItem?.name?.includes('cur_') && isSameAsSelected()) { return false } 
        return fieldItem.is_enable
    }
    
    
    const fetch_mgf_year = async (fieldItem?: any) => {
        let mgf_year= (commonState?.common?.make_year)?commonState.common.make_year:{}
        let makeYearField = getFieldItem('make_year')
        
        if (makeYearField) {
            makeYearField['options'] =(fieldItem.value.value===INS_TYPE_COMPREHENSIVE)?mgf_year?.mgf_comprehensive:mgf_year?.mgf_ctpl

            //FOR WITHOUT RULE ENGINE SHOW ALL YEARS
            if(+props?.applicationType === WITHOUT_RULE_ENGINE){
                makeYearField['options'] = mgf_year?.without_mfg_comprehensive
            }
        }
    }

    const fetchPaymentTerm = (fieldItem?: any) => {
        let pay_term= (commonState?.common?.payment_term)?commonState.common.payment_term:[]
        let payTermField = getFieldItem('payment_term')
        if (payTermField) {
            payTermField['options'] = pay_term
        }
    }
    
    const fetchPaymentTenure = (fieldItem?: any, params?: any) => {
        let isPaymentMethodSelected = props?.fields?.find((fieldItem: any) => fieldItem.name === "payment_method" && (fieldItem.value || params?.payment_method)) //use params payment method if apptype renewal
        if (isPaymentMethodSelected) {
            let payment_tenure = (commonState?.common?.payment_tenure) ? commonState.common.payment_tenure : []
            let payment_method_id = isPaymentMethodSelected.value.value || params?.payment_method
            let paymentTenureField = getFieldItem('payment_tenure')
            if (paymentTenureField) {
                let options = payment_tenure?.filter((el: any) => el.payment_method === payment_method_id && el.payment_term === (fieldItem?.value?.value || params?.payment_term)) //use params payment term if apptype renewal
                paymentTenureField['options'] = options;

                //if apptype renewal, then prefill payment tenure
                if(params?.is_renewal && params?.payment_tenure) {
                    paymentTenureField['value'] = options?.find((tenure: any) => tenure.value === params.payment_tenure)
                    const ptLabel = paymentTenureField['value']
                    const installment = ptLabel?.label?.split(' ')?.[0] || 0
                    setTotalInstallments(installment)
                }

                if ((!options || !options.length) && fieldItem?.value?.label?.trim() === 'One time Payment') paymentTenureField['is_show'] = false;
                else paymentTenureField['is_show'] = true;
            }
        }
    }

    const updateBarangay = (fieldItem: any, response: any, key: string) => {
        if (props.apptype !== APP_TYPE_STANDALONE && response?.data?.data) {
            let is_renewal = props.apptype === APP_TYPE_RENEWAL ? true : false
            let customer_details = !is_renewal ? insuranceState?.activeLoanID?.customer_details : {
                per_city: +insuranceState?.leadDetail?.per_city,
                cur_city: +insuranceState?.leadDetail?.cur_city,
                per_barangay: +insuranceState?.leadDetail?.per_barangay,
                cur_barangay: +insuranceState?.leadDetail?.cur_barangay,
            }
            updateInsuranceForm({ ...customer_details, type: is_renewal ? 'renewal' : false }, fieldItem).then(() => {
                //update redux state for prefilling without clicking next
                props?.saveDetails(props?.sectionItemAll)
            })
        }
    }


    let rowClass = (props.rowClass) ? props.rowClass : "col-md-4";
    let hideField = (props.hideFieldType)? props.hideFieldType:[] 
    return (
        <>
            {
                props?.fields?.map((fieldItem: any, index: number) => {
                    // if app type is UCF then other detail dealer id, select_addon will hide key otherwise select_addon hide 
                    hideField = props?.apptype === APP_TYPE_WITH_UCF ? ['select_addon', 'other_detail_dealer_id', 'paid_by_customer'] : ['select_addon'];
                    hideField = props?.apptype === APP_TYPE_RENEWAL ? [...hideField, 'other_detail_dealer_id'] : hideField

                    const isShowField = isShowDynamicField(fieldItem, hideField);

                    let optionsList = (fieldItem?.options?.length && fieldItem?.name !== 'vehicle_type') ? fieldItem.options : getOptionList(fieldItem);

                    let optionValue = typeof fieldItem.value === 'object' ? fieldItem.value : (fieldItem.value != 0 || fieldItem.value === 0) ? optionsList?.filter((option: any) => option.value == fieldItem.value)?.[0] : null;   // eslint-disable-line

                    //if edit quote details & renewal
                    if (props.formType === 'EDIT' && props.apptype === APP_TYPE_RENEWAL) {
                        //disable mmv if renewal lead
                        if (['make', 'model', 'version'].includes(fieldItem.name)) {
                            const label = insuranceState?.leadDetail?.[fieldItem.name + '_name']
                            const value = insuranceState?.leadDetail?.[fieldItem.name]
                            fieldItem.value = { label, value }
                            optionValue = fieldItem.value
                        }
                    }

                    let inputType = (['per_postal_code', 'cur_postal_code'].includes(fieldItem.name)) ? 'number' : 'text';

                    fieldItem['conditional_manadatory'] = ([2, 3].includes(+props?.apptype) && ['plate_number', 'mv_number'].includes(fieldItem.name)) ? true : false

                    if (fieldItem.name === 'payment_term' && props?.insuranceType === INS_TYPE_CTPL) {
                        optionsList = optionsList?.filter((opt: any) => opt.value !== PAYMENT_TERM_INSTALLMENT)
                    }
                    
                    switch (fieldItem.type) {
                        case FIELD_TYPE.CURRENCY:
                        case FIELD_TYPE.TELEPHONE:
                        case FIELD_TYPE.SUM:
                        case FIELD_TYPE.TEXT_BOX:
                        case FIELD_TYPE.MOBILE_FIELD:
                            return (
                                isShowField &&
                                <fieldset className={rowClass} key={index}>
                                    <div className="material">
                                        {
                                            isEnable(fieldItem) ? 
                                            <input 
                                            maxLength={fieldItem.type === FIELD_TYPE.MOBILE_FIELD ? 10 : 1000} 
                                            disabled={!isEnable(fieldItem)}  
                                            type={inputType} 
                                            placeholder=" " 
                                            name={fieldItem.name} 
                                            className="form-input" 
                                            value={getFieldValue(fieldItem) || ''} onChange={(event) => { onChangeText(fieldItem, event.target.value); }} 
                                        />
                                        : 
                                        <input 
                                        maxLength={fieldItem.type === FIELD_TYPE.MOBILE_FIELD ? 10 : 1000} 
                                        disabled={!isEnable(fieldItem)}  
                                        type={inputType} 
                                        placeholder=" " 
                                        name={fieldItem.name} 
                                        className="form-input" 
                                        value={getFieldValue(fieldItem) || ''}  
                                        />
                                        }
                                        {/* if label is email and lead is UCF then email is not mandatory*/}
                                        {(fieldItem.label === 'Email' && +props.apptype === 1) ?
                                            <label data-label={fieldItem.label} className="form-label"></label>
                                            :
                                            <label data-label={fieldItem.label + ((props?.apptype !== APP_TYPE_AGENCY || (props.apptype === APP_TYPE_AGENCY && !['insurer_name'].includes(fieldItem.name))) ? (fieldItem.is_required ? '*' : ((fieldItem?.conditional_manadatory ? '**' : ''))) : '')} className="form-label"></label>
                                        } 
                                        
                                        {fieldItem?.error_message ? <span className="error-txt">{fieldItem.error_message}</span> : null}
                                    </div>
                                </fieldset>
                            )
                        case FIELD_TYPE.FUTURE_DATE:
                        case FIELD_TYPE.DATE_PICKER: {
                            return (
                                isShowField && fieldItem.name === 'expected_installment'
                                    ?
                                    times(totalInstallments, (idx: number) => {
                                        const placeholder = `Expected ${idx + 1}${nthNumber(idx + 1)} Installment`
                                        return (
                                            <React.Fragment key={idx + placeholder}>
                                                {/** Adding expected installment amount using expected installment date fieldItem */}
                                                <fieldset className={rowClass} key={'expected_installment_amount' + idx + 1}>
                                                    <div className="material">
                                                        <CurrencyInputField
                                                            inputProps={{
                                                                id: 'expected' + (idx + 1) + "_amount",
                                                                type: "text",
                                                                name: 'expected' + (idx + 1) + "_amount",
                                                                placeholder: " ",
                                                                value: fieldItem?.value?.[idx + 1]?.expected_installment_amount || "",
                                                                className: "form-input",
                                                                disabled: false,
                                                            }}

                                                            onChange={(event: any) => {
                                                                onValueSelect(fieldItem, { [idx + 1]: { ...fieldItem?.['value']?.[idx + 1], expected_installment_amount: (removeCurrencyFormatting(event?.target?.value) || 0) } }, { key: 'amount' });
                                                            }}
                                                        />
                                                        <label data-label={placeholder + " Amount*"} className="form-label"></label>
                                                        {
                                                            fieldItem?.error_message?.[idx + 1]?.expected_installment_amount ?
                                                                <span className="error-txt">
                                                                    {
                                                                        fieldItem.error_message?.[idx + 1].expected_installment_amount
                                                                    }
                                                                </span> :
                                                                null
                                                        }
                                                    </div>
                                                </fieldset>
                                                <fieldset className="form-filed col-md-4" key={fieldItem.name + idx + 1}>
                                                    <div className={"material animation-effect" + (fieldItem?.value?.[idx + 1]?.expected_installment_date ? ' date-label' : '')}>
                                                        <ExpectedInstallmentDate
                                                            onChange={(e: Date) => onValueSelect(fieldItem, { [idx + 1]: { ...fieldItem?.['value']?.[idx + 1], expected_installment_date: e } }, { key: 'date'})}
                                                            selected={fieldItem?.value?.[idx + 1]?.expected_installment_date || ''}
                                                            dateFormat="dd MMM, yyyy"
                                                            disabled={false}
                                                            installmentNumber={idx + 1}
                                                            prevInstallmentDate={fieldItem?.value?.[idx]?.expected_installment_date || null}
                                                            text={placeholder + " Date*"}
                                                        />
                                                        {
                                                            fieldItem?.error_message?.[idx + 1]?.expected_installment_date ?
                                                                <span className="error-txt">{fieldItem?.error_message?.[idx + 1]?.expected_installment_date}</span>
                                                                : ''
                                                        }
                                                    </div>
                                                </fieldset>
                                            </React.Fragment>
                                        )
                                    })
                                :
                                isShowField && 
                                <fieldset className={"form-filed date-picker-detail " + rowClass} key={index}> 
                                    <div className={"material animation-effect" + (fieldItem.value ? ' active-label' : '')}>
                                        {/* <InsuranceDate text={fieldItem.label + (fieldItem.is_required ? '*' : '')} /> */}
                                        <Calendra2 onChange={(e: Date) => onValueSelect(fieldItem, e)} 
                                        placeholder={fieldItem.label + ((props?.apptype !== APP_TYPE_AGENCY || (props.apptype === APP_TYPE_AGENCY && !['expiration_date'].includes(fieldItem.name))) ?(fieldItem.is_required ? '*' : '') : '')}
                                         dateFormat="dd/MM/yyyy" selected={fieldItem?.value} fieldId = {fieldItem.id} fields = {props?.fields} fieldLabel = {fieldItem.label} disabled={(props.apptype === APP_TYPE_RENEWAL && props.formType === 'EDIT') ? true : !fieldItem.is_enable} apptype = {props.apptype}  fieldname = {fieldItem.name}/>
                                        {fieldItem?.error_message ? <span className="error-txt">{fieldItem.error_message}</span> : null} 
                                    </div>
                                </fieldset>
                            )
                        }
                        case FIELD_TYPE.CHECK_BOX:
                            return (
                                <div className="form-filed col-md-11  m-xs-b " key={index}>
                                    <div className="material">
                                        <div className="custom-control custom-checkbox">
                                            <input checked={fieldItem.value} id="Individual" type="checkbox" className="custom-control-input " name="Buyer Type" onChange={(e) => {
                                                onChangeCheckbox(fieldItem)
                                            }} disabled={!fieldItem.is_enable}/>
                                            <label className="custom-control-label">Same as Permanent Address </label>
                                        </div>

                                    </div>
                                </div>
                            )
                        case FIELD_TYPE.TEXT_AREA:
                            return (
                                <fieldset className="form-filed col-md-4">
                                    <div className={"material"}>
                                        <textarea id="description" placeholder=" " name="notes" className="form-input" value={fieldItem.value} onChange={(e) => onValueSelect(fieldItem, e.target.value)} />
                                        <label data-label={fieldItem.label} className="form-label"></label>
                                    </div>
                                </fieldset>
                            )
                        
                        case FIELD_TYPE.MULTI_DROP_DOWN:
                            return (                               
                                <fieldset className="multiselect-dropDown col-md-4">
                                <div className={ ((fieldItem.name === 'other_doc' &&  fieldItem.value.length >0 )|| typedValue) ? "active-label material animation-effect" : "material animation-effect "}>
                                    <MultiSelect
                                        id="other_docs"
                                        key={"other_docs"}
                                        options={optionsList || []}
                                        value={optionValue ?? []} 
                                        onChange={(e: any) => onValueSelect(fieldItem, e)}
                                        isSelectAll={true}
                                        placeholder={""}
                                        setTypedValue = {setTypedValue}
                                    />
                                    <label data-label={fieldItem.label} className="form-label"></label>
        
                                </div>
                            </fieldset>
                            )    

                        default: {
                            return (
                                
                                isShowField && 
                                <React.Fragment key={index} >
                                    {
                                        isEnable(fieldItem) ? 
                                        <ReactSelect 
                                        options={optionsList} 
                                        value={optionValue} 
                                        handleChange={(e: any, fieldKey: string) => onValueSelect(fieldItem, e)} placeholder={fieldItem.label + ((fieldItem.is_required && (props?.apptype !== APP_TYPE_AGENCY || (props.apptype === APP_TYPE_AGENCY && !['insurer_name', 'expiration_date', 'financier'].includes(fieldItem.name))) && (fieldItem.name !=="copy_of_insurance" || (fieldItem.name === 'copy_of_insurance' && props.apptype === APP_TYPE_WITH_UCF))) ? '*' : '')} 
                                        fieldKey="" 
                                        fieldItemName={fieldItem.name} 
                                        isDisabled={!isEnable(fieldItem)} 
                                        errorField={fieldItem?.error_message}
                                        fieldSetClassName={"form-filed " + rowClass}
                                    />
                                        :
                                        <ReactSelect 
                                        options={optionsList} 
                                        value={optionValue} 
                                        placeholder={fieldItem.label + ((fieldItem.is_required && (fieldItem.name !=="copy_of_insurance" || (fieldItem.name === 'copy_of_insurance' && props.apptype === APP_TYPE_WITH_UCF))) ? '*' : '')} 
                                        fieldKey="" 
                                        isDisabled={!isEnable(fieldItem)} 
                                        errorField={fieldItem?.error_message}
                                        fieldSetClassName={"form-filed " + rowClass}
                                    /> 
                                    }
                                   
                                </React.Fragment>
                            )
                        }
                    }
                })
            }
        </>
    )
}

export default DynamicFormField