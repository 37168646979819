import { toast } from "react-toastify";
import { createSlice } from '@reduxjs/toolkit';
import InsuranceService from "../../services/InsuranceService";
import CommonService from "@src/services/CommonService";
interface APIResponse {
    data: {
        status: number,
        message: string,
        data?: any
    }
}

const Insurance = createSlice({
    name: 'insurance',
    initialState: {
        insuranceList: {},
        insuranceListFilters: {},
        leadDetail: {},
        createInsurance:{},
        templateList: [],
        getInboundPaymentFrom: "",
        paymentData : [],
        savePayment : [],
        markLost : [],
        reopenLostLead : [],
        leadTemplateData: [],
        activeLoanID: null,
        sampleCsv : [],
        formDetails: { extraField: {}, formDetails: []},
        formConfig: [],
        formConfigAgency: [],
        createInsuranceAgency: {},
        sectionList: [],
        renewalUpdateFlag: { insuranceForm: false, customerDetails: false },
        insuranceData: {}
    },
    reducers: {
        insuranceList: (state, action) => {
            state.insuranceList = action.payload
        },
        insuranceListFilters: (state, action) => {
            state.insuranceListFilters = action.payload
        },
        leadDetail: (state, action) => {
            state.leadDetail = action.payload
        },
        createInsurance: (state, action) => {
            state.createInsurance = {
                ...state.createInsurance, ...action.payload
            }
        },
        setTemplateList: (state, action) => {
            state.templateList = action.payload
        },
        setInboundPaymentFrom: (state, action) => {
            state.getInboundPaymentFrom = action.payload
        },
        savePayment: (state, action) => {
            state.savePayment = action.payload
        },
        paymentData: (state, action) => {
            state.paymentData = action.payload
        },
        markLost: (state, action) => {
            state.markLost = action.payload
        }, 
        reopenLostLead : (state, action) => {
            state.reopenLostLead = action.payload
        },
        setLeadTemplateData: (state, action) => {
            state.leadTemplateData = action.payload
        },
        activeLoanID: (state, action) => {
            state.activeLoanID = action.payload
        },
        setSampleCsv: (state, action) => {
            state.sampleCsv = action.payload
        },
        formDetails: (state, action) => {
            state.formDetails = action.payload
        },
        formConfig: (state, action) => {
            state.formConfig = action.payload
        },
        formConfigAgency: (state, action) => {
            state.formConfigAgency = action.payload
        },
        setRenewalUpdateFlag: (state, action) => {
            state.renewalUpdateFlag = action.payload
        },
        createInsuranceAgency: (state, action) => {
            state.createInsuranceAgency = action.payload
        },
        setSectionList: (state, action) => {
            state.sectionList = action.payload
        },
        setInsuranceData: (state, action) => {
            state.insuranceData = action.payload
        }
    }
})


export default Insurance.reducer;

const { insuranceList, insuranceListFilters, leadDetail, setTemplateList, savePayment, setInboundPaymentFrom, paymentData, markLost, reopenLostLead, setLeadTemplateData ,createInsurance, activeLoanID, setSampleCsv, formDetails, formConfig, formConfigAgency, setRenewalUpdateFlag, createInsuranceAgency, setSectionList, setInsuranceData } = Insurance.actions


/**
 * Function to make API call to fetch insurance listing
 * @param params 
 * @returns 
 */
export const getInsuranceList = (params: object) => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.getInsuranceListing(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                        dispatch(insuranceList(response.data));
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

/**
 * Function to make API call to fetch Insurance history
 * @param params 
 * @returns 
 */
export const getLeadHistory = async (params: object) => {
    return new Promise((resolve, reject) => {
        InsuranceService.getLeadHistory(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

/**
 * Function to make API call to fetch Insurance remarks
 * @param params 
 * @returns 
 */
export const getLeadRemarks = async (params: object) => {
    return new Promise((resolve, reject) => {
        InsuranceService.getLeadRemarks(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

/**
 * Function to make API call to fetch Insurance remarks
 * @param params 
 * @returns 
 */
export const addLeadRemark = async (params: object) => {
    return new Promise((resolve, reject) => {
        InsuranceService.addLeadRemark(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

/**
 * Function to make API call to fetch Insurance Details by id
 * @param params 
 * @returns 
 */
export const getInsuranceDetails = (params: object) => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.getInsuranceDetails(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        let data = response.data && response.data.data
                        resolve(response.data)
                        dispatch(leadDetail(data))

                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

export const leadListFilters = (params: object) => async (dispatch: any) => {
    dispatch(insuranceListFilters(params));
}

export const leadListData = (params: object) => async (dispatch: any) => {
    dispatch(leadDetail(params));
}
export const saveInsurance = (params: object) => async (dispatch: any) => {
    dispatch(createInsurance(params));
}

export const updateLeadAssignUser = (params: object) => {
    return new Promise((resolve, reject) => {
        InsuranceService.updateLeadAssignUser(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        if (response.data.message) toast.success(response.data.message);
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

// update Sms template

export const updateTemplate = async (params: object) => {
    return new Promise((resolve, reject) => {
        InsuranceService.updateTemplate(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}


// fetch Template Info

export const templateInfo = () => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.templateInfo()
            .then(
                (response: any) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                        dispatch(setTemplateList((response.data && response.data['data']) || []));
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}


export const sharePolicyDetails = async (params: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.sharePolicyDetails(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        if (response.data.message) {
                            if(params?.['sent_to_broker_skip']){
                                toast.success("Sent to insurance broker skipped successfully");
                            }else{
                                toast.success(response.data.message);
                            }
                        }
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

/**
 * Function to make API call to Update Insurance Details by id
 * @param params 
 * @returns 
 */
export const updateInsuranceDetails = (params: object) => {
    return new Promise((resolve, reject) => {
        InsuranceService.updateInsuranceDetails(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        if (response.data.message) toast.success(response.data.message);
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}


// save Insurance Policy 

export const saveInsurancePolicy = async (params: object) => {
    return new Promise((resolve, reject) => {
        InsuranceService.saveInsurancePolicy(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

export const savePaymentDetails = (params: any) => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.savePaymentDetails(params)
            .then(
                (response: any) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                        dispatch(savePayment((response.data && response.data['data']) || []));
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

export const setInboundPaymentFromResponse = (params: any) => async (dispatch: any) => {
    dispatch(setInboundPaymentFrom(params));
}

/**
 * function to update lead detail in redux
 * @param params 
 * @returns 
 */
export const updateLeadDetailsInStore = (params: object): any => {
    return async (dispatch: any) => {
        dispatch(leadDetail(params))
        if(!Object.keys(params).length){
            dispatch(setLeadTemplateData([]))
        }
    }
}


export const getPaymentDetails = (params: any) => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.getPaymentDetails(params)
            .then(
                (response: any) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                        dispatch(paymentData((response.data && response.data['data']) || []));
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}


export const viewDocumentHistory = (params: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.getDocumentHistory(params)
            .then(
                (response: any) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}


export const requestDocResubmission = (params: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.requestDocResubmission(params)
            .then(
                (response: any) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}



export const markDocApproved = (params: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.markDocApprove(params)
            .then(
                (response: any) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}


export const markLeadAsLost = (params: any) => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.markAsLost(params)
            .then(
                (response: any) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data);
                        dispatch(markLost((response.data && response.data['data']) || []));
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

export const reOpenLostLead = (params: any) => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.reopenLost(params)
            .then(
                (response: any) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data);
                        dispatch(reopenLostLead((response.data && response.data['data']) || []));
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

// fetch financier Info

export const getFinancierTemplateConfig = (params: object) => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.getFinancierTemplateConfig(params)
            .then(
                (response: any) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

// fetch templateFieldsInfo

export const getLeadEmailTemplates =  (params: any) => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.getLeadEmailTemplates(params)
            .then(
                (response: any) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                       dispatch(setLeadTemplateData((response.data && response.data['data']) || []));
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

// fetch Template Fields name   async (params: object) => {

export const getTemplateFields = async () => {
    return new Promise((resolve, reject) => {
        InsuranceService.templateFieldsDetails()
            .then(
                (response: any) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                      //  dispatch(setTemplateList((response.data && response.data['data']) || []));
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

// fetch templateFieldsInfo

export const getTemplateFieldinfo = async (params: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.getTemplateFieldinfo(params)
            .then(
                (response: any) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                      //  dispatch(setTemplateList((response.data && response.data['data']) || []));
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}



export const shareLoanQuoteDetail = async (params: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.shareLoanQuoteDetails(params)
            .then(
                (response: any) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}


export const downloadLoanQuotationPdf = (params: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.downloadLoanQuoteDetails(params)
            .then(
                (response: any) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

export const updateLeadTemplateData = (params: object): any => {
    return async (dispatch: any) => {
        dispatch(setLeadTemplateData(params))
    }
}
//updateTemplateNote

export const updateTemplateNote = (params: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.updateTemplateNote(params)
            .then(
                (response: any) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}


//DOWNLOAD TEMPLATE PDF
export const downloadTemplatePdf = (params: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.downloadTemplatePdf(params)
            .then(
                (response: any) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}


/**
 * Function to make API call to fetch Insurance Installment Details
 * @param params 
 * @returns 
 */
export const fetchInstallmentDetails = (params: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.fetchInstallmentDetails(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}


/**
 * Function to make API call to fetch Lead Details
 * @param params 
 * @returns 
 */
export const fetchLeadData = (params: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.fetchLeadProcessingDetails(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}
/*
 * Function to make API call to fetch Loan Applications
 * @param params 
 * @returns 
 */
export const fetchLoanApplications = (params: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.getLoanApplications(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}


/**
 * Function to make API call to fetch documents
 * @param params 
 * @returns 
 */
export const fetchDocuments = (params: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.fetchAllDocuments(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

/*
 * Function to make API call to fetch Loan Applications By Id
 * @param params 
 * @returns 
 */
export const fetchLoanApplicationById = (params: any) => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.getLoanApplicationById(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                        dispatch(activeLoanID({...response?.data?.data}))
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}


/**
 * Function to make API call to fetch Quote detail
 * @param params 
 * @returns 
 */
export const fetchQuoteDetails = (params: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.fetchQuoteDetails(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

export const clearLoanData = (params: object): any => {
    return async (dispatch: any) => {
        dispatch(activeLoanID({}))
    }
}

//get sample csv
export const getSampleFile = () => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.getSampleFile()
            .then(
                (response: any) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                        dispatch(setSampleCsv((response.data && response.data['data']) || []));
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

//to update common application form
export const saveFormDetails = (params: any) => async (dispatch: any) => {
    dispatch(formDetails(params));
}


export const getFormConfig = (params: any) => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.getFormConfig(params)
        .then(
            (response: any) => {
                if ((response && response.data && response.data.status) === 200) {
                    resolve(response?.data?.data || [])
                    dispatch(formConfig(response?.data?.data?.insuranceFormFields || []));
                    dispatch(formConfigAgency(response?.data?.data?.insuranceFormFieldsAgency || []));
                }
                else {
                    let errorMsg = (response && response.data && response.data.message) || ''
                    if (errorMsg) toast.error(response.data.message);
                    resolve(response && response.data)
                }
            },
            (error: Error) => {
                reject(error);
            }
        )
    })
}
/**
 * 
 * @param params filter options/data to export
 * @returns sends email to logged in user
 */
export const exportInsuranceData = (params: object) => {
    return new Promise((resolve, reject) => {
        InsuranceService.exportInsuranceData(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}


/* * Function to make API call to fetch Renewal Insurance Applications
 * @param params 
 * @returns 
 */
export const fetchRenewalApplications = (params: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.getRenewalApplications(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}


/* * Function to make API call to fetch Renewal Insurance Applications
 * @param params 
 * @returns 
 */
export const fetchRenewalApplicationById = (params: any) => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.getRenewalApplicationById(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        let data = response.data && response.data.data
                        resolve(response.data)
                        dispatch(leadDetail(data))
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}


export const updateRenewalState = (params: any) => {
    return async (dispatch: any) => {
        dispatch(setRenewalUpdateFlag(params))
    }
}

/**
 * function to update redux store while creating agency
 * @param params sectionLists for creating insurance for agency/agent
 * @returns 
 */
export const saveInsuranceAgency = (params: object) => async (dispatch: any) => {
    dispatch(createInsuranceAgency(params));
}


export const saveSectionList = (params: any) => async (dispatch: any) => {
    dispatch(setSectionList(params));
}


export const getAgencyUsers = () => {
    return new Promise((resolve, reject) => {
        InsuranceService.getAgencyUsers()
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}


/* * Function to make API call to fetch data from ORCR document
 * @param params 
 * @returns 
 */
export const uploadAndReadDocument = (params: any) => async (dispatch: any) => {
    return new Promise((resolve, reject) => {
        CommonService.getDataFromDocument(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        let data = response.data && response.data.data
                        resolve(response.data)
                        dispatch(setInsuranceData({...data, autoFillVehicleDetails: false, autoFillPersonalDetails: false}))
                    }
                    else {
                        // let errorMsg = (response && response.data && response.data.message) || ''
                        // if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

export const updateInsuranceDataForAgency = (params: any) => async (dispatch: any) => {
    dispatch(setInsuranceData(params))
}


/* * Function to make API call to fetch data from BULK UPLOAD LOGS
 * @param params 
 * @returns 
 */
export const getBulkUploadLeadLogs = () => {
    return new Promise((resolve, reject) => {
        InsuranceService.getBulkUploadLeadLogs()
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}

/* * Function to make API call to fetch data from BULK UPLOAD LOGS
 * @param params 
 * @returns 
 */
export const downloadBulkUploadLeadLogs = (params:any) => {
    return new Promise((resolve, reject) => {
        InsuranceService.downloadBulkUploadLeadLogs(params)
            .then(
                (response: APIResponse) => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        resolve(response && response.data)
                    }
                },
                (error: Error) => {
                    reject(error);
                }
            )
    })
}