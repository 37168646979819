import Dashboard from '@src/component/Dashboard'
import LeadList from '@src/component/insurance/list/InsuranceList';
import Header from "@src/common/Header";
import Footer from "@src/common/Footer";

import Login from '@src/component/auth/Login';
import DeleteAccount from '@src/component/auth/DeleteAccount';

import { Route, Routes, Navigate } from 'react-router-dom';
import CreateInsurancePolicy from '@src/component/insurance/create/Index';
import RoleList from '@src/component/role/RoleList';
import Partners from '@src/component/Partners';
import RuleEngine from '@src/component/rule-engine/RuleEngine';
import Details from '@src/component/insurance/detail/index';
import PrivateRoute from '@src/routes/privateRoute';
import SendToFinancierTemplateListing from "@src/component/rule-engine/template/SentToFinancerConfigListing";
import SendToFinancierTemplateDetail from '@src/component/rule-engine/template/SentToFinancerGroupDetail'
import NotFound from '@src/common/NotFound';
import { useLocation } from "react-router-dom"
import { useEffect, useState } from 'react';
import { getPublicKey } from '@src/store/slices/encryption';
import CreateInsurancePolicyAgency from '@src/component/insurance/agency/Index';
import BulkPaymentList from '@src/component/bulk-payment/BulkPaymentList';
import PaymentAddEdit from '@src/component/bulk-payment/PaymentAddEdit';
// import secureStorage from '@src/config/encrypt';
import AgencyList from '@src/component/rule-engine/agency/AgencyList'
import AddEditInsuranceAgency from '@src/component/rule-engine/agency/AddEditInsuranceAgency'
import OcrReadDocument from '@src/component/readOcr/ReadOcrDocument';

const AppRoutes = () => {
    const location = useLocation()
    let currentPath = location.pathname;

    const [loadRoutes, setLoadRoutes] = useState(true)

    useEffect(()=>{

        getPublicKey().then((res: any)=> {
            if(res?.data) setLoadRoutes(true)
        })

    },[])
    
    return (
        <>
            {
                !["/", "", "/login", "/delete/account" ,"/not-found", "/readOcrDocument"].includes(currentPath) && <Header />
            }
            { loadRoutes && <Routes>

                <Route path="/dashboard" element={<PrivateRoute components={Dashboard} accessPageId="23"  accessType = "isVisible"/>} />             
                <Route path="/insurance-list" element={<PrivateRoute components={LeadList} accessPageId="22" accessType="isVisible"/>} />
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/delete/account" element={<DeleteAccount />} />
                 
                <Route path="/create-insurance-policy" element={<PrivateRoute components={CreateInsurancePolicy} accessPageId="22" accessType="isVisible"  />} />
                
                <Route path="/create-insurance-policy-agency" element={<PrivateRoute components={CreateInsurancePolicyAgency} accessPageId="22" accessType="isVisible"  />} />
                
                <Route path="/role-list" element={<PrivateRoute components={RoleList} accessPageId="19" accessType="isVisible" />} />
                <Route path="/partners" element={<PrivateRoute components={Partners} accessPageId="18" accessType="isVisible" />} />
                <Route path="/rule-engine" element={<PrivateRoute components={RuleEngine} accessPageId="19" accessType="isVisible" />} />
                <Route path="/user-list" element={<PrivateRoute components={RoleList} accessPageId="19" accessType="isVisible" />} />
                <Route path="/payment-list" element={<PrivateRoute components={BulkPaymentList} accessPageId="19" accessType="isVisible" />} />

                <Route path="/details/:type/:id" element={<PrivateRoute components={Details} accessPageId="22" accessType="isVisible"/>} />            
                <Route path="/email-template-config" element={<PrivateRoute components={SendToFinancierTemplateListing} accessPageId="19" accessType="isVisible" />} />
                {/* <Route path="/email-template-config-detail" element={<PrivateRoute components={SendToFinancierTemplateDetail} accessPageId="19"  accessType="isVisible" />} /> */} 
                <Route path="/payment/add" element={<PrivateRoute components={PaymentAddEdit} accessPageId="22" accessType="isVisible" />} />
                <Route path="/payment/edit/:id" element={<PrivateRoute components={PaymentAddEdit} accessPageId="22" accessType="isVisible" />} /> 
                <Route path="/email-template-config-detail" element={<PrivateRoute components={SendToFinancierTemplateDetail} accessPageId="19"  accessType="isVisible" />} />
                <Route path="/agency-list" element={<PrivateRoute components={AgencyList} accessPageId="39" accessType="isVisible" />} />
                <Route path="/agency/create" element={<PrivateRoute components={AddEditInsuranceAgency} accessPageId="39" accessType="isVisible"  />} />
                <Route path="/agency/edit/:id" element={<PrivateRoute components={AddEditInsuranceAgency} accessPageId="39" accessType="isVisible"  />} />
                <Route path="*" element={<Navigate to="/not-found"/>} />
                <Route path="/readOcrDocument" element={<OcrReadDocument/>} />    
                <Route path="/not-found" element={<NotFound/>} />    

            </Routes>
            }

            {
                !["/", "", "/login", "/delete/account" ,"/not-found", "/readOcrDocument"].includes(currentPath) && <Footer />
            }
        </>
    );
}

export default AppRoutes;
