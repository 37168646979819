import MasterService from '../services/MasterService';

interface OtpRequest {
    // Define the structure of the 'params' object here
    email: string;
    password?: string;
    captcha?: string;
    resendOtp?: boolean;
  }

  interface DeleteAccount {
    // Define the structure of the 'params' object here
    email: string;
    otp : string;
  }

export const UserService = {
    ...MasterService,

    VerifyOtp(params: OtpRequest) { // this is changes otpRequest
        return this.post('/userAdminAuth/verifyOtp', params)
    },
    createOtp(params: OtpRequest) {
        return this.post('/userAdminAuth/createOtp', params)
    },
    loginCaptcha() {
        return this.post('/userAdminAuth/getCaptchaImage')
    },
    getAllUsers(params: any) {
        return this.post('/user/userList', params)
    },
    googleWithLogin(params: OtpRequest) {
        return this.post('/userAdminAuth/googleWithLogin', params)
    },
    SendOtpToDeleteAccount(params:OtpRequest) {
        return this.post('/agency/auth/sendOtpToDeleteAccount', params)
    },
    deleteAccount(params:DeleteAccount) {
        return this.post('/agency/auth/deleteAcount', params)
    }
}