
import FullPageLoader from '@common/FullPageLoader';
import { useState,useEffect } from 'react';
import Captcha from "@src/common/Captcha";
import { CaptchaData } from "@src/common/Captcha";
import { sendOtpToDeleteAccount,deleteAccountRequest } from "@src/store/slices/user";
import { useNavigate } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';
import MobileUser from '@src/component/auth/MobileUser'



function DeleteAccount() {

    const [showMobileLinks, setShowMobileLinks] = useState(false);
    const [showEmailLinks, setShowEmailLinks] = useState(true);

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [showCaptcha, setShowCaptcha] = useState(0);


    const [inputField, setInputField] = useState({
        email: "",
        password: "",
        captcha: "",
        hash: "",
        mobile: "",
        otp : ""
    });
    const [errField, setErrField] = useState<any>({
        email: "",
        isCaptchaInvalid: false,
        captchaError: "",
        captcha: "",
    });

    const [showVerify, setShowVerify] = useState<boolean>(false);
    const [userId, setUserId] = useState<string>('');
    const [reloadCaptcha, setReloadCaptcha] = useState(1);
    const maxTime = 59;
    const [otpTimer, setOtpTimer] = useState(maxTime);

    const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setInputField({ ...inputField, [name]: value });
        errField[name] = '';
        setErrField({ ...errField });
    };

    const activeTabHandler = (selectedtab: any) => {
        setShowCaptcha((prev)=>prev+1);
    }

    const captchaFiledHandler = (captchaValue: string, captchaImage: CaptchaData) => {
        if (captchaImage.data) {
            setInputField({ ...inputField, captcha: captchaValue, hash: captchaImage.data.value });
        }
    };

    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i); // eslint-disable-line

    const validForm = () => {
        let formIsValid = true;
        setErrField({
            email: "",
            isCaptchaInvalid: false,
            captchaError: "",
            captcha: "",
            otp: ""
        });

        if(!showVerify){
            if (inputField.email === "") {
    
                formIsValid = false;
                setErrField((prevState: any) => ({
                    ...prevState,
                    email: "Email is required",
                }));
            } else if (!validEmailRegex.test(inputField.email)) {
    
                formIsValid = false;
                setErrField((prevState: any) => ({
                    ...prevState,
                    email: "Please enter valid email",
                }));
            }
            if (!inputField['captcha'].trim()) {
    
                formIsValid = false;
                setErrField((prevState: any) => ({
                    ...prevState,
                    captcha: "Captcha is required",
                }));
                setReloadCaptcha((prev) => prev + 1)
            }
        }


        if (showVerify) {
            if (inputField.otp === "" || inputField.otp.trim() === '') {
                formIsValid = false;
                setErrField((prevState: any) => ({
                    ...prevState,
                    otp: 'OTP is required',
                }));
            }
        }

        return formIsValid;
    };

    const submitButton = async () => {
        setLoading(true);
        if (validForm()) {
            let data = {email : inputField.email}
            sendOtpToDeleteAccount(data).then((res) => {
                setLoading(false)
                if (res && res.data) {
                    //DELETE CAPTCHA HASH FROM CACHE 
                    setUserId(res && res.data && res.data.user_id);
                    setShowVerify(true);

                }
                setReloadCaptcha((prev) => prev + 1)
                setInputField({ ...inputField, email: inputField.email, captcha: '' })

            });
        } else {
            setReloadCaptcha((prev) => prev + 1)
            setLoading(false);
        }
    }

    const deleteAccount = async () => {
        setLoading(true);
        if (validForm()) {
            let data = {email : inputField.email , otp : inputField.otp}
            deleteAccountRequest(data).then((res) => {
                setLoading(false)
                if (res && res.data) {
                    setInputField({
                        email: "",
                        password: "",
                        captcha: "",
                        hash: "",
                        mobile: "",
                        otp: ""
                    });
                    setShowVerify(false);
                }

            });
        } else {
            setReloadCaptcha((prev) => prev + 1)
            setLoading(false);
        }
    }

    const resendOtp = () => {
        if (otpTimer < 0) {
            let data = {email : inputField.email}
            sendOtpToDeleteAccount(data).then((res) => {
                setLoading(false)
                if (res && res.data) {
                    //DELETE CAPTCHA HASH FROM CACHE 
                    setUserId(res && res.data && res.data.user_id);
                    setShowVerify(true);

                }
                setReloadCaptcha((prev) => prev + 1)
                setInputField({ ...inputField, email: inputField.email, captcha: '' })

            });
        }
    }

    useEffect(() => {
        let startInterval: NodeJS.Timeout;
        if (otpTimer >= 0) {

            startInterval = setInterval(() => {
                setOtpTimer((val) => val - 1)

            }, 1000)
        } else {
            setOtpTimer(-1)
        }

        return () => {
            clearInterval(startInterval)

        }
    }, [otpTimer])

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="login-outer">
                    <div className="login-left-panel">
                        <div className="login-bg">
                            <div className="logo-img">
                            {<img src="/images/ocr_logo.png" className="" alt="insurance-logo"/>}
                            </div>

                            <div className="login-txt">
                                <span style={{"fontSize":"40"}}>Welcome.</span>
                                <h1 style={{"fontSize":"40"}}> Please sign in to OTO Insurance</h1>
                            </div>
                            {<img src="/images/login_bg.png" className="login-bg-img" alt="insurance-logo"/>}
                        </div>
                    </div>
                    <div className="login-right-panel">
                        <div className="logo-img m-xl-b">
                            <img src={'/images/ocr_logo.png'} className="" alt='logo' />
                        </div>
                        <div className="login-heading">
                            <h2>Request to delete data</h2>
                        </div>
                        <Tab.Container id="" defaultActiveKey="first" >
                            <div className="login-tab"> 
                                <Nav variant="pills" className="flex-column" onSelect={(k) => activeTabHandler(k)}>
                                {(showEmailLinks && showMobileLinks) &&
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">By Email</Nav.Link>
                                    </Nav.Item>
                                }
                                {(showEmailLinks && showMobileLinks) &&
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">By Mobile</Nav.Link>
                                    </Nav.Item>
                                }
                                </Nav>


                                <Tab.Content>
                                    

                                    <Tab.Pane eventKey="second">
                                        <MobileUser setShowEmailLinks = {setShowEmailLinks} setShowMobileLinks = {setShowMobileLinks} showCaptcha={showCaptcha}/> 
                                    </Tab.Pane>  

                                    <Tab.Pane eventKey="first">
                                        <div className="login-form">

                                            <FullPageLoader show={loading} />

                                            {showVerify ?

                                            (   
                                                <div>
                                                    <fieldset className="">
                                                        <div className = "material">
                                                            <input
                                                                type="number"
                                                                placeholder=" "
                                                                name="otp"
                                                                className="form-input "
                                                                onChange={inputHandler}
                                                            />
                                                            <i className="ic-otp icn-login">
                                                                <i className='path1'></i>
                                                                <i className='path2'></i>
                                                                <i className='path3'></i>
                                                                <i className='path4'></i>
                                                            </i>

                                                            <label data-label='Otp' className="form-label"></label>
                                                            {errField.otp && (
                                                                <span className="error-txt">
                                                                {errField['otp'] || ""}
                                                                </span>
                                                            )}
                                                        </div>
                                                        {
                                                            <p onClick={resendOtp} className={`resend-otp-txt ${otpTimer < 0 ? 'resend-otp' : ''}`}>{otpTimer < 0 ? 'Resend Otp' : 'resend otp after '+(otpTimer > 9 ? "0:" + otpTimer : "0:0" + otpTimer)+" seconds" }</p>
                                                        }


                                                    </fieldset>

                                                    <button type="button" className="btn-primary" onClick={deleteAccount} disabled={loading}>
                                                        Delete Account
                                                    </button>

                                                </div>
                                            )
                                            :
                                                <form autoComplete="on">
                                                    <fieldset className="">
                                                        <div className={"material " + (errField.email ? "error" : "")}    >
                                                            <input
                                                                type="text"
                                                                placeholder=" "
                                                                name="email"
                                                                value={inputField.email}
                                                                className="form-input"
                                                                onChange={inputHandler}
                                                            />
                                                            <i className="ic-email1 icn-login"></i>
                                                            <label data-label="Email" className="form-label"></label>
                                                            {errField.email && (
                                                                <span className="error-txt">
                                                                    {errField['email'] || ""}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </fieldset>
                                                    
                                                
                                                    <fieldset className=" captcha-filed">
                                                        <Captcha onKeyPressCaptcha={captchaFiledHandler} errField={errField} inputField={inputField} reloadCaptcha={reloadCaptcha || showCaptcha} />
                                                    </fieldset>

                                                    <div className="language-selectors">
                                                        <button type="button" className="btn-primary" onClick={submitButton} disabled={loading}>
                                                            Send Otp
                                                        </button>
                                                    </div>
                                                </form>
                                            }
                                        </div> 
                                    </Tab.Pane>  

                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </div>
    )

}


export default DeleteAccount