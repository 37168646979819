import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import MultiSelect from '@src/common/MultiSelect';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from '@common/ReactSelect';
import DatePicker from "react-multi-date-picker";
import { getSfaUsersByRole, updateSfaUserList } from '@src/store/slices/common';

const MoreFilterOption = (props: any) => {

    let{ masterConfig } = props;
    const dispatch: any = useDispatch();
    const insuranceState = useSelector((state: any) => state.insurance);

    const [selectedMoreFilters, setSelectedMoreFilters] = useState<any>({});
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [sfaUserList, setSfaUserList] = useState([]);

    useEffect(() => {
        if (Object.keys(insuranceState.insuranceListFilters).length) {
            setSelectedMoreFilters({ ...insuranceState.insuranceListFilters });
        }
    }, [insuranceState]);

    const handleChangeDateType = (selectedOption: any, key: string) => {

        let updatedMoreFilters: any = selectedMoreFilters;

        if (key === "from_date") {
            //here date range valye set into two different key        
            if (selectedOption.length === 2) {
                updatedMoreFilters["from_date"] = selectedOption[0] ? selectedOption[0].format("YYYY-MM-DD") : null;
                updatedMoreFilters["to_date"] = selectedOption[1] ? selectedOption[1].format("YYYY-MM-DD") : null;
            } else {
                updatedMoreFilters["from_date"] = selectedOption[0]?.format("YYYY-MM-DD");
                updatedMoreFilters["to_date"] = null; // Reset end date
            }
        }
        else if (key === "from_due_date") {
            //here date range valye set into two different key        
            if (selectedOption.length === 2) {
                updatedMoreFilters["from_due_date"] = selectedOption[0] ? selectedOption[0].format("YYYY-MM-DD") : null;
                updatedMoreFilters["to_due_date"] = selectedOption[1] ? selectedOption[1].format("YYYY-MM-DD") : null;
            } else {
                updatedMoreFilters["from_due_date"] = selectedOption[0]?.format("YYYY-MM-DD");
                updatedMoreFilters["to_due_date"] = null; // Reset end date
            }
        }
        else if(["created_by_ids"].includes(key)){
            updatedMoreFilters[key] = selectedOption.map((v:any)=> v.value);
        }
        else if(["lead_source", "username"].includes(key)){
            updatedMoreFilters[key] = selectedOption.map((v:any)=> v.value);
        }
        else if (key === "policy_effectivity_date") {
            //here date range valye set into two different key    
            if (selectedOption.length === 2) {
                updatedMoreFilters["policy_effectivity_date_from"] = selectedOption[0] ? selectedOption[0].format("YYYY-MM-DD") : null;
                updatedMoreFilters["policy_effectivity_date_to"] = selectedOption[1] ? selectedOption[1].format("YYYY-MM-DD") : null;
            } else {
                updatedMoreFilters["policy_effectivity_date_from"] = selectedOption[0]?.format("YYYY-MM-DD");
                updatedMoreFilters["policy_effectivity_date_to"] = null; // Reset end date
            }
        }
        else if (key === "policy_expiry_date") {
            //here date range valye set into two different key            
            if (selectedOption.length === 2) {
                updatedMoreFilters["policy_expiry_date_from"] = selectedOption[0] ? selectedOption[0].format("YYYY-MM-DD") : null;
                updatedMoreFilters["policy_expiry_date_to"] = selectedOption[1] ? selectedOption[1].format("YYYY-MM-DD") : null;
            } else {
                updatedMoreFilters["policy_expiry_date_from"] = selectedOption[0]?.format("YYYY-MM-DD");
                updatedMoreFilters["policy_expiry_date_to"] = null; // Reset end date
            }
            
        }
        else{
            updatedMoreFilters[key] = selectedOption['value'];

        }
    
        setSelectedMoreFilters({...updatedMoreFilters});

    };

    useEffect(()=>{
        setSelectedMoreFilters({...selectedMoreFilters, ...props.selectedFilters})
        // eslint-disable-next-line
    }, [props.selectedFilters])
    /**
     * FILTER SEARCH HANDLER
     */
    const onFilterSearch = () => {
        props.submitHandler({ ...props.selectedFilters, ...selectedMoreFilters, page_number: 1});
        setPopupOpen(false);

    }

    /**
     * FILTER RESET HANDLER
     */
    const onFilterReset = () => {
        setSelectedMoreFilters({});
        setSfaUserList([])
        dispatch(updateSfaUserList([]))
        props.updateSelectedFilters({type:'reset'});

        // props.submitHandler({activeTab: props.selectedFilters.activeTab});
        setPopupOpen(false);
    }
    
    const handleRoleSelection = async () => {
        props.loader(true)
        dispatch(getSfaUsersByRole({ role_id: [selectedMoreFilters['user_role']] })).then((res: any) => {
            if(res?.data) {
                setSfaUserList(res.data)
            }
        })
        props.loader(false)
    }
    return (
        <React.Fragment>
            <div className="filter-option">
                <Dropdown show={isPopupOpen} onToggle={(isOpen:any) => setPopupOpen(isOpen)}>
                    <div className="text-btn d-flex">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <span className="more-filter-txt">More Filters</span>
                            <span className="leass-filter-txt">Less Filters</span>
                        </Dropdown.Toggle>
                        <div className="btn-submit-reset top-btn-none">
                            <button className="btn-primary" onClick={onFilterSearch}>Search</button>
                            <button className="btn-reset" onClick={onFilterReset}>Reset</button>
                        </div>
                    </div>
                    <Dropdown.Menu>
                        <div className="more-filter-option">
                            <h3>More Filters</h3>
                            <div className="filter-form">
                                <div className="more-filter-form">
                                    <div>
                                        <span className="filter-heading">Date Type</span>

                                        <ReactSelect options={masterConfig['sub_status_list'] || []} value={masterConfig['sub_status_list']?.find((v: any) => +selectedMoreFilters['dateType'] === +v.value) || null} handleChange={(e: any, fieldKey: string) => handleChangeDateType(e, fieldKey)} placeholder="Select Date Type" fieldKey="dateType" />
                                    </div>
                                    <div>

                                        <span className="filter-heading">Select Date</span>
                                        <fieldset className='multi-datepicker'>
                                            <div className="material">

                                                <DatePicker
                                                    value={[selectedMoreFilters?.from_date, selectedMoreFilters?.to_date] || []}
                                                    onChange={(e: any) => handleChangeDateType(e, 'from_date')}
                                                    placeholder="Select Date"
                                                    range
                                                    disabled={!selectedMoreFilters?.dateType}
                                                />

                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                                <div className="more-filter-form">
                                    <div>
                                        <span className="filter-heading">Insurance Type</span>
                                        <ReactSelect options={masterConfig['insurance_type'] || []} value={masterConfig['insurance_type']?.find((v: any) => +selectedMoreFilters['insurance_type_id'] === +v.value) || null} handleChange={(e: any, fieldKey: string) => handleChangeDateType(e, fieldKey)} placeholder="Select Insurance Type" fieldKey="insurance_type_id" />
                                    </div>
                                    <div className="Date-picker-sec follow-up-sec">
                                        <span className="filter-heading">Application Method</span>
                                        <ReactSelect options={masterConfig['rule_engine'] || []} value={masterConfig['rule_engine']?.find((v: any) => +selectedMoreFilters['rule_engine_id'] === +v.value) || null} handleChange={(e: any, fieldKey: string) => handleChangeDateType(e, fieldKey)} placeholder="Select Application Method" fieldKey="rule_engine_id" />
                                    </div>
                                </div>
                                <div className="more-filter-form">
                                    <div>
                                        <span className="filter-heading">Created By</span>
                                        <fieldset className="multiselect-dropDown">
                                            <div className="material">
                                                <MultiSelect
                                                    options={masterConfig['insurance_role'] || []}
                                                    isSelectAll={true}
                                                    value={masterConfig['insurance_role']?.filter((v:any)=> selectedMoreFilters['created_by_ids']?.includes(v.value)) }

                                                    onChange={(e: any)=>handleChangeDateType(e, 'created_by_ids')}
                                                    placeholder="Select Created By"
                                                />
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div>
                                        <span className="filter-heading">Business Line</span>
                                        <ReactSelect options={masterConfig?.['business_lines'] || []} value={masterConfig['business_lines']?.find((v: any) => selectedMoreFilters['business_line'] === v.value) || null} handleChange={(e: any, fieldKey: string) => handleChangeDateType(e, fieldKey)} placeholder="Select Business Line" fieldKey="business_line" />
                                    </div>
                                </div>

                                <div className="more-filter-form">
                                    <div>
                                        <span className="filter-heading">User Role</span>
                                        <ReactSelect options={masterConfig?.['sfa_roles'] || []} value={masterConfig['sfa_roles']?.find((v: any) => +selectedMoreFilters['user_role'] === +v.value) || null} handleChange={(e: any, fieldKey: string) => {
                                            handleChangeDateType(e, fieldKey)
                                            handleRoleSelection()
                                        }} placeholder="Select User Role" fieldKey="user_role" />
                                    </div>
                                    <div>
                                        <span className="filter-heading">User Name</span>
                                        <fieldset className="multiselect-dropDown">
                                            <div className="material">
                                                <MultiSelect
                                                    options={sfaUserList || []}
                                                    isSelectAll={true}
                                                    value={sfaUserList.filter((user: any) => {
                                                        return selectedMoreFilters['username']?.includes(user.value)
                                                    })}
                                                    onChange={(e: any) => handleChangeDateType(e, 'username')}
                                                    placeholder="Select User name"
                                                />
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>

                                <div className="more-filter-form">
                                    <div>
                                        <span className="filter-heading">Employment Type</span>
                                        <ReactSelect options={masterConfig['employee_type'] || []} value={masterConfig['employee_type']?.find((v: any) => +selectedMoreFilters['employee_type'] === +v.value) || null} handleChange={(e: any, fieldKey: string) => handleChangeDateType(e, fieldKey)} placeholder="Select Employment Type" fieldKey="employee_type" />
                                    </div>
                                    <div>
                                        <span className="filter-heading">Lead Source</span>
                                        <fieldset className="multiselect-dropDown">
                                            <div className="material">
                                                <MultiSelect
                                                    options={masterConfig['lead_source'] || []}
                                                    isSelectAll={true}
                                                    value={masterConfig['lead_source']?.filter((v: any) => selectedMoreFilters['lead_source']?.includes(v.value))}
                                                    onChange={(e: any) => handleChangeDateType(e, 'lead_source')}
                                                    placeholder="Select Lead Source"
                                                />
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                                
                                <div className="more-filter-form">
                                    <div>
                                        <span className="filter-heading">Insurance Policy Effectivity </span>
                                        <fieldset className='multi-datepicker'>
                                            <div className="material">
                                                <DatePicker
                                                    value={[selectedMoreFilters?.policy_effectivity_date_from, selectedMoreFilters?.policy_effectivity_date_to] || []}
                                                    onChange={(e: any) => handleChangeDateType(e, 'policy_effectivity_date')}
                                                    placeholder="Select Date"
                                                    range
                                                />
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div>
                                        <span className="filter-heading">Insurance Policy Expiry Date</span>
                                        <fieldset className='multi-datepicker'>
                                            <div className="material">
                                                <DatePicker
                                                    value={[selectedMoreFilters?.policy_expiry_date_from, selectedMoreFilters?.policy_expiry_date_to] || []}
                                                    onChange={(e: any) => handleChangeDateType(e, 'policy_expiry_date')}
                                                    placeholder="Select Date"
                                                    range
                                                />
                                            </div>
                                        </fieldset>
                                    </div>

                                    

                                </div>

                                <div className="more-filter-form">
                                    <div>
                                        <span className="filter-heading">Payment Status</span>
                                        <ReactSelect options={masterConfig?.['paid_by_customer'] || []} value={masterConfig['paid_by_customer']?.find((v: any) => selectedMoreFilters['payment_status'] === v.value) || null} handleChange={(e: any, fieldKey: string) => handleChangeDateType(e, fieldKey)} placeholder="Payment Status" fieldKey="payment_status" />
                                    </div>

                                    <div>
                                        <span className="filter-heading">Renewal Status</span>
                                        <ReactSelect options={masterConfig?.['YesAndNo'] || []} value={masterConfig['YesAndNo']?.find((v: any) => selectedMoreFilters['renewal_status'] === v.value) || null} handleChange={(e: any, fieldKey: string) => handleChangeDateType(e, fieldKey)} placeholder="Renewal Status" fieldKey="renewal_status" />
                                    </div>
                                    
                                </div>
                                <div className="more-filter-form">
                                    <div>

                                        <span className="filter-heading">Due Date</span>
                                        <fieldset className='multi-datepicker'>
                                            <div className="material">

                                                <DatePicker
                                                    value={[selectedMoreFilters?.from_due_date, selectedMoreFilters?.to_due_date] || []}
                                                    onChange={(e: any) => handleChangeDateType(e, 'from_due_date')}
                                                    placeholder="Select Due Date"
                                                    range
                                                />

                                            </div>
                                        </fieldset>
                                    </div>
                                </div>


                                <div className="btn-submit-reset more-filter-btn">
                                    <button className="btn-primary" onClick={onFilterSearch}>Search</button>
                                    <button className="btn-reset" onClick={onFilterReset}>Reset</button>
                                </div>
                            </div>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </React.Fragment>
    );
};

export default MoreFilterOption;
