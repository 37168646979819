import React, { useState, useEffect } from 'react';
import ReactSelect from '../../common/ReactSelect';
import { Nav, Tab } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as HELPER from '@src/config/helper';
import { useDispatch, useSelector } from 'react-redux';
import { getBulkPaymentList } from '../../store/slices/bulkPayment';
import { getAgencyList, getAllAgencyList } from '@src/store/slices/agency';
import MultiSelect from '../../common/MultiSelect';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import FullPageLoader from '../../common/FullPageLoader';
import InputField from '../../common/InputField';
import ConfirmPopUp from '../../common/ConfirmPopUp';
import { ROLE_FEATURES, AGENCY_SOURCE } from '@src/config/constant';
import { getMasterConfig } from '../../store/slices/common';
import CurrencyInputField from '../../common/CurrencyInputField';
import { removeCurrencyFormatting } from '@src/config/helper';
import { ExpectedInstallmentDate } from '@src/common/DatePicker';
import moment from 'moment';



function PaymentAddEdit() {
    const dispatch: any = useDispatch();
    const [formData, setFormData] = useState<any>({});
    const [errors, setErrors] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false)
    const [selectedTab, setSelectedTab] = useState<string>('first');
    const [insuranceDetails, setInsuranceDetails] = useState<any>([]);
    const [previewImages, setPreviewImages] = useState<any>([]);
    const [imageSlider, setImageSlider] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [actionType, setActionType] = useState<any>('');
    const [pendingAction, setPendingAction] = useState<any>(null);
    const navigate = useNavigate();
    const queryParams = useParams();
    const commonState = useSelector((state: any) => state.common);
    const payment_id = queryParams.id
    const [leadIdsArr, setLeadIdsArr] = useState<any>([]);
    const [leadOptions, setLeadOptions] = useState<any>([]);
    const [trigger, setTrigger] = useState(0);
    const [editForm, setEditForm] = useState(true);
    const [disableField, setDisableField] = useState<boolean>(false);
    const [isLeadDisabled, setIsLeadDissable] = useState<boolean>(false);
    const [showPaymentReference, setShowPaymentReference] = useState<any>([{ date_time: '', payment_ref_no: '', disabled :'' }]);
    const [paymentRefArray, setPaymentRefArray] = useState<any>([]);
    // State to store initial data which need to reset during cancel button
    const [initialFormData, setInitialFormData] = useState({});
    // const [initialPaymentReference, setInitialPaymentReference] = useState([{ date_time: '', payment_ref_no: '' }]);
    const [bulkPaymentOrigData, setBulkPaymentOrigData] = useState<any>({});
    const [initialPaymentReference, setInitialPaymentReference] = useState([{ date_time: '', payment_ref_no: '', disabled :'' }]); 
    const [agents,setAgents] = useState<any>([]);

    // useEffect(() => {
    //     setLoading(true);
    //     if (!payment_id) {
    //         getLeadData({ paid_to: 4 });
    //     }
    //     // eslint-disable-next-line
    // }, [])

    useEffect(() => {
        if (Object.keys(commonState.common).length === 0) {
            dispatch(getMasterConfig());
        }
        // eslint-disable-next-line
    }, [commonState.common, dispatch]);

    const paymentTerms = commonState.common?.payment_term;
    const creditTerms = commonState.common?.agency_credit_terms;
    const paymentMethods = commonState.common?.payment_method_agency;
    const paymentTenure = commonState.common?.payment_tenure;
    const paidToWhomAgency = commonState.common?.paid_to_whom_agency;
    const getLeadData = async (params: any) => {
        setLoading(true);
        dispatch(getBulkPaymentList.getInsuranceIdsList(params)).then((res: any) => {
            if (res && res.status === 200) {
                let idsList = res.data;
                setLeadIdsArr(idsList)
                const options = idsList.map((item: any) => ({
                    label: `${item.lead_code} (${item.customer_name})`,
                    value: item.lead_id,
                    // isDisabled: item.is_Disabled ? true : false
                }));
                setLeadOptions(options);
                setLoading(false);
            }
        })
        setLoading(false);
    }

    // Fetching initial data and setting up the form
    useEffect(() => {
        // Check if 'payment_id' parameters are present
        if (payment_id && paidToWhomAgency) {
            let filters = {
                payment_id: payment_id,
                page_number: 1,
                isPaginationApply: true,
                payment_way:2
            }


            setLoading(true);
            setEditForm(false)
            dispatch(getBulkPaymentList.getBulkPayData(filters)).then((res: any) => {
                if (res && res.status === 200) {
                    setBulkPaymentOrigData(res.data)
                    setDisableField(true);
                    const paymentData = res.data;

                    getLeadData({ payment_id: payment_id, agent_id: paymentData.agent_id });
                    setSelectedTab(paymentData.paid_to === "Insurance Partner" ? "second" : "first");

                    const documentPaths: any = [];
                    paymentData?.documentList?.forEach((subArray: any[], subArrayIndex: number) => {
                        subArray.forEach((document: any) => {
                            const docType = document.url.indexOf('.pdf') !== -1 ? "pdf" : "image";
                            documentPaths[subArrayIndex] = documentPaths[subArrayIndex] || [];
                            documentPaths[subArrayIndex].push({ url: document.url, type: docType, id: document.id });
                        });
                    });

                    let payment_data = {};
                    
                    payment_data = {
                        paid_to: paidToWhomAgency.filter((item: any) => item.label === paymentData.paid_to)?.map((item: any) => item.value),
                        credit_terms: paymentData.credit_terms,
                        agent_id : paymentData.agent_id,
                        payment_method: paymentData.payment_method,
                        payment_term: paymentData.payment_term,
                        payment_tenure: paymentData.payment_tenure,
                        payment_ref_no: paymentData.payment_ref_no,
                        date_time: new Date(paymentData.date_time),
                        insurance_ids: paymentData.lead_info?.map((info: any) => info.lead_id),
                        id: paymentData.payment_id,
                        documents: paymentData.documentList,
                        isBulkPaymentFreezed: paymentData.isBulkPaymentFreezed
                    }
                    setIsLeadDissable(paymentData.id_lead_disable);
                    setInsuranceDetails(paymentData.lead_info);
                    setFormData(payment_data);
                    setPreviewImages(documentPaths);
                    // during edit set multiple payment_ref and date time
                   
                    const tenure_number = paymentData?.payment_tenure ? parseInt(paymentData.payment_tenure_name.split(' ')[0]) : 0;
                    const existingPaymentData = paymentData.payment_tenure_data || [];
                    const blankObjectsCount = tenure_number - existingPaymentData.length;
                    
                    const blankObjects = Array.from({ length: blankObjectsCount }, () => ({
                        date_time: '',
                        payment_ref_no: ''
                    }));
                    

                    const updatedPaymentData = [...existingPaymentData, ...blankObjects];
                    
                    setShowPaymentReference(updatedPaymentData);
                }
                setLoading(false);
            }).catch((err: Error) => {
                setLoading(false);
            })
        }
        // eslint-disable-next-line
    }, [payment_id, trigger, paidToWhomAgency]);

    useEffect(() => {
        // let agencyStatus: number = 1;
        fetchAgency({ status: []})
    }, [])

    const fetchAgency = (params: any) => {
        setLoading(true)
        getAllAgencyList(params).then((res: any) => {
            setLoading(false)

            if (res.status === 200) {
                const formattedAgents = res.data.map((agent: any) => ({
                    label: agent.name,
                    value: agent.id,    
                    credit_terms: agent.credit_terms,
                }));
                setAgents(formattedAgents);
            }
        }).catch(err => setLoading(false))
    }


    const handleTabSelect = (eventKey: string) => {
        setSelectedTab(eventKey);
        // getLeadData({ payment_id: payment_id, agent_id: formData.agent_id })
        // setInsuranceDetails([]);

        // formData["insurance_ids"] = [];
        // setFormData({ ...formData });
        let error = {};
        setErrors(error);

    };


    const getFormData = async () => {
        let postData: any = {};

        postData['payment_data'] = {
            status: 1,
            payment_way: 2
        };
        postData['insurance_details'] = [];
        postData['payment_data']['paid_to'] = selectedTab === "second" ? 5 : 4;
        postData['payment_data']['date_time'] = formData?.date_time;
        if (formData && formData.payment_method) postData['payment_data']['payment_method'] = +formData.payment_method;
        if (formData && formData.payment_term) postData['payment_data']['payment_term'] = +formData.payment_term;
        if (formData && formData.payment_tenure) postData['payment_data']['payment_tenure'] = +formData.payment_tenure;
        if (formData && formData.payment_term === 1) postData['payment_data']['payment_tenure'] = 0;
        if (formData && formData.credit_terms) postData['payment_data']['credit_terms'] = +formData.credit_terms;
        if (formData && formData.agent_id) postData['payment_data']['agent_id'] = +formData.agent_id;
        if (formData && formData.payment_ref_no) postData['payment_data']['payment_ref_no'] = formData.payment_ref_no;
        if (formData && formData.id) postData['payment_data']['id'] = +formData.id;
        if (insuranceDetails?.length) {
            postData['insurance_details'] = insuranceDetails.map((item: any) => ({
                lead_id: item.lead_id,
                amount: item.amount,
                total_fmv: item.total_fmv
            }))
        }
        postData['source'] = AGENCY_SOURCE['web']
        return postData;
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        let data: any = await getFormData();

        data.payment_ref_date_time = showPaymentReference;
        
        // Check the sequence of payment reference data
        if (showPaymentReference && isSequenceBroken(showPaymentReference)) {
            toast.error('Enter proper data in sequence');
            return;
        }

        if (await isFormValid(data)) {
            setLoading(true);

            // Check for duplicate payment_ref_no
            const paymentRefNos = data?.payment_ref_date_time?.map((item: any) => item.payment_ref_no);
            const duplicateValue = paymentRefNos.find((item: any, index: number) => paymentRefNos.indexOf(item) !== index);
            if (duplicateValue) {
                // Show error if duplicates are found
                setLoading(false);
                return toast.error(`Payment reference number '${duplicateValue}' already exists. Please try a unique one`);
            }
            

            if (data?.payment_data?.date_time) {
                delete data.payment_data.date_time;
            }

            if (data?.payment_data?.payment_ref_no) {
                delete data.payment_data.payment_ref_no;
            }
            

            // return false

            let paramObj = new FormData();
            let hasImages = false;
            // Add images to form data

            let sortedRefsAccToImages:any = []
            Object.keys(previewImages || {}).forEach((key) => {
                (previewImages[key] || []).forEach((imageObj: any) => {
                    if (imageObj.file) {
                        hasImages = true;
                        paramObj.append('images', imageObj.file);

                        if(data.payment_ref_date_time[key]){
                            sortedRefsAccToImages.push(data.payment_ref_date_time[key]['payment_ref_no'])
                        }
                    }
                });
            });

            if (hasImages) {
                data['isFileUpload'] = 1;
            }
            // return false;
            dispatch(getBulkPaymentList.saveBulkPaymentData(data)).then((res: any) => {
                if (res && res.status === 200) {
                    toast.success(res.message);
                    setFormData({ ...formData, id: res.data.id });


                    paramObj.append("payment_id", res.data.id);
                    paramObj.append("payment_term", data?.payment_data?.payment_term);
                    paramObj.append("paid_to_whom", data?.payment_data?.paid_to);
                    paramObj.append('source', "" + AGENCY_SOURCE['web']);
                    paramObj.append("payment_Ref_no", JSON.stringify(paymentRefArray));
                    paramObj.append("insurance_details", JSON.stringify(data.insurance_details));
                    paramObj.append("sortedRefsAccToImages", JSON.stringify(sortedRefsAccToImages));
                    paramObj.append("unique_ref_no", data.payment_ref_date_time?.map((ref: any) => ref.payment_ref_no));
                    setDisableField(true);
                    if (hasImages) {
                        toast.success("Image uploading in process");

                        getBulkPaymentList.savePaymentProofs(paramObj).then((resp: any) => {
                            if (resp && resp.status === 200) {
                                setLoading(false);
                                toast.success(resp.message);
                                setTrigger(trigger + 1);
                                setPaymentRefArray([]);
                                
                                if (!payment_id) {
                                    navigate(`/payment/edit/${res.data.hash_id}`);
                                }

                            }else{

                                if (!payment_id) {
                                    navigate(`/payment/edit/${res.data.hash_id}`);
                                }
                                setLoading(false);
                            }
                        }).catch((err: Error) => { setLoading(false) })
                    } else {
                        if (!payment_id) {
                            navigate(`/payment/edit/${res.data.hash_id}`);
                        }
                        setLoading(false)
                    }

                    
                    setEditForm(!editForm)
                } else {
                    setLoading(false);
                }
            }).catch((err: Error) => {
                setLoading(false);
            })
        } else {
            toast.error('Please ensure all required fields are completed.');
        }
    }


    const isFormValid = async (data: any) => {
        let isValid = true;

        let updatedError: any = {}
        if (!data.payment_data?.paid_to) {
            updatedError['paid_to'] = "Paid to is Required";
            isValid = false;
        }

        let refNumObj: any = {};
        data.payment_ref_date_time?.forEach((item: any, index: number) => {
            if (item?.payment_ref_no?.trim()) {
                refNumObj[item?.payment_ref_no] = (!refNumObj[item?.payment_ref_no]) ? 1 : +refNumObj[item?.payment_ref_no] + 1
            }

            if (index === 0) {
                if (!item.date_time || item.date_time === '') {
                    updatedError[`date_time_${index}`] = "Premium reference data & time is required";
                    isValid = false;
                }
                if (!item.payment_ref_no || item.payment_ref_no === '') {
                    updatedError[`payment_ref_no_${index}`] = "Payment reference no. is required";
                    isValid = false;
                }

                if (!(previewImages[index] && previewImages[index].length > 0)) {
                    updatedError[`images_${index}`] = "Please upload at least one document";
                    isValid = false;
                }

            } else {
                const isAnyFieldFilled = item.date_time || item.payment_ref_no || (previewImages[index] && previewImages[index].length > 0);
                const isAnyFieldEmpty = (!item.date_time || item.date_time === '') || (!item.payment_ref_no || item.payment_ref_no === '') || !(previewImages[index] && previewImages[index].length > 0);

                if (isAnyFieldFilled && isAnyFieldEmpty ) {
                    if (!item.date_time || item.date_time === '') {
                        updatedError[`date_time_${index}`] = "Premium reference data & time is required";
                        isValid = false;
                    }
                    if (!item.payment_ref_no || item.payment_ref_no === '') {
                        updatedError[`payment_ref_no_${index}`] = "Payment reference no. is required";
                        isValid = false;
                    }
                    if (!(previewImages[index] && previewImages[index].length > 0)) {
                        updatedError[`images_${index}`] = "Please upload at least one document";
                        isValid = false;
                    }
                    if (item.payment_ref_no && refNumObj[item?.payment_ref_no] > 1) {
                        updatedError[`payment_ref_no_${index}`] = "Payment Reference no. should be unique";
                        isValid = false;
                    }
                }
            }
        });

        if (!data.payment_data?.agent_id) {
            updatedError['agent_id'] = "Agent is Required";
            isValid = false;
        }

        if (!data.payment_data?.credit_terms) {
            updatedError['credit_terms'] = "Credit term is Required";
            isValid = false;
        }

        if (!data.payment_data?.payment_method) {
            updatedError['payment_method'] = "Payment method is Required";
            isValid = false;
        }

        if (!data.payment_data?.payment_term) {
            updatedError['payment_term'] = "Payment term is Required";
            isValid = false;
        }

        if (+data.payment_data?.payment_term === 2 && !data.payment_data?.payment_tenure) {
            updatedError["payment_tenure"] = 'Please select payment tenure';
            isValid = false;
        }

        if (!data.insurance_details?.length) {
            updatedError['insurance_ids'] = "Please select atleast one insurance id";
            isValid = false;
        }

        if (data.insurance_details?.length < 2) {
            updatedError['insurance_ids'] = "Please select atleast two insurance id";
            isValid = false;
        }

        data.insurance_details?.forEach((item: any, idx: any) => {
            if (!item.amount) {
                updatedError['amount_' + idx] = "Amount is required";
                isValid = false;
            }

            if (item.amount && item.total_fmv && +item.amount >= +item.total_fmv) {
                updatedError['amount_' + idx] = "Amount should be less than Total FMV";
                isValid = false;
            }
        })

        showPaymentReference.forEach((_: any, index: number) => {
            if (index === 0) {
                if (!payment_id && (!previewImages[index] || previewImages[index].length === 0)) {
                    updatedError[`images_${index}`] = "Please upload at least one document";
                    isValid = false;
                }
            } else {
                if (!payment_id && previewImages[index] && previewImages[index].length > 0 && previewImages[index].length < 1) {
                    updatedError[`images_${index}`] = "Please upload at least one document if any other field is filled";
                    isValid = false;
                }
            }
        });

        setErrors({ ...updatedError });
        return isValid;

    }


    const handleChange = (event: any, key: any, index: any = null) => {
        let value: any = '';
        let target = event?.target;
        if (key === 'date_time') {
            value = event || "";
            const newTenureArray: any = [...showPaymentReference];
            newTenureArray[index] = { ...newTenureArray[index], date_time: value };
            setShowPaymentReference(newTenureArray);
            errors[`date_time_${index}`] = '';
            // Added logic: Enable/Disable the next field based on the current and next field values
            for (let [idx, tenure] of newTenureArray.entries()) {
                if (idx + 1 < newTenureArray.length) {
                    const next = newTenureArray[idx + 1];
                    if (tenure?.date_time || tenure?.payment_ref_no) {
                        // Enable next field if the current one has a value
                        next['disabled'] = false;
                        // Reset the next date if the current date is greater
                        if (tenure?.date_time > next?.date_time) {
                            next['date_time'] = '';
                        }
                    }
                    else if (!tenure?.date_time && !tenure?.payment_ref_no && !next?.date_time && !next?.payment_ref_no) {
                        // Disable the next field if both current date and payment_ref_no are empty
                        next['disabled'] = true;
                    }
                }
            }
            setShowPaymentReference(newTenureArray);
            
        } else if (key === "insurance_ids") {
            value = event.map((v: any) => v.value);
            if (value?.length) {
                const selected: any = leadIdsArr.filter((item: any) => value.includes(item.lead_id));
                const savedArr = insuranceDetails?.filter((item: any) => value?.includes(item.lead_id));
                const savedMap = new Map(savedArr?.map((item: any) => [item.lead_id, item]));

                selected.forEach((item: any) => {
                    if (!savedMap.has(item.lead_id)) {
                        item.amount = removeCurrencyFormatting(item.pending_payment_net_of_commission);

                        savedArr.push(item);
                    }
                });
                setInsuranceDetails(savedArr);
                // setInsuranceDetails((prevState: any) => {
                //     const updated: any = savedArr;
                //     updated[index].amount = removeCurrencyFormatting(value);
                //     return updated;
                // });
            } else setInsuranceDetails([])
        }
        else if (key === "payment_tenure") {
            // reset payment ref no and dateTime
          
            const months = event.label.split(' ')[0];
            if (payment_id) {
                // remove preview from frontend
                let updatedPreviewImages = [...previewImages]
                if (months > previewImages.length) {
                    for (let i = previewImages.length; i < months; i++) {
                        updatedPreviewImages.push([]);
                    }
                } else {
                    // Slice the array if months are less than the current length
                    updatedPreviewImages = updatedPreviewImages.slice(0, months);
                }
                setPreviewImages(updatedPreviewImages);


                const existingTenure = [...showPaymentReference];
                let newTenureArray: any = [];
                if (months > existingTenure.length) {
                    const numberOfNewObjects = months - existingTenure.length;

                    // Push new objects based on the tenure value
                    for (let i = 0; i < numberOfNewObjects; i++) {

                        newTenureArray.push({ date_time: '', payment_ref_no: '', disabled: true });
                    }
                    newTenureArray = [...existingTenure, ...newTenureArray];
                } else {
                    newTenureArray = existingTenure.slice(0, months);
                }

                setShowPaymentReference(newTenureArray);
                value = event.value;
            } else {
                const newTenureArray: any = [];
                // push object base on tenure value
                for (let i = 0; i < months; i++) {
                    newTenureArray.push({ date_time: showPaymentReference?.[i]?.['date_time'] || "", payment_ref_no: showPaymentReference?.[i]?.['payment_ref_no'] || "", disabled: i > 0 });
                }
                setShowPaymentReference(newTenureArray);
                value = event.value;
            }

        }
        else if (key === "payment_ref_no") {
            value = target?.value;
            const newTenureArray = [...showPaymentReference];
            newTenureArray[index] = { ...newTenureArray[index], payment_ref_no: value};
            setShowPaymentReference(newTenureArray);
            errors[`payment_ref_no_${index}`] = '';

            // Added logic: Enable/Disable the next field based on the current and next field values
            if (index < showPaymentReference.length - 1) {
                const current = newTenureArray[index];
                const next = newTenureArray[index + 1];

                if (current.date_time || current.payment_ref_no || next.date_time || next.payment_ref_no) {
                    // Enable next field if the current one has a value
                    next.disabled = false;
                } else if (!current.date_time && !current.payment_ref_no && !next.date_time && !next.payment_ref_no) {
                    // Disable the next field if both current date and payment_ref_no are empty
                    next.disabled = true;
                }
            }
            setShowPaymentReference(newTenureArray);

        }
        else if (target) value = target?.value;
        else if (event) value = event.value;

        if (key === "payment_term") {
            if(+value === 1){

                if(formData?.payment_term && formData?.payment_term !== value) {
                    
                    setShowPaymentReference([{ date_time: '', payment_ref_no: '', disabled: false }]);
                    formData['payment_tenure'] = 0;

                    setPreviewImages({})

                }
            } else {
                if(formData?.payment_term && formData?.payment_term !== value) {
                    setPreviewImages({})
                }

            }
        }
        // **If agent is selected and has a credit_terms, update credit_terms automatically**
        if (key === "agent_id") {
            getLeadData({ agent_id: value});
            // Update credit_terms when agent_id is selected
            const selectedAgent = agents.find((agent: any) => agent.value === value);
            if (selectedAgent && selectedAgent.credit_terms) {
                // Automatically update credit_terms with agent's credit_terms
                formData['credit_terms'] = selectedAgent.credit_terms;
            }else{
                formData['credit_terms'] = null;
            }
        }
        formData[key] = value;
        errors[key] = '';

        setErrors({ ...errors });
        setFormData({ ...formData });

    }



    const handlePremiumChange = (index: number, value: string) => {
        setInsuranceDetails((prevState: any) => {
            const updated: any = [...prevState];
            updated[index].amount = removeCurrencyFormatting(value || 0);
            return updated;
        });

        errors['amount_' + index] = '';
        setErrors({ ...errors });
    };

    const handleRemoveInsurance = (lead:any, index: number) => {

        setShowConfirmation(true);
        setActionType('deleteInsuranceLead');
        setConfirmationMessage(`Are you sure you want to delete this insurance ?`);
        setPendingAction({ image: null, index, lead });



    };


    const showImagePopup = (activeImg: number) => {
        setImageSlider(!imageSlider)
        // setActiveImageIndex(activeImg)
    }


    const handleFileInputChange = async (e: any, index: any) => {
        
        let selectedFiles = e.target.files;
        let validFiles: File[] = [];
        let previewImage: any = [];

        if ((selectedFiles.length + (previewImages[index]?.length || 0)) > 5) {
            toast.error("Maximum 5 Documents are allowed")
            return;
        }

        for (const file of selectedFiles) {
            let allowedExtensions = ['data:application/pdf', 'data:image/*']
            let base64PreviewImg: any = await HELPER.toBase64(file);
            let filetype = await HELPER.detectMimeType(base64PreviewImg, { allowedExtensions });
            let fileSize = file.size;

            if (!filetype) {
                toast.error("Please Select a valid file");
            } else if (fileSize < 25000 || fileSize > 5000000) {
                toast.error('file size should be between 25kb to 5mb');
            } else {
                validFiles.push(file);
                previewImage.push({ url: base64PreviewImg, type: file.type.split('/')[1], file: file });
                // Append docObj to newDocArray
                // send payment_ref no along with each image
                setPaymentRefArray((prev: any) => [
                    ...prev,
                    showPaymentReference[index].payment_ref_no
                ]);

                // setValidSelectedFiles()


            }
        }


        if (validFiles.length > 0) {
            setPreviewImages((prev: any) => ({ ...prev, [index]: [...(prev[index] || []), ...previewImage] }));
        }

        e.target.value = '';

        //errors['images'] = ''
        errors[`images_${index}`] = '';
        if (!showPaymentReference[index].date_time && !showPaymentReference[index].payment_ref_no) {
            errors[`images_${index}`] = '';
        }
        setErrors(errors)

    }

    // delete image function
    const handleImageDelete = async (image: any, index: any) => {
        if (!image || !Object.keys(image).length) return;
        setShowConfirmation(true);
        setActionType('deleteImage');
        // setPendingAction({ image });
        setPendingAction({ image, index });
        setConfirmationMessage(`Are you sure you want to delete this document ?`);

    }


    const confirmYes = async () => {
        setShowConfirmation(false);

        const { image, index, lead }: any = pendingAction;
        if (actionType === 'deleteImage') {

            // remove from new entry
            if (image?.type === "new") {
                // Handle new image deletion
                setPreviewImages((prev: any) => {
                    const newPreviewImages = { ...prev };
                    if (newPreviewImages[index]) {
                        newPreviewImages[index] = newPreviewImages[index].filter((img: any) => img.url !== image.url);
                    }
                    return newPreviewImages;
                });

                setFormData((prev: any) => {
                    // Remove from new images
                    const newImages: any = { ...prev.images };
                    if (newImages[index]) {
                        newImages[index] = newImages[index].filter((img: any) => img.url !== image.url);
                    }

                    const newDocuments = (prev?.documents || []).map((docArray: any[]) =>
                        docArray.filter((img: any) => img.url !== image.url)
                    );

                    return {
                        ...prev,
                        images: newImages,
                        documents: newDocuments
                    };
                });
                toast.success('Image deleted successfully');

            }

            else {
                let savedImages = formData?.documents
                    ?.flat() // make the nested arrays into a single array
                    .filter((doc: any) => doc.id);

                try {
                    if (savedImages?.length > 1) {
                        setLoading(true)
                        let result: any = await getBulkPaymentList.deleteProofDocument({ payment_id: payment_id, id: image.id });
                        if (result.status === 200) {
                            setLoading(false)

                            // setPreviewImages((prev: any) => prev.filter((img: any) => img.id !== image.id));
                            // after remove image filter in nested array for remove form
                            setPreviewImages((prev: any) =>
                                prev.map((subArray: any[]) =>
                                    subArray.filter((img: any) => img.id !== image.id)
                                )
                            );

                            setFormData((prev: any) => ({
                                ...prev,
                                images: prev?.images?.filter((img: any) => img.id !== image.id),
                                documents: prev?.documents?.filter((img: any) => img.id !== image.id)
                            }));
                            //REMOVE DELETE DOC FROM PAYMENT REFERENCE ARRAY
                            setShowPaymentReference((prev:any)=>{
                                return prev.map((item:any)=>{
                                    if(item['documents']){
                                        item['documents'] = item?.documents?.filter((elm:any)=> elm.id !== image.id)
                                    }
                                    return item
                                })
                            })
                            toast.success('Image deleted successfully');
                        } else {
                            setLoading(false)
                            toast.error('Failed to delete image');
                        }
                    } else {
                        setLoading(false)
                        toast.error('Atleast One doc is required. Upload more to delete');
                    }
                } catch (error) {
                    setLoading(false)
                    toast.error('Failed to delete image');
                }
            }

        }

        if (actionType === "deleteInsuranceLead") {
            
            let result: any;


            if(payment_id){

                setLoading(true)
                result = await getBulkPaymentList.deleteBulkPaymentLead({ payment_id: payment_id, lead_id: lead.lead_id });
                setLoading(false)

            }
                
            if(result && result?.status === 200) {
                toast.success(result?.data || 'success')
                
            }

            setInsuranceDetails((prevState: any) => {
                let updated:any = [...prevState];
                updated = updated.filter((leads:any, idx: number)=> (idx!==index))//updated.map((leads:any, idx: number)=> (idx===index)? {...leads, status:0} : leads)
                formData['insurance_ids'] = updated.map((i: any) => i.lead_id)
                return updated;
            });
                
        }

        setActionType(null);
        setPendingAction(null);
    }

    const confirmNo = () => {
        setShowConfirmation(false);
        setActionType(null);
        setPendingAction(null);
    }

    const handleEdit = () => {
        if (!editForm) {
            // Storing initial form data before editing
            setInitialFormData({ ...formData });
            setInitialPaymentReference([...showPaymentReference]);
        } else {
            // Resetting form data to initial values on cancel
            setFormData({ ...initialFormData });
            setShowPaymentReference([...initialPaymentReference]);
            setErrors({});
        }

        setDisableField(!disableField);
        setEditForm(!editForm)
    }

    // Function to check if the sequence is broken
    const isSequenceBroken = (references: any[]): boolean => {
        let sequenceBroken = false;
        
        for (let i = 0; i < references.length - 1; i++) {
            const current = references[i];
            const next = references[i + 1];
    
            if ((current.date_time === '' || current.payment_ref_no === '') &&
                (next.date_time !== '' && next.payment_ref_no !== '')) {
    
                // Check which field is missing and set the appropriate error
                if (current.date_time === '') {
                    errors[`date_time_${i}`] = "Premium reference date & time is required";
                }
    
                if (current.payment_ref_no === '') {
                    errors[`payment_ref_no_${i}`] = "Payment reference no. is required";
                }
    
                sequenceBroken = true;
            }
        }
        setErrors({ ...errors }); // Update the errors state
        return sequenceBroken;
    };

    return (
        <>
            <div className="container-fluid">
                <FullPageLoader show={loading} />

                <div className="top-heading financer-lead-heding">
                    <h2>{payment_id ? 'Bulk Payment Update' : 'Bulk Payment Add'}</h2>
                    <div className='d-flex edit-approve-btn-top'>
                        {(payment_id && HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['insurance_bulk_payment'], accessType: 'edit' })) &&
                            <button className="btn-line" onClick={handleEdit}>
                                {
                                    !editForm ? <> <i className='ic-edit m-xs-r'></i> <span>Edit</span></> : <span>Cancel</span>
                                }
                            </button>

                        }

                    </div>
                </div>

                <div className="rule-engine-menu-sec p-lg">
                    <h2>Select Agent</h2>
                    <div className='form-select-bx col-md-4 partner-add-edit m-xs-t' style={{ marginLeft: '-16px' }}>
                        <ReactSelect
                            options={agents || []}
                            value={formData && agents.find((agent: any) => agent.value === formData?.agent_id)}
                            handleChange={(e: any) => handleChange(e, "agent_id")}
                            placeholder="Select Agent*"
                            fieldKey="agent_id"
                            isDisabled={formData.isBulkPaymentFreezed || disableField || payment_id}
                            errorField={errors?.agent_id ? errors?.agent_id : ''}
                        />
                    </div>
                    <br style={{ clear: 'both' }} />
                    <h2 className='m-xl-t'>Agency Paid to Whom?</h2>
                    <Tab.Container id="left-tabs-example" activeKey={selectedTab}>
                        <div className="p-rel p-lg-t">
                            <div className="role-management-top-sec">
                                <div className="selected-chips">
                                    <Nav variant="pills" className="flex-column nav nav-pills" >
                                        <div className="tab-list d-flex m-lg-b">
                                            <Nav.Item onClick={() => handleTabSelect("first")}>
                                                <Nav.Link eventKey="first" disabled={payment_id ? true : false}>
                                                    OTO Insurance
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item onClick={() => handleTabSelect("second")}>
                                                <Nav.Link eventKey="second" disabled={payment_id ? true : false}>Insurance Partner</Nav.Link>
                                            </Nav.Item>

                                        </div>
                                    </Nav>
                                </div>

                            </div>
                        </div>

                    </Tab.Container>


                    <div className="row agency-payment-form">

                        <div className='form-select-bx col-md-4 p-sm-b'>
                            <ReactSelect
                                options={paymentMethods ?? []}
                                value={formData && paymentMethods?.filter((v: any) => +v.value === +formData?.payment_method)} handleChange={(e: any) => handleChange(e, "payment_method")}
                                placeholder="Payment Method *"
                                fieldKey="payment_method"
                                errorField={errors?.payment_method ? errors?.payment_method : ''}
                                isDisabled={formData.isBulkPaymentFreezed || disableField} />
                        </div>


                        <div className='form-select-bx col-md-4 p-sm-b'>
                            <ReactSelect
                                options={paymentTerms ?? []}
                                value={formData && paymentTerms?.filter((v: any) => +v.value === +formData?.payment_term)} handleChange={(e: any) => handleChange(e, "payment_term")}
                                placeholder="Payment Term *"
                                fieldKey="payment_term"
                                errorField={errors?.payment_term ? errors?.payment_term : ''}
                                isDisabled={formData.isBulkPaymentFreezed || disableField} />
                        </div>


                        {
                            paymentTerms?.find((v: any) => +formData?.payment_term === +v.value)?.label === "Installment"
                                ?
                                <div className='form-select-bx col-md-4 p-sm-b'>
                                    <ReactSelect
                                        options={paymentTenure?.filter((pay: any) => +pay.payment_method === +formData.payment_method && +pay.payment_term === +formData.payment_term)?.map((el:any)=>({...el, label: el.label_for_agency}))}
                                        value={formData && paymentTenure?.filter((v: any) => +v.value === +formData?.payment_tenure)}
                                        handleChange={(e: any) => handleChange(e, "payment_tenure")}
                                        placeholder="Number of Payments*"
                                        fieldKey="payment_tenure"
                                        errorField={errors?.payment_tenure ? errors?.payment_tenure : ''}
                                        isDisabled={formData.isBulkPaymentFreezed || disableField} />
                                </div>
                                : ''
                        }

                        <div className='form-select-bx col-md-4 p-sm-b'>
                            <ReactSelect
                                options={creditTerms ?? []}
                                value={formData && creditTerms?.filter((v: any) => +v.value === +formData?.credit_terms)} handleChange={(e: any) => handleChange(e, "credit_terms")}
                                placeholder="Credit Terms*"
                                fieldKey="credit_terms"
                                errorField={errors?.credit_terms ? errors?.credit_terms : ''}
                                isDisabled={formData.isBulkPaymentFreezed || disableField} />
                        </div>

                        {
                            showPaymentReference?.map((val: any, index: any) => (
                                <React.Fragment key={index}>
                                    <div className='form-select-bx col-md-4 p-sm-b'>
                                        <fieldset className="date-picker-detail p-sm-b">
                                            <div className={(showPaymentReference[index].date_time) ? "active-label material animation-effect" : "material animation-effect "}>
                                                {/* <InsuranceDate
                                            onChange={(e: Date) => handleChange(e, "date_time",index)}
                                            placeholder=""
                                            text={`Payment Reference Date & Time ${index+1} ${index === 0 ? '*' : ''}`}
                                            insurancePolicyDate={showPaymentReference?.[index]?.date_time !== '' ? new Date(showPaymentReference[index].date_time) : '' }
                                            disabled={formData.isBulkPaymentFreezed || disableField}
                                        /> */}

                                                <ExpectedInstallmentDate
                                                    onChange={(e: Date) => handleChange(e, "date_time", index)}
                                                    // selected={showPaymentReference?.[index]?.date_time !== '' ? new Date(showPaymentReference[index].date_time) : !index ? new Date() : (showPaymentReference?.[index-1]?.date_time ? moment(new Date(showPaymentReference?.[index-1]?.date_time)).add(1, 'd').toDate() : "")}
                                                    selected={showPaymentReference?.[index]?.date_time !== '' ? new Date(showPaymentReference[index].date_time) : ""}
                                                    dateFormat="dd MMM, yyyy h:mm aa"
                                                    disabled={(formData.isBulkPaymentFreezed && bulkPaymentOrigData?.['payment_tenure_data']?.[index]?.['payment_ref_no']) || disableField || val.disabled}
                                                    installmentNumber={index + 1}
                                                    prevInstallmentDate={showPaymentReference?.[index - 1]?.date_time || null}
                                                    text={`Payment ${(formData?.payment_tenure) ? (index + 1) : ""} Date${index === 0 ? "*" : ""}`}
                                                    source="detailSection"
                                                    showTimeSelect={true}
                                                    minDateAgency={new Date((new Date().getFullYear() - 1) + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate())}
                                                />
                                                {
                                                    errors[`date_time_${index}`]
                                                        ?
                                                        <span className="error-txt">{errors[`date_time_${index}`]}</span>
                                                        :
                                                        ''
                                                }
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div className='form-select-bx col-md-4 p-sm-b'>
                                        <fieldset className="" key={`payment_ref_no_${index}`}>
                                            <div className="material">
                                                <InputField
                                                    inputProps={{
                                                        id: `payment_ref_no_${index}`,
                                                        name: `payment_ref_no_${index}`,
                                                        className: "form-input",
                                                        type: "text",
                                                        value: showPaymentReference[index].payment_ref_no || '',//[{ref:2,date},{ref:2}]
                                                        maxLength: "",
                                                        disabled: (formData.isBulkPaymentFreezed && bulkPaymentOrigData?.['payment_tenure_data']?.[index]?.['payment_ref_no']) || disableField || val.disabled,
                                                        placeholder: " ",
                                                    }}
                                                    onChange={(e: any) => handleChange(e, "payment_ref_no", index)}
                                                />
                                                <label data-label={`Payment ${(formData?.payment_tenure) ? (index + 1) : ""} Reference ${index === 0 ? "*" : ""}`} className="form-label"></label>
                                                {
                                                    errors[`payment_ref_no_${index}`]
                                                        ?
                                                        <span className="error-txt">{errors[`payment_ref_no_${index}`]}</span>
                                                        :
                                                        ''
                                                }
                                            </div>
                                        </fieldset>
                                    </div>
                                </React.Fragment>

                            ))
                        }
                    </div>



                    <div className='search-insurance-id-outer'>
                        <h3>Search Insurance IDs</h3>
                        <div className='row '>
                            <fieldset className="multiselect-dropDown search-multiselect col-md-4">

                                <div className={"material animation-effect "}>
                                    <MultiSelect
                                        options={leadOptions}
                                        isSelectAll={true}
                                        value={leadOptions?.filter((v: any) => formData?.['insurance_ids']?.includes(v.value))}
                                        onChange={(e: any) => handleChange(e, 'insurance_ids')}
                                        placeholder={"Search Insurance Ids"}
                                        isDisabled={disableField || formData?.isBulkPaymentFreezed}
                                        isOptionDisabled={(option:any) => option.is_insurance_lead_freezed}
                                    />
                                    {
                                        errors && errors["insurance_ids"]
                                            ?
                                            <span className="error-txt">{errors["insurance_ids"]}</span>
                                            :
                                            ''
                                    }

                                </div>
                            </fieldset>
                        </div>
                        <div className='row'>
                            {insuranceDetails?.map((detail: any, index: any) => (
                                <div key={index} className={`insurance-detail col-md-4 p-lg-t ${typeof detail.status !== "undefined" ? "hide" : ""}`}>
                                    <div className='bg-white p-lg insurance-id-list'>
                                        <div className="detail-header">
                                            <span>ID {detail.lead_code} • {detail.agency_name} • {detail.customer_name}</span>

                                        </div>
                                        <div className="form-group p-md-t">
                                            <fieldset className="">
                                                <div className="material">
                                                    <CurrencyInputField
                                                        inputProps={{
                                                            id: "",
                                                            name: "amount",
                                                            className: "form-input",
                                                            type: "text",
                                                            value: removeCurrencyFormatting(detail?.amount) || removeCurrencyFormatting(detail?.pending_payment_net_of_commission) || 0,
                                                            maxLength: "",
                                                            placeholder: " ",
                                                            disabled: disableField || (formData.isBulkPaymentFreezed && detail.payment_id),
                                                        }}
                                                        onChange={(e: any) => handlePremiumChange(index, e.target.value)}
                                                    />
                                                    <label data-label={`Premium Amount`} className="form-label"></label>
                                                    {
                                                        errors && errors["amount_" + index]
                                                            ?
                                                            <span className="error-txt">{errors["amount_" + index]}</span>
                                                            :
                                                            ''
                                                    }
                                                </div>


                                            </fieldset>
                                        </div>
                                        {/* {((detail.is_insurance_lead_freezed && !detail.payment_id) || (!detail.is_insurance_lead_freezed && detail.payment_id)) && !disableField && <div className="close-icn" onClick={() => handleRemoveInsurance(detail,index)}>
                                            <i className="ic-cancel"></i>
                                        </div>} */}
                                        {/* {((detail.is_insurance_lead_freezed && !detail.payment_id) || 
                                            (!detail.is_insurance_lead_freezed && detail.payment_id)) &&  */}
                                        {!detail.is_insurance_lead_freezed && !disableField && 
                                            ( // Added condition to check isLeadDisabled
                                                <div className="close-icn pointer" onClick={() => handleRemoveInsurance(detail, index)}>
                                                    <i className="ic-cancel"></i>
                                                </div>
                                        )}
                                    </div>

                                </div>
                            ))}
                        </div>

                    </div>

                    {
                        showPaymentReference.map((val: any, index: any) => (
                            <React.Fragment key={index}>
                                <div className='upload-heading-text'>
                                    <h3>{`Upload Payment Proof ${ formData.payment_tenure ? (index + 1) : "" } ${index === 0 ? '*' : ''}`}</h3>
                                </div>



                                <div className='row p-lg-b'>
                                    {previewImages[index]?.length > 0 && previewImages[index].map((image: any, imgIndex: any) => (
                                        <div className="col-md-2 p-lg-t" key={`doc-${imgIndex}`}>

                                            <div key={imgIndex} className="upload-image-proof">
                                                {
                                                    image?.type?.indexOf("pdf") !== -1
                                                        ?
                                                        <i className={`ic-${image?.type}-file icons-normal t-accent`} onClick={() => showImagePopup(index)} style={{ fontSize: "100px" }}></i>
                                                        :
                                                        <img src={image.url} alt={`preview-${index}`} className="upload-icn" onClick={() => showImagePopup(index)} />

                                                }

                                                {!(formData.isBulkPaymentFreezed && image.id && bulkPaymentOrigData?.['payment_tenure_data']?.[index]?.['payment_ref_no']) && !disableField && <div className="close-icn" onClick={() => handleImageDelete({ ...image, id: (image.id || index), type: (!image.id ? "new" : "old") }, index)}>
                                                    <i className="ic-clearclose"></i>
                                                </div>}
                                            </div>

                                        </div>
                                    ))
                                    }

                                    <div className="col-md-2 p-lg-t">
                                        {
                                            (previewImages[index]?.length || 0) < 5 &&
                                            <div className="add-document-proof">
                                                {
                                                    <input type="file" className="upload-image-input" name="documents" title=""
                                                        accept="image/*, application/pdf"
                                                        onChange={(e: any) => handleFileInputChange(e, index)} multiple disabled={disableField} />
                                                }
                                                <i className="ic-add"> </i>
                                                <span className='add-doc-text d-block'>Add Document</span>
                                            </div>
                                        }

                                        {
                                            errors[`images_${index}`]
                                                ?
                                                <span className="error-txt error-left">{errors[`images_${index}`]}</span>
                                                :
                                                ''
                                        }
                                    </div>

                                </div>
                            </React.Fragment>
                        ))
                    }


                    <div className="btn-bottom t-left p-lg-t">
                        {

                            HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['insurance_bulk_payment'], accessType: 'edit' }) &&
                            <button className="btn-primary" onClick={handleSubmit} disabled={disableField}>
                                Submit Details
                            </button>
                        }
                    </div>

                </div>

                {showConfirmation === false ? ("") : (
                    <ConfirmPopUp
                        confirmNo={confirmNo}
                        confirmYes={confirmYes}
                        message={confirmationMessage} />
                )}


            </div>
        </>
    );
};

export default PaymentAddEdit;

