import { useEffect, useState } from 'react';
import Modal from "../../../common/ModalPopup";
import { Tab } from 'react-bootstrap';
import { agencyCommentHistory } from '../../../store/slices/agency';
import dateFormat from "dateformat";


function LeadTimelineModal(props: any) {

    const {viewTimeLine, agency_id} = props;


    const [loading, setLoading] = useState(false);

    const [timelineList, setTimelineList] = useState([]);


    useEffect(()=>{

        if(viewTimeLine){
            setLoading(true);
            agencyCommentHistory({ agency_id }).then((res: any) => {
                setLoading(false);

                if (res.data && res.data.length) {
                    setTimelineList(res.data)
                }
            }).catch(err=> setLoading(false));
        }

    // eslint-disable-next-line
    }, [viewTimeLine])

    return (
        

            <div className="view-timeline-popup image-timeline-popup">
                <Modal show={viewTimeLine} handleClose={() => props.handleViewTimeLine(false)} >
                    <div className="modal-header">
                        <h2>Comment History</h2>
                    </div>

                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                        <div className="modal-body" >

                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <table>
                                        <tbody>

                                            {
                                                !loading && timelineList.length
                                                    ?
                                                    timelineList.map((timeline: any, idx: number) =>
                                                        <tr key={idx+1}>
                                                            <td>
                                                                <span className="date">{dateFormat(timeline.created_at, 'd mmm')}</span>
                                                                <span className="time">{dateFormat(timeline.created_at, 'hh:MM tt')}</span>
                                                            </td>
                                                            <td>
                                                                <span className="heading">{timeline.comment}</span>
                                                                {/* <span className="">{timeline.comment}</span> */}
                                                            </td>
                                                            <td>{timeline.created_by_name || 'Admin'}</td>
                                                        </tr>
                                                    )
                                                    :
                                                    loading ? <tr><td><p style={{ textAlign: "center" }}>Loading...</p></td></tr> : <tr><td><p style={{ textAlign: "center" }}>No data found</p></td></tr>

                                            }

                                        </tbody>
                                    </table>

                                </Tab.Pane>

                            </Tab.Content>
                        </div>

                    </Tab.Container>
                </Modal>

            </div>

    )
}

export default LeadTimelineModal;