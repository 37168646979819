import React, { useState } from 'react';
import { AGENCY_PREFILLED_KEYS, APP_TYPE_AGENCY, FIELD_TYPE, INS_TYPE_COMPREHENSIVE, INS_TYPE_CTPL, WITHOUT_RULE_ENGINE } from '@src/config/constant';
import ReactSelect from '@src/common/ReactSelect';
import { Calendra2 } from '@src/common/DatePicker';
import { useSelector, useDispatch } from 'react-redux';
import InsuranceService from '@src/services/InsuranceService';
import { getMMVList } from '@src/store/slices/common';

export function DynamicFormField(props: any) {
    const { getFieldItem, autoFillStatus } = props
    //eslint-disable-next-line
    const [refresh, setRefresh] = useState(1)

    const dispatch: any = useDispatch()

    const insurance = useSelector((state: any) => state.insurance)
    const commonState = useSelector((state: any) => state.common);

    const preFilledKeys = AGENCY_PREFILLED_KEYS

    const rowClass = (props.rowClass) ? props.rowClass : "col-md-4"

    const isSameAsSelected = () => {
        let sameAsField = props?.fields?.filter((item: any) => item.name === 'per_sameas_cur')?.[0]
        if (sameAsField) {
            return sameAsField?.value
        }
        return false
    }

    const isEnable = (fieldItem: any) => {
        if (fieldItem?.name?.includes('cur_') && isSameAsSelected()) { return false }
        return fieldItem.is_enable
    }

    const isShowDynamicField = (detail: any) => {
        if (detail?.field_show) {
            const splitType = detail?.field_show?.split('/')
            if (splitType?.length > 1) {
                const filterFieldItem = props?.fields?.filter((each: any) => each.name === splitType[0])?.[0]
                if (filterFieldItem?.value?.value == splitType[1] || filterFieldItem?.value == splitType[1]) {  // eslint-disable-line
                    return true;
                }
                else return false;
            }
        }
        return !!detail?.['is_show']
    }

    const onValueSelect = (fieldItem: any, event: any) => {
        //code to auto fill agency using ORCR img recognition data
        let parentKeysForPrefill = ['version']
        if (autoFillStatus && parentKeysForPrefill.includes(fieldItem.name)) {
            let dependencyMapping: any = { version: ['color', 'fuel_type', 'seat_capacity'] }
            let items: any = []
            if (Array.isArray(dependencyMapping[fieldItem.name])) {
                for (let key of dependencyMapping[fieldItem.name]) {
                    let item = getFieldItem(key)
                    items.push(item)
                }
            } else {
                items.push(getFieldItem(dependencyMapping[fieldItem.name]))
            }

            if (items.length) {
                for (let item of items) {
                    if (item.value && typeof item.value === 'object' && item.value?.label) {
                        let findDataInOptions = item.options?.find((op: any) => {
                            if(typeof op.label === 'string' && typeof item.value.label === 'string') {
                                return op.label.toLowerCase() === item.value.label.toLowerCase()
                            } else {
                                return op.label === item.value.label
                            }
                        })
                        if (findDataInOptions) {
                            item['value'] = findDataInOptions
                        }
                    }
                }
            }
        }

        if (fieldItem.name === 'make' && event.value === 0) {
            let modelItem = getFieldItem('model')
            modelItem['value'] = event
            modelItem['value_selected'] = event

            let versionItem = getFieldItem('version')
            versionItem['value'] = event
        }
        else if (fieldItem.name === 'model' && event.value === 0) {
            let versionItem = getFieldItem('version')
            versionItem['value'] = event
        }

        fieldItem['value'] = event
        fieldItem['error_message'] = ''

        //store date in string format to prevent redux state error
        if (fieldItem?.name?.includes('date')) {
            fieldItem['value'] = event ? event.toString() : event
        }
        resetChildDependecies(fieldItem)

        switch (fieldItem?.name) {
            case 'make_year':
            case 'version':
                // fetchCarFMV()
                if (fieldItem.name === 'version') {
                    props?.onVersionSelect(event)
                }
                break
            case 'per_city':
                fetchPermanentLocality(fieldItem)
                break
            case 'cur_city':
                if (!isSameAsSelected()) {
                    fetchCurrentLocality(fieldItem)
                }
                break
            case 'per_barangay':
                preFillPostalCode(event?.pin_code, fieldItem?.child_depedencies, fieldItem?.name);
                break;
            case 'cur_barangay':
                preFillPostalCode(event?.pin_code, fieldItem?.child_depedencies, fieldItem?.name)
                break;
            case 'insurance_type':
                fetch_mgf_year(fieldItem)
                handlePlateMMVField(fieldItem)
                break;
            case 'payment_term':
                fetchPaymentTenure(fieldItem)
                break;
            case 'payment_method':
                fetchPaymentTerm()
                break;
            case 'paid_from_agency':
                fetchAgencyPaymentDetails()
                break;
            default:
                break
        }

        if (isSameAsSelected()) {
            prefillSameAsAddress(fieldItem)
        }
    }

    const handlePlateMMVField = (params: any) => {
        let { value } = params
        let mvNumber = getFieldItem('mv_number')
        let plateNumber = getFieldItem('plate_number')

        if (value?.value === INS_TYPE_CTPL) {
            mvNumber['is_show'] = true
            mvNumber['conditional_mandatory'] = true
            plateNumber['is_required'] = false
            plateNumber['conditional_mandatory'] = true
        } else {
            mvNumber['is_show'] = false
            plateNumber['is_required'] = true
            mvNumber['conditional_mandatory'] = false
            plateNumber['conditional_mandatory'] = false
        }
    }

    const fetchAgencyPaymentDetails = () => {
        let payFromAgency = getFieldItem('paid_from_agency')
        if (payFromAgency?.value?.value) {
            let paidTo = getFieldItem('paid_to_whom')
            let paymentWay = getFieldItem('payment_way')

            if (payFromAgency.value.value === '1') {
                paidTo['is_show'] = true
                paymentWay['is_show'] = true
            } else {
                paidTo['is_show'] = false
                paymentWay['is_show'] = false
            }
        }
    }

    const prefillSameAsAddress = (fieldItem: any) => {
        if (fieldItem.name.includes('per_')) {
            const keyName = fieldItem?.name?.replace('per_', 'cur_');
            let fields = props?.fields?.filter((item: any) => item.name === keyName)
            if (fields.length) {
                if (isSameAsSelected()) {
                    fields[0]['is_enable'] = false
                } else {
                    fields[0]['is_enable'] = true
                }
                if (fields[0].name === "cur_postal_code") {
                    fields[0]['is_enable'] = false
                }
                fields[0].value = fieldItem.value
                fields[0].error_message = ""

            }
        }
    }

    const preFillPostalCode = (pinCode: any, child_depedencies: any, fieldName: any) => {
        const fieldData = getFieldItem(child_depedencies[0]);
        if (fieldData) {
            fieldData['value'] = pinCode + ''
            fieldData['error_message'] = ''
            if (props.onValueChange) props.onValueChange(fieldData)
        }

        if (isSameAsSelected() || fieldName === 'cur_barangay') {
            const curfieldData = getFieldItem('cur_postal_code')
            if (curfieldData) {
                curfieldData['value'] = pinCode + ''
                curfieldData['error_message'] = ''
                if (props.onValueChange) props.onValueChange(curfieldData)
            }
        }
    }

    const fetchPermanentLocality = async (fieldItem?: any) => {
        let response = await InsuranceService.getlocalityByCity({ city: [fieldItem.value.value] })
        let perBarangayField = getFieldItem('per_barangay')
        if (perBarangayField) {
            perBarangayField['options'] = response?.data?.data
        }

        if (isSameAsSelected()) {
            let perBarangayField = getFieldItem('cur_barangay')
            if (perBarangayField) {
                perBarangayField['options'] = response?.data?.data
                setRefresh((prev) => prev + 1)
            }
        }
    }

    const fetchCurrentLocality = async (fieldItem?: any) => {
        let perBarangayField = getFieldItem('cur_barangay')
        if (perBarangayField) {
            let response = await InsuranceService.getlocalityByCity({ city: [fieldItem.value.value] })
            if (response?.status === 200) {
                if (response?.data?.data) {
                    perBarangayField['options'] = response.data.data
                    setRefresh((prev) => prev + 1)
                }
            }
        }
    }

    const fetchPaymentTerm = () => {
        let pay_term = (commonState?.common?.payment_term) ? commonState.common.payment_term : []
        let payTermField = getFieldItem('payment_term')
        if (payTermField) {
            // payTermField['options'] = insurance_type === INS_TYPE_CTPL ? pay_term?.filter((opt: any) => opt.value !== PAYMENT_TERM_INSTALLMENT) : pay_term
            payTermField['options'] = pay_term
        }
    }

    const fetchPaymentTenure = (fieldItem?: any, params?: any) => {
        let isPaymentMethodSelected = props?.fields?.find((fieldItem: any) => fieldItem.name === "payment_method" && (fieldItem.value || params?.payment_method)) //use params payment method if apptype renewal
        if (isPaymentMethodSelected) {
            let payment_tenure = (commonState?.common?.payment_tenure) ? commonState.common.payment_tenure : []
            let payment_method_id = isPaymentMethodSelected.value.value || params?.payment_method
            let paymentTenureField = getFieldItem('payment_tenure')
            if (paymentTenureField) {
                let options = payment_tenure?.filter((el: any) => el.payment_method === payment_method_id && el.payment_term === (fieldItem?.value?.value || params?.payment_term)) //use params payment term if apptype renewal
                paymentTenureField['options'] = options

                if ((!options || !options.length) && fieldItem?.value?.label?.trim() === 'One time Payment') paymentTenureField['is_show'] = false
                else paymentTenureField['is_show'] = true
            }
        }
    }

    const fetchCarFMV = async () => {
        let vehicleUsaseItem = getFieldItem('vehicle_usage')
        let versionItem = getFieldItem('version')
        let manufacturingItem = getFieldItem('make_year')
        if ((typeof vehicleUsaseItem.value === "object") ? vehicleUsaseItem.value.value : vehicleUsaseItem.value && ((typeof versionItem.value === "object") ? versionItem.value.value : versionItem?.value) && ((typeof manufacturingItem.value === "object") ? manufacturingItem.value.value : manufacturingItem?.value)) {
            const params = {
                rule_engine: insurance?.createInsuranceAgency?.rule_engine,
                vehicle_usage: (typeof vehicleUsaseItem.value === "object") ? vehicleUsaseItem.value.value : vehicleUsaseItem.value,
                version_id: ((typeof versionItem.value === "object") ? versionItem.value.value : versionItem?.value),
                mfg_year: manufacturingItem.value.value,
                apptype: APP_TYPE_AGENCY
            }
            let response: any = params.version_id ? await InsuranceService.getCarFMV(params) : {}
            if (response?.data?.status === 200) {
                if (response?.data?.data?.price?.price > 0) {
                    let carFMVItem = getFieldItem('car_fmv')
                    if (carFMVItem) {
                        carFMVItem['value'] = (response.data.data.price.price + '')
                        carFMVItem['is_enable'] = true                     //FMV should be editable
                        carFMVItem['error_message'] = ''                     //FMV should be editable
                        setRefresh((prev) => prev + 1);
                    }
                }
            }
        }
    }

    // const resetCarFMV = () => {
    //     let carFMVItem = getFieldItem('car_fmv')
    //     if (carFMVItem) {
    //         carFMVItem['value'] = ''
    //         carFMVItem['is_enable'] = true
    //         setRefresh((prev) => prev + 1);
    //     }
    // }

    const resetChildDependecies = (fieldItem: any) => {
        let childDependencies: any = fieldItem?.child_depedencies || []
        if (fieldItem.name === 'vehicle_usage') {
            childDependencies.push('vehicle_type')
        }

        if (fieldItem.name === 'vehicle_type') {
            fetchMMV();
            childDependencies = [...childDependencies, 'make', 'model', 'version'];
        }

        //if autofill status is true, then do not reset below keys
        if(autoFillStatus) {
            childDependencies = childDependencies.filter((key: any) => !preFilledKeys.includes(key))
        }

        // eslint-disable-next-line
        childDependencies.map((key: any) => {
            const fieldData: any = props?.fields?.filter((fieldData: any) => fieldData.name === key)?.[0]
            if (fieldData) {
                fieldData['error'] = ''
                fieldData['value'] = ''
                if (fieldData.name === "addon_detail") {

                    fieldData['selected_addon'] = []
                    fieldData['original_list'] = []
                    if (props?.resetAddonList) props.resetAddonList()
                }
            }
        })

        setRefresh((prev) => prev + 1);
    }

    const fetchMMV = async (isfetch = false) => {
        let vehicleUsaseItem = getFieldItem('vehicle_usage')
        let vehicleTypeItem = getFieldItem('vehicle_type')

        if (vehicleUsaseItem?.value) {
            let vehicle_usage = (typeof vehicleUsaseItem.value === "object") ? vehicleUsaseItem.value.value : vehicleUsaseItem.value;
            let vehicle_type = (typeof vehicleTypeItem.value === "object") ? vehicleTypeItem.value.value : vehicleTypeItem.value;
            let params = { rule_engine: insurance?.createInsuranceAgency?.rule_engine, vehicle_usage, apptype: APP_TYPE_AGENCY, vehicle_type, category_id: vehicleTypeItem?.value?.category_id }
            // if ((commonState.mmvList).length === 0 || isfetch) {
            if (!(commonState?.mmvList?.[params['category_id']] && Object?.keys(commonState?.mmvList?.[params['category_id']])?.length) || isfetch) {

                if(autoFillStatus) {
                    props.setLoading(true)
                    const data = await dispatch(getMMVList(params))
                    autoFillData('make', data?.data?.make || [])
                    props.setLoading(false)
                } else {
                    dispatch(getMMVList(params))
                }
            }else{
                autoFillData('make', commonState?.mmvList?.[params['category_id']]?.make || [])
            }
        }
    }

    const getOptionList = (fieldItem: any) => {
        let vehicleTypeItem = getFieldItem('vehicle_type') 
        let vehicleCategoryId = vehicleTypeItem?.value?.category_id;

        let mmvDataRecord: any = commonState.mmvList?.[vehicleCategoryId] || []
        if (fieldItem.name === "make") {
            return mmvDataRecord?.make
        } else if (fieldItem.name === "model") {
            let makeItem = getFieldItem('make')
            if (makeItem) {
                let value = typeof makeItem?.value === 'object' ? makeItem?.value?.value : makeItem?.value;
                let modelList = mmvDataRecord?.model?.filter((modelItem: any) => modelItem.make === value || modelItem.value === 0)

                return modelList || []
            }
        } else if (fieldItem.name === 'version') {
            // let makeItem = getFieldItem('make')
            let modelItem = getFieldItem('model')
            if (modelItem) {
                let value = typeof modelItem?.value === 'object' ? modelItem?.value?.value : modelItem?.value;
                let modelList = mmvDataRecord?.version?.filter((versionItem: any) => versionItem.model == value || versionItem.value == 0)   // eslint-disable-line

                return modelList || []
            }
        } else if (fieldItem.name === 'addon_detail') {
            return [{ value: 1, label: 'Yes' }, { value: 0, label: 'No' }]
        } else if (fieldItem.name === 'per_region' || fieldItem.name === 'cur_region') {
            let tempList: any = []
            props?.stateCityList?.forEach((item: any) => {
                let param = { label: item.value, value: item.id, city_list: item.city_list }
                tempList.push(param)
            })
            return tempList
        } else if (fieldItem.name === 'per_city') {
            let regionItem = getFieldItem('per_region')
            let value = typeof regionItem?.value === 'object' ? regionItem?.value?.value : regionItem?.value;
            let cityList = props?.stateCityList?.filter((item: any) => item.id === value)?.[0]?.['city_list'];
            let tempList: any = []
            cityList?.forEach((item: any) => {
                let param = { label: item.value, value: item.id }
                tempList.push(param)
            })
            return tempList
        } else if (fieldItem.name === 'cur_city') {
            let regionItem = getFieldItem('cur_region')
            let value = typeof regionItem?.value === 'object' ? regionItem?.value?.value : regionItem?.value;
            let cityList = props?.stateCityList?.filter((item: any) => item.id === value)?.[0]?.['city_list'];
            let tempList: any = []
            cityList?.forEach((item: any) => {
                let param = { label: item.value, value: item.id }
                tempList.push(param)
            })
            return tempList
        } else if (fieldItem.name === 'vehicle_type') {
            let vehicleUsaseItem = getFieldItem('vehicle_usage')

            if (vehicleUsaseItem) {
                let value = typeof vehicleUsaseItem?.value === 'object' ? vehicleUsaseItem?.value?.value : vehicleUsaseItem?.value;
                let tempOptionList = fieldItem?.options?.filter((optionItem: any) => optionItem[vehicleUsaseItem.name] == value)   // eslint-disable-line
                return tempOptionList || []
            }
            return []
        } else {
            return fieldItem.options || []
        }
    }

    const getFieldValue = (fieldItem: any) => {
        if (fieldItem.name === "total_fmv") {
            let carFMVItem = getFieldItem('car_fmv')
            // let accPriceItem = getFieldItem('acc_price')
            let totalValue = 0
            if (carFMVItem.value)
                totalValue = parseFloat(carFMVItem.value)

            // if (accPriceItem.value)
            //     totalValue += parseFloat(accPriceItem.value)
            // Round to two decimal places
            totalValue = parseFloat(totalValue.toFixed(2))
            fieldItem["value"] = totalValue
            fieldItem["error_message"] = ""
            return totalValue
        }

        return fieldItem.value || ''
    }

    const onChangeText = (fieldItem: any, text: any) => {
        //number validation for input fields 
        if ((['per_postal_code', 'cur_postal_code'].includes(fieldItem.name))) {
            if (text.length > 4) {
                return true
            }
        }
        if (fieldItem && fieldItem.type !== 'tb') {
            if (text) {
                const regex = /^[0-9]+(\.[0-9]{0,2})?$/
                if (!regex.test(text)) {
                    return
                }
            }
        }
        if (fieldItem.label === 'Customer Name') {
            if (!text || (text && !text.trim())) {
                fieldItem['value'] = ''
            }
        }
        fieldItem['value'] = text
        fieldItem['error_message'] = ''
        if (isSameAsSelected()) {
            prefillSameAsAddress(fieldItem)
        }
        if (['make_name', 'model_name', 'version_name'].includes(fieldItem.name)) {
            setRefresh((prev) => prev + 1);
        } else {
            resetChildDependecies(fieldItem)
        }

        if (fieldItem?.name === 'phone' && fieldItem?.value?.length >= 10) {
            props.fetchCustomerData(text)
        }
    }

    const fetch_mgf_year = async (fieldItem?: any) => {
        let mgf_year = (commonState?.common?.make_year) || {}
        let makeYearField = getFieldItem('make_year')

        if (makeYearField) {
            makeYearField['options'] = (fieldItem.value.value === INS_TYPE_COMPREHENSIVE) ? mgf_year?.mgf_comprehensive : mgf_year?.mgf_ctpl

            //FOR WITHOUT RULE ENGINE SHOW ALL YEARS
            if (+props?.applicationType === WITHOUT_RULE_ENGINE) {
                makeYearField['options'] = mgf_year?.without_mfg_comprehensive
            }

            if(makeYearField?.options?.length && autoFillStatus) {
                autoFillData('make_year', makeYearField['options'])
            }
        }
    }

    const onChangeCheckbox = (fieldItem?: any) => {
        fieldItem['value'] = !fieldItem.value
        setRefresh((prev) => prev + 1);
        if (fieldItem?.name === 'per_sameas_cur') {
            setPrefillDataOnCheckboxPress()
        }
    }

    const setPrefillDataOnCheckboxPress = () => {
        props?.fields?.forEach((fieldItem: any) => {
            prefillSameAsAddress(fieldItem)
        })
    }

    const autoFillData = (key: string, data: any) => {
        let item: any = null
        switch (key) {
            case 'make':
                item = getFieldItem('make')
                break
            case 'make_year':
                item = getFieldItem('make_year')
                break
            default:
                break
        }

        if (data?.length && item?.value?.label) {
            let newValue = data.find((ele: any) => {
                if(typeof ele.label === 'string' && typeof item.value.label === 'string') {
                    return ele.label.toLowerCase() === item.value.label.toLowerCase()
                } else {
                    return ele.label === item.value.value
                }
            })
            if (newValue) {
                item['value'] = newValue
            } else {
                item['value'] = ""
            }
            setRefresh(refresh + 1)
        }
    }
    return (
        <>
            {
                props?.fields?.map((fieldItem: any, index: number) => {
                    let inputType = (['per_postal_code', 'cur_postal_code'].includes(fieldItem.name)) ? 'number' : 'text'
                    let optionsList = (fieldItem?.options?.length && fieldItem?.name !== 'vehicle_type') ? fieldItem.options : getOptionList(fieldItem)
                    let optionValue = typeof fieldItem.value === 'object' ? fieldItem.value : (fieldItem.value != 0 || fieldItem.value === 0) ? optionsList?.filter((option: any) => option.value == fieldItem.value)?.[0] : null;   // eslint-disable-line

                    const isShowField = isShowDynamicField(fieldItem)
                    switch (fieldItem.type) {
                        case FIELD_TYPE.CURRENCY:
                        case FIELD_TYPE.TELEPHONE:
                        case FIELD_TYPE.SUM:
                        case FIELD_TYPE.TEXT_BOX:
                        case FIELD_TYPE.MOBILE_FIELD:
                            return (
                                isShowField &&
                                <fieldset className={rowClass} key={index}>
                                    <div className="material">
                                        {
                                            isEnable(fieldItem) ?
                                                <input
                                                    maxLength={fieldItem.type === FIELD_TYPE.MOBILE_FIELD ? 10 : 1000}
                                                    disabled={!isEnable(fieldItem)}
                                                    type={inputType}
                                                    placeholder=" "
                                                    name={fieldItem.name}
                                                    className="form-input"
                                                    value={getFieldValue(fieldItem) || ''}
                                                    onChange={(event) => { onChangeText(fieldItem, event.target.value); }}
                                                />
                                                :
                                                <input
                                                    maxLength={fieldItem.type === FIELD_TYPE.MOBILE_FIELD ? 10 : 1000}
                                                    disabled={!isEnable(fieldItem)}
                                                    type={inputType}
                                                    placeholder=" "
                                                    name={fieldItem.name}
                                                    className="form-input"
                                                    value={getFieldValue(fieldItem) || ''}
                                                />
                                        }
                                        <label data-label={fieldItem.label + (fieldItem.is_required ? '*' : ((fieldItem?.conditional_mandatory ? '**' : '')))} className="form-label"></label>

                                        {fieldItem?.error_message ? <span className="error-txt">{fieldItem.error_message}</span> : null}
                                    </div>
                                </fieldset>
                            )
                        case FIELD_TYPE.FUTURE_DATE:
                        case FIELD_TYPE.DATE_PICKER: {
                            return (
                                isShowField &&
                                <fieldset className={"form-filed date-picker-detail " + rowClass} key={index}>
                                    <div className={"material animation-effect" + (fieldItem.value ? ' active-label' : '')}>
                                        {/* <InsuranceDate text={fieldItem.label + (fieldItem.is_required ? '*' : '')} /> */}
                                        <Calendra2
                                            onChange={(e: Date) => onValueSelect(fieldItem, e)}
                                            placeholder={fieldItem.label + (fieldItem.is_required ? '*' : '')}
                                            dateFormat="dd/MM/yyyy"
                                            selected={fieldItem?.value}
                                            fieldId={fieldItem.id}
                                            fields={props?.fields}
                                            fieldLabel={fieldItem.label}
                                            disabled={!fieldItem.is_enable}
                                            apptype={APP_TYPE_AGENCY}
                                            fieldname={fieldItem.name} />
                                        {fieldItem?.error_message ? <span className="error-txt">{fieldItem.error_message}</span> : null}
                                    </div>
                                </fieldset>
                            )
                        }
                        case FIELD_TYPE.CHECK_BOX:
                            return (
                                // <div className="form-filed col-md-11  m-xs-b " key={index}>
                                    <div className="material col-md-4 t-center">
                                        <div className="custom-control custom-checkbox">
                                            <input checked={fieldItem.value} id="Individual" type="checkbox" className="custom-control-input " name="Buyer Type" onChange={(e) => {
                                                onChangeCheckbox(fieldItem)
                                            }} disabled={!fieldItem.is_enable} />
                                            <label className="custom-control-label">Same as Permanent Address </label>
                                        </div>

                                    </div>
                            )
                        case FIELD_TYPE.TEXT_AREA:
                            return (
                                <div className="form-filed col-md-11  m-xs-b m-xl-t create-agency-additional-note" key={index} style={{padding:"0"}}>

                                    <fieldset className="form-filed col-md-4">
                                        <div className={"material"}>
                                            <textarea id="description" placeholder=" " name="notes" className="form-input" value={fieldItem.value} onChange={(e) => {
                                                onValueSelect(fieldItem, e.target.value)
                                            }} />
                                            <label data-label={fieldItem.label} className="form-label"></label>
                                        </div>
                                    </fieldset>
                                </div>
                            )

                        // case FIELD_TYPE.MULTI_DROP_DOWN:
                        //     return (
                        //         <fieldset className="multiselect-dropDown col-md-4">
                        //             <div className={((fieldItem.name === 'other_doc' && fieldItem.value.length > 0) || typedValue) ? "active-label material animation-effect" : "material animation-effect "}>
                        //                 <MultiSelect
                        //                     id="other_docs"
                        //                     key={"other_docs"}
                        //                     options={optionsList || []}
                        //                     value={optionValue ?? []}
                        //                     onChange={(e: any) => onValueSelect(fieldItem, e)}
                        //                     isSelectAll={true}
                        //                     placeholder={""}
                        //                     setTypedValue={setTypedValue}
                        //                 />
                        //                 <label data-label={fieldItem.label} className="form-label"></label>

                        //             </div>
                        //         </fieldset>
                        //     )

                        default: {
                            return (

                                isShowField &&
                                <React.Fragment key={index} >
                                    {
                                        isEnable(fieldItem) ?
                                            <ReactSelect
                                                options={optionsList}
                                                value={optionValue}
                                                handleChange={(e: any, fieldKey: string) => onValueSelect(fieldItem, e)}
                                                placeholder={fieldItem.label + ((fieldItem.is_required) ? '*' : '')}
                                                fieldKey=""
                                                fieldItemName={fieldItem.name}
                                                isDisabled={!isEnable(fieldItem)}
                                                errorField={fieldItem?.error_message}
                                                fieldSetClassName={"form-filed " + rowClass}
                                            />
                                            :
                                            <ReactSelect
                                                options={optionsList}
                                                value={optionValue}
                                                placeholder={fieldItem.label + ((fieldItem.is_required) ? '*' : '')}
                                                fieldKey=""
                                                isDisabled={!isEnable(fieldItem)}
                                                errorField={fieldItem?.error_message}
                                                fieldSetClassName={"form-filed " + rowClass}
                                            />
                                    }

                                </React.Fragment>
                            )
                        }
                    }
                })
            }
        </>
    )
}