import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputField from "@src/common/InputField"
import ReactSelect from '@common/ReactSelect';
import { getlocalityList } from '@src/store/slices/common';
import { APP_TYPE_AGENCY } from '@src/config/constant';



export default function ResidentialInformation(props: any): ReturnType<React.FC> {
    const commonState = useSelector((state: any) => state.common);
    const insuranceState = useSelector((state: any) => state.insurance)
    const { application_type_id } = insuranceState?.leadDetail || {}

    let [perCity, setPerCity] = useState<any>([]);
    let [curCity, setCurCity] = useState<any>([]);
    let [perLocality, setPerLocality] = useState<any>([]);
    let [curLocality, setCurLocality] = useState<any>([]);
    let [curFieldDisable, setCurFieldDisable] = useState<boolean>(false)
    let [isLocalityFetched, setIsLocalityFetched] = useState(false)

    const dispatch: any = useDispatch();

    useEffect(() => {
        if ((commonState.locality).length === 0 && !isLocalityFetched) {
            setIsLocalityFetched(true)
            let cityArr = [+props?.residentialDetails?.per_city]
            if (!cityArr.includes(+props?.residentialDetails?.cur_city)) cityArr.push(+props?.residentialDetails?.cur_city)
            props.setLoading(true)

            //FILTER OUT VALID CITIES
            let validCityArr = cityArr?.filter(c=> +c)

            if(validCityArr?.length){
                dispatch(getlocalityList({ city: cityArr })).finally(() => props.setLoading(false))
            }else{
                props.setLoading(false)
            }
        }
        getloadData();

        return (() => {
            setIsLocalityFetched(false)
        })
        // eslint-disable-next-line
    }, [commonState]);

    // const getlocalityData = (data: Array<object>) => {
    //     if (props?.residentialDetails?.per_city && data.length > 0) {

    //         let per_locality = data.filter((el: any) => +el.city_id === +props?.residentialDetails?.per_city);
    //         setPerLocality([...per_locality]);
    //     }
    //     if (props?.residentialDetails?.cur_city && data.length > 0) {

    //         let cur_locality = data.filter((el: any) => +el.city_id === +props?.residentialDetails?.cur_city);
    //         setCurLocality([...cur_locality]);
    //     }

    // }

    const getloadData = () => {

        if (props?.residentialDetails?.per_region) {
            let per_city = commonState.city.filter((el: any) => el.state_id === Number(props.residentialDetails.per_region));
            setPerCity([...per_city]);
        }
        if (props?.residentialDetails?.cur_region) {
            let cur_city = commonState.city.filter((el: any) => +el.state_id === +props?.residentialDetails?.cur_region);
            setCurCity([...cur_city]);
        }
        if (props?.residentialDetails?.cur_city && commonState?.locality.length > 0) {
            let cur_locality = commonState?.locality.filter((el: any) => +el.city_id === +props?.residentialDetails?.cur_city);
            setCurLocality([...cur_locality]);
        }
        if (props?.residentialDetails?.per_city && commonState?.locality.length > 0) {
            let per_locality = commonState?.locality.filter((el: any) => +el.city_id === +props?.residentialDetails?.per_city);
            setPerLocality([...per_locality]);
        }
        if (+props?.residentialDetails?.per_sameas_cur === 2) {
            setCurFieldDisable(true);
        }
    }

    const changeHandler = async (value: any, key: string) => {
        let formFields: any = props.residentialDetails;
        if (key === 'per_full_address') {
            let curvalue = {};
            if (formFields["per_sameas_cur"] === 2) {
                curvalue = { cur_full_address: value.target.value }
            }
            formFields = { ...formFields, [key]: value.target.value, ...curvalue }
        }
        else if (key === 'per_house_no') {
            let curvalue = {};
            if (formFields["per_sameas_cur"] === 2) {
                curvalue = { cur_house_no: value.target.value }
            }
            formFields = { ...formFields, [key]: value.target.value, ...curvalue }
        } else if (key === 'per_street') {
            let curvalue = {};
            if (formFields["per_sameas_cur"] === 2) {
                curvalue = { cur_street: value.target.value }
            }
            formFields = { ...formFields, [key]: value.target.value, ...curvalue }
        } else if (key === "per_region") {
            let per_city = commonState.city.filter((el: any) => +el.state_id === +value.value);
            setPerCity([...per_city]);
            let curvalue = {};
            if (formFields["per_sameas_cur"] === 2) {
                curvalue = { cur_region: value.value, cur_city: null, cur_barangay: null }
            }
            formFields = { ...formFields, [key]: value.value, ...curvalue, per_city: null, per_barangay: null }

        } else if (key === "cur_region") {
            let cur_city = commonState.city.filter((el: any) => +el.state_id === +value.value);
            setCurCity([...cur_city]);

            formFields = { ...formFields, [key]: value.value, cur_city: null, cur_barangay: null }

        } else if (key === "per_city") {
            let locality = commonState.locality.filter((el: any) => +el.city_id === +value.value)
            if (!(locality && locality.length)) {
                props.setLoading(true)
                let cityArray = [+value.value]
                if (formFields.cur_city) cityArray.push(+formFields.cur_city)
                locality = await dispatch(getlocalityList({ city: cityArray }))
                locality = locality?.data?.filter((e: any) => e.city_id === +value.value) || []
                props.setLoading(false)
            }

            setPerLocality([...locality]);
            let curvalue: any = {};
            if (formFields["per_sameas_cur"] === 2) {
                curvalue = { cur_city: value.value, cur_barangay: null }
                setCurLocality([...locality])
            }
            formFields = { ...formFields, [key]: value.value, ...curvalue, per_barangay: null }

        } else if (key === "cur_city") {
            let locality = commonState.locality.filter((el: any) => +el.city_id === +value.value);
            if (!(locality && locality.length)) {
                props.setLoading(true)
                let cityArray = [+value.value]
                if (formFields.per_city) cityArray.push(+formFields.per_city)
                locality = await dispatch(getlocalityList({ city: cityArray }))
                locality = locality?.data?.filter((e: any) => e.city_id === +value.value) || []
                props.setLoading(false)
            }

            setCurLocality([...locality]);
            formFields = { ...formFields, [key]: value.value, cur_barangay: null }

        } else if (key === "per_barangay") {
            let pin_code = { per_postal_code: value.pin_code };
            let curvalue = {};
            if (formFields["per_sameas_cur"] === 2) {
                curvalue = { cur_barangay: value.value, cur_postal_code: value.pin_code }
            }
            formFields = { ...formFields, [key]: value.value, ...pin_code, ...curvalue }

        } else if (key === "cur_barangay") {
            let pin_code = { cur_postal_code: value.pin_code };
            formFields = { ...formFields, [key]: value.value, ...pin_code }

        } else if (key === "per_sameas_cur") {
            let per_sameas_cur = 1;
            let fieldDisable = false;
            let curvalue: any = { cur_house_no: '', cur_street: '', cur_region: '', cur_city: '', cur_barangay: '', cur_postal_code: '' };
            let isAgency = application_type_id === APP_TYPE_AGENCY

            if (isAgency) {
                curvalue['cur_full_address'] = ''
            }

            if (value.target.checked) {
                fieldDisable = true;
                per_sameas_cur = 2;
                curvalue = {
                    cur_full_address: props.residentialDetails.cur_full_address,
                    cur_house_no: props.residentialDetails.per_house_no,
                    cur_street: props.residentialDetails.per_street,
                    cur_region: props.residentialDetails.per_region,
                    cur_city: props.residentialDetails.per_city,
                    cur_barangay: props.residentialDetails.per_barangay,
                    cur_postal_code: props.residentialDetails.per_postal_code
                };

                if (isAgency) {
                    curvalue['cur_full_address'] = props.residentialDetails.per_full_address
                }
                setCurCity(perCity);
                setCurLocality(perLocality);
            } else {
                setCurCity([]);
                setCurLocality([]);
            }
            setCurFieldDisable(fieldDisable);

            formFields = { ...formFields, [key]: per_sameas_cur, ...curvalue }

        } else {

            formFields = { ...formFields, [key]: value.target.value }
        }
        props.changeHandler(formFields, "residential-details");
    }

    let { errors, residentialDetails } = props;
    return (
        <React.Fragment>
            <div className="">
                <div className='customer-heading-txt'>
                    <h2>Residential Details</h2>
                    <span className='current-address-txt'>Permanent Address</span>
                </div>
                <div className="row">
                    {
                        application_type_id === APP_TYPE_AGENCY
                            ?
                            <fieldset className="col-md-6">
                                <div className="material" title={residentialDetails?.per_full_address}>
                                    <InputField
                                        inputProps={{
                                            id: "per_full_address",
                                            name: "per_full_address",
                                            className: "form-input",
                                            type: "text",
                                            value: residentialDetails.per_full_address || '',
                                            maxLength: '',
                                            disabled: props.disable,
                                            placeholder: " "
                                        }}
                                        onChange={(e: any) => changeHandler(e, 'per_full_address')}
                                    />
                                    <label data-label="Permanent Full Address*" className="form-label"></label>
                                    {
                                        errors.per_full_address ?
                                            <span className="error-txt">{errors.per_full_address}</span>
                                            : ''
                                    }
                                </div>
                            </fieldset>
                            : null
                    }
                    <fieldset className="col-md-6">
                        <div className="material" title={residentialDetails?.per_house_no}>
                            <InputField
                                inputProps={{
                                    id: "per_house_no",
                                    name: "per_house_no",
                                    className: "form-input",
                                    type: "text",
                                    value: residentialDetails.per_house_no || '',
                                    maxLength: '',
                                    disabled: props.disable,
                                    placeholder: " "
                                }}
                                onChange={(e: any) => changeHandler(e, 'per_house_no')}
                            />
                            <label data-label="Permanent House Number*" className="form-label"></label>
                            {
                                errors.per_house_no ?
                                    <span className="error-txt">{errors.per_house_no}</span>
                                    : ''
                            }
                        </div>
                    </fieldset>

                    <fieldset className="col-md-6">
                        <div className="material" title={residentialDetails?.per_street}>
                            <InputField
                                inputProps={{
                                    id: "per_street",
                                    name: "per_street",
                                    className: "form-input",
                                    type: "text",
                                    value: residentialDetails.per_street || '',
                                    maxLength: '',
                                    disabled: props.disable,
                                    placeholder: " "

                                }}
                                onChange={(e: any) => changeHandler(e, 'per_street')}
                            />
                            <label data-label="Permanent Street*" className="form-label"></label>
                            {
                                errors.per_street ?
                                    <span className="error-txt">{errors.per_street}</span>
                                    : ''
                            }
                        </div>
                    </fieldset>

                    <ReactSelect
                        options={commonState?.state}
                        value={commonState?.state.find((v: any) => Number(residentialDetails.per_region) === +v.value) || null}
                        placeholder={"Permanent Region *"}
                        isDisabled={props.disable}
                        handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)}
                        fieldKey="per_region"
                        fieldSetClassName="col-md-6"
                        errorField={errors.per_region} />


                    <ReactSelect
                        options={perCity}
                        value={perCity.find((v: any) => Number(residentialDetails.per_city) === Number(v.value)) || null}
                        placeholder={"Permanent City *"}
                        isDisabled={props.disable}
                        handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)}
                        fieldKey="per_city"
                        fieldSetClassName="col-md-6"
                        errorField={errors.per_city} />


                    <ReactSelect
                        options={perLocality} value={perLocality.find((v: any) => +residentialDetails.per_barangay === +v.value) || null}
                        placeholder={"Permanent Barangay *"}
                        isDisabled={props.disable}
                        handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)} fieldKey="per_barangay"
                        fieldSetClassName="col-md-6"
                        errorField={errors.per_barangay} />


                    <fieldset className="col-md-6">
                        <div className="material" title={residentialDetails?.per_postal_code}>
                            <InputField
                                inputProps={{
                                    id: "per_postal_code",
                                    name: "per_postal_code",
                                    className: "form-input",
                                    type: "text",
                                    value: (residentialDetails.per_postal_code) ? residentialDetails.per_postal_code : '',
                                    maxLength: '',
                                    disabled: true,
                                    placeholder: " "

                                }}
                                onChange={(e: any) => changeHandler(e, 'per_postal_code')}
                            />
                            <label data-label="Permanent Postal Code *" className="form-label"></label>
                            {
                                errors.per_postal_code ?
                                    <span className="error-txt">{errors.per_postal_code}</span>
                                    : ''
                            }
                        </div>
                    </fieldset>
                    {/* </div> */}

                </div>

                <div className="row">
                    <div className="col-md-12">
                        <span className='mailing-address-txt'>Mailing Address</span>
                    </div>

                    <fieldset className="form-filed col-md-12  m-b-1">
                        <div className="material">
                            <div className="custom-control custom-checkbox">
                                <input
                                    id="per_sameas_cur"
                                    name="per_sameas_cur"
                                    type="checkbox"
                                    onChange={(e: any) => changeHandler(e, 'per_sameas_cur')}
                                    checked={(residentialDetails.per_sameas_cur === 2) ? true : false} value={residentialDetails.per_sameas_cur} className="custom-control-input"
                                    disabled={props.disable}
                                    placeholder=" "

                                />

                                <label className="custom-control-label">Same as Permanent Address </label>
                            </div>
                        </div>
                    </fieldset>

                    {
                        application_type_id === APP_TYPE_AGENCY
                            ?
                            <fieldset className="col-md-6">
                                <div className="material" title={residentialDetails?.cur_full_address}>
                                    <InputField
                                        inputProps={{
                                            id: "cur_full_address",
                                            name: "cur_full_address",
                                            className: "form-input",
                                            type: "text",
                                            value: residentialDetails.cur_full_address || '',
                                            maxLength: '',
                                            disabled: props.disable || curFieldDisable,
                                            placeholder: " "
                                        }}
                                        onChange={(e: any) => changeHandler(e, 'cur_full_address')}
                                    />
                                    <label data-label="Current Full Address*" className="form-label"></label>
                                    {
                                        errors.cur_full_address ?
                                            <span className="error-txt">{errors.cur_full_address}</span>
                                            : ''
                                    }
                                </div>
                            </fieldset>
                            : null
                    }
                    <fieldset className="col-md-6">
                        <div className="material" title={residentialDetails?.cur_house_no}>
                            <InputField
                                inputProps={{
                                    id: "cur_house_no",
                                    name: "cur_house_no",
                                    className: "form-input",
                                    type: "text",
                                    value: residentialDetails.cur_house_no || '',
                                    maxLength: '',
                                    disabled: props.disable || curFieldDisable,
                                    placeholder: " "

                                }}
                                onChange={(e: any) => changeHandler(e, 'cur_house_no')}
                            />
                            <label data-label="Current House Number*" className="form-label"></label>
                            {
                                errors.cur_house_no ?
                                    <span className="error-txt">{errors.cur_house_no}</span>
                                    : ''
                            }
                        </div>
                    </fieldset>

                    <fieldset className="col-md-6">
                        <div className="material" title={residentialDetails?.cur_street}>
                            <InputField
                                inputProps={{
                                    id: "cur_street",
                                    name: "cur_street",
                                    className: "form-input",
                                    type: "text",
                                    value: residentialDetails.cur_street || '',
                                    maxLength: '',
                                    disabled: props.disable || curFieldDisable,
                                    placeholder: " "

                                }}
                                onChange={(e: any) => changeHandler(e, 'cur_street')}
                            />
                            <label data-label="Current Street*" className="form-label"></label>
                            {
                                errors.cur_street ?
                                    <span className="error-txt">{errors.cur_street}</span>
                                    : ''
                            }
                        </div>
                    </fieldset>

                    <ReactSelect
                        options={commonState?.state}
                        value={commonState?.state.find((v: any) => Number(residentialDetails.cur_region) === +v.value) || null}
                        placeholder={"Current Region *"}
                        isDisabled={props.disable || curFieldDisable}
                        handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)}
                        fieldKey="cur_region"
                        fieldSetClassName="col-md-6"
                        errorField={errors.cur_region} />

                    <ReactSelect
                        options={curCity}
                        value={curCity.find((v: any) => Number(residentialDetails.cur_city) === Number(v.value)) || null}
                        placeholder={"Current City *"}
                        handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)}
                        fieldKey="cur_city"
                        fieldSetClassName="col-md-6"
                        errorField={errors.cur_city}
                        isDisabled={props.disable || curFieldDisable} />

                    <ReactSelect
                        options={curLocality}
                        value={curLocality.find((v: any) => Number(residentialDetails.cur_barangay) === Number(v.value)) || null}
                        placeholder={"Current Barangay *"}
                        handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)}
                        fieldKey="cur_barangay"
                        fieldSetClassName="col-md-6"
                        errorField={errors.cur_barangay}
                        isDisabled={props.disable || curFieldDisable} />

                    <fieldset className="col-md-6">
                        <div className="material" title={residentialDetails?.cur_postal_code}>
                            <InputField
                                inputProps={{
                                    id: "cur_postal_code",
                                    name: "cur_postal_code",
                                    className: "form-input",
                                    type: "text",
                                    value: (residentialDetails.cur_postal_code) ? residentialDetails.cur_postal_code : '',
                                    maxLength: '',
                                    disabled: true,
                                    placeholder: " "

                                }}
                                onChange={undefined}
                            />
                            <label data-label="Current Postal Code *" className="form-label"></label>
                            {
                                errors.cur_postal_code ?
                                    <span className="error-txt">{errors.cur_postal_code}</span>
                                    : ''
                            }
                        </div>
                    </fieldset>
                </div>
            </div>
        </React.Fragment >
    );
}

