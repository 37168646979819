import { updateMasterRates, updateReduxMasterRates } from '../../../store/slices/common';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import * as HELPER from "../../../config/helper";
import { ROLE_FEATURES,AGENCY_VAL, APP_TYPE_AGENCY } from '@src/config/constant';
import ReactSelect from '@common/ReactSelect';

export default function BaseIssueRate(props: any): ReturnType<React.FC> {
    let { appTypeList, userId, handleLoader, handleModalClose,partnerList} = props
    let [inputField, setInputFields] = useState<any>({ apptype: null, value: null, partner: null , base_rate_min: null, base_rate_max: null})
    let [errors, setErrors] = useState<any>()
    let [showPartner, setShowPartner] = useState(false);

    let dispatch = useDispatch()
    let commonState = useSelector((state: any) => state.common)

    let isRateEditable = HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['update_module'], accessType: 'edit' })


    /**
    * function to handle change in inputs
    * @param name 
    * @param value 
    */
    const handleChange = (name: string, value: any) => {
        if (name === 'apptype') {
            if (value) {
                autoFillBaseRate({ value: value.value })
                if(value?.value === AGENCY_VAL){
                    setShowPartner(true);
                    const initialPartner = partnerList[0];
                    // Prefill rates for the first partner
                    const partnerRates = commonState?.rates?.base_issue_rate_agency?.find((rate:any) => rate.partner_id === +initialPartner.value && rate.app_type_id === AGENCY_VAL);
                    setInputFields((prev:any) => ({
                        ...prev,
                        base_rate_min: partnerRates?.min_rate,
                        base_rate_max: partnerRates?.max_rate,
                        partner: initialPartner
                    }));  

                }else{
                    setShowPartner(false);
                }
            }
        }
        if(name === 'partner'){
            if(value){
                setInputFields((prev: any) => { 
                    return {
                        ...prev,
                        partner: value.value
                    }
                })

                const partnerRates = commonState.rates.base_issue_rate_agency.find((rate:any) => rate.partner_id === value.value && rate.app_type_id === AGENCY_VAL);
                setInputFields((prev:any) => ({
                  ...prev,
                  base_rate_min: partnerRates?.min_rate,
                  base_rate_max: partnerRates?.max_rate
                }));
            }
        }

        if (['base_rate_min', 'base_rate_max'].includes(name) && value) {
            //regex to accept numbers from 0-100 non-negative and upto 2 decimal places
            let isValid = (/^(?<whole>100|\d{1,2})(\.\d{0,2})?$/).test(value)
            if (!isValid) return
        }
        setInputFields((prev: any) => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    /**
     * checks if all inputs are valid or not
     * @returns boolean
     */
    const checkValidation = (): boolean => {
        let error = false
        setErrors({})

        if (inputField && Object.keys(inputField).length) {
            let updatedInputField = { ...inputField }

            if (inputField.apptype?.value) {
                updatedInputField['base_rate_max'] = inputField.base_rate_max || null
                updatedInputField['base_rate_min'] = inputField.base_rate_min || null
            }

            for (let key in updatedInputField) {

                let inputMax: any = updatedInputField.base_rate_max || 0
                let inputMin: any = updatedInputField.base_rate_min || 0

                if (inputField.apptype?.value) {
                    if (key === 'base_rate_min' || key === 'base_rate_max') {
                        if (inputMin && inputMax) {
                            if (inputMin > inputMax) {
                                error = true
                                setErrors((prev: any) => {
                                    return {
                                        ...prev,
                                        base_rate_min: `Min Rate should be less than Max Rate`
                                    }
                                })
                            }

                            if (inputMax < inputMin) {
                                error = true
                                setErrors((prev: any) => {
                                    return {
                                        ...prev,
                                        base_rate_max: `Max Rate should be greater than Min Rate`
                                    }
                                })
                            }
                        }

                    }

                }


            }
        }

        return error
    }

    /**
     * executes on Save button click
     */
    const handleSubmit = async () => {
        let isErrorExist = checkValidation()

        if (!isErrorExist) {
            handleLoader(true)
            let apiParams: any = {
                base_issue_rate: {
                    application_type_id: inputField?.apptype?.value
                },
                user_id: userId
            }

            if (inputField.apptype?.value) {
                let min = inputField.base_rate_min ? +inputField.base_rate_min : 0
                let max = inputField.base_rate_max ? +inputField.base_rate_max : 0
                let partner_id = inputField?.partner?.value
                apiParams.base_issue_rate = {
                    ...apiParams.base_issue_rate,
                    min,
                    max,
                    partner_id
                }
            }
            let updateAonRate: any = await updateMasterRates(apiParams)

            if (updateAonRate && updateAonRate.status && updateAonRate.status === 200) {
                toast.success("Value Updated Successfully")
                handleModalClose()

                //update redux state
                let updateRateInRedux = JSON.parse(JSON.stringify(commonState))
                let { rates } = updateRateInRedux
                let { master, base_issue_rate_agency} = rates

                if (master && base_issue_rate_agency) {
                    let updateBaseRate: any = JSON.parse(JSON.stringify(master))
                    let updateBaseIssueRateAgency: any = JSON.parse(JSON.stringify(base_issue_rate_agency))

                    const updatedFieldName = inputField.apptype?.value === 1 ? 'ucf' : inputField.apptype?.value === 2 ? 'standalone' : 'renewal'

                    for (let key of updateBaseRate) {

                        if (inputField.apptype?.value === 1 && key.slug === 'app_with_ucf') {
                            key['value'] = inputField.value
                        }
                        if (inputField.apptype?.value === 2 && key.slug === 'standalone') {
                            key['value'] = inputField.value
                        }
                        if (inputField.apptype?.value && key.slug === `basic_rate_min_${updatedFieldName}`) {
                            key['value'] = inputField.base_rate_min
                        }
                        if (inputField.apptype?.value && key.slug === `basic_rate_max_${updatedFieldName}`) {
                            key['value'] = inputField.base_rate_max
                        }

                    }

                    for (let key of updateBaseIssueRateAgency) {              
                        if (inputField.apptype?.value === AGENCY_VAL && key.partner_id === inputField?.partner?.value &&  key.app_type_id === inputField.apptype?.value ) {
                            key.max_rate = +inputField.base_rate_max;
                            key.min_rate = +inputField.base_rate_min;
                        }
                    }
                    rates.master = updateBaseRate
                    rates.base_issue_rate_agency = updateBaseIssueRateAgency
                    dispatch(updateReduxMasterRates(rates))
                }
            }

            handleLoader(false)
        }
    }

    //for auto filling previous rate
    const autoFillBaseRate = useCallback((params: any) => {
        let { rates } = commonState
        if (rates && rates.master) {
            let masterData = rates.master
            let updateObj: any = {}
            // let element = masterData.find((e: any) => e.slug === 'app_with_ucf')

            if (params && params.value) {
                const slugMap: { [key: string]: string } = {
                    'standalone': 'value',
                    'basic_rate_min_standalone': 'base_rate_min',
                    'basic_rate_max_standalone': 'base_rate_max',
                    'app_with_ucf': 'value',
                    'basic_rate_min_ucf': 'base_rate_min',
                    'basic_rate_max_ucf': 'base_rate_max',
                    'basic_rate_min_renewal': 'base_rate_min',
                    'basic_rate_max_renewal': 'base_rate_max'
                };

                const prefix = params.value === 2 ? 'standalone' : params.value === 1 ? 'ucf' : 'renewal';
                for (let key in masterData) {
                    let element: any = masterData[key];
                    if (element) {
                        const mappedKey = slugMap[element.slug];
                        if (mappedKey && element.slug.includes(prefix)) {
                            updateObj[mappedKey] = element.value;
                        }
                    }
                }
            }

            if (Object.keys(updateObj).length) {
                setInputFields((prev: any) => {
                    return {
                        ...prev,
                        ...updateObj
                    }
                })
            }
        }
    }, [commonState])


    useEffect(() => {
        if (appTypeList) {
            let preSelectedApptype = (appTypeList && appTypeList[0]) || {}
            let inputObj = { apptype: preSelectedApptype, value: null }

            setInputFields(inputObj)
            //for auto filling previous rate
            autoFillBaseRate({ value: 1 })
        }
        // eslint-disable-next-line
    }, [appTypeList, autoFillBaseRate])


    // Remove the first element from appTypeList
    // if (appTypeList && appTypeList.length > 0) {
    //     [, ...appTypeList] = appTypeList;
    // }

    // fetch value for 2nd dropdown option
    // useEffect(() => {
    // let params = {value:2, label: "Standalone"};
    // let key:any = 'apptype';
    //     handleChange(key,params);
    // // eslint-disable-next-line
    // }, [])
    
    return (
        <div className="calculator-form">

            <div className="row base-issue-rate-popup">

                <ReactSelect options={appTypeList?.filter((el:any)=> el.value !== APP_TYPE_AGENCY)}
                    value={inputField && inputField.apptype}
                    handleChange={(e: any, fieldKey: string) => handleChange(fieldKey, e)}
                    placeholder="Application Type*" fieldKey="apptype" key="apptype"
                    errorField={errors?.apptype ? "This field is required" : ""}
                    fieldSetClassName={"col-md-6 react-select-max-value"}
                />

                {
                    showPartner &&
                    <ReactSelect options={partnerList}
                        value={partnerList?.filter((v:any) => v.value === inputField?.partner?.value) || {}}
                        handleChange={(e: any, fieldKey: string) => handleChange(fieldKey, e)}
                        placeholder="Insurance Partner Name" fieldKey="partner" key="partner"
                        fieldSetClassName={"col-md-6 react-select-max-value"}
                    />

                }

               

                {inputField.apptype ?
                    <>
                        <fieldset className="form-filed col-md-6">
                            <div className="material">
                                <input id="" type="text" placeholder=" " name="base_min" className="form-input" onChange={(e) => handleChange('base_rate_min', e.target.value)} value={(inputField.base_rate_min || inputField.base_rate_min === 0 ? inputField.base_rate_min : '')} disabled={!isRateEditable} />
                                <label data-label="Base Rate Min(%)*" className="form-label"></label>
                                {
                                    errors && errors.base_rate_min
                                        ?
                                        <span className="error-txt">{errors.base_rate_min}</span>
                                        :
                                        ''
                                }
                            </div>
                        </fieldset>

                        <fieldset className="form-filed col-md-6">
                            <div className="material">
                                <input id="" type="text" placeholder=" " name="base_max" className="form-input" onChange={(e) => handleChange('base_rate_max', e.target.value)} value={(inputField.base_rate_max || inputField.base_rate_max === 0 ? inputField.base_rate_max : '')} disabled={!isRateEditable} />
                                <label data-label="Base Rate Max(%)*" className="form-label"></label>
                                {
                                    errors && errors.base_rate_max
                                        ?
                                        <span className="error-txt">{errors.base_rate_max}</span>
                                        :
                                        ''
                                }
                            </div>
                        </fieldset>
                    </>
                    : ''
                }

                {isRateEditable ?
                    <div className="col-md-12">
                        <button className="btn-primary" onClick={() => handleSubmit()}>Save</button>
                    </div>
                    : ''}

            </div>
        </div>
    )
}