import React, { useEffect, useState } from 'react';
import MoreFilterOption from '@src/component/insurance/list/MoreFilterOption';
import MultiSelect from '@src/common/MultiSelect';
import { useDispatch } from 'react-redux';
import { getMasterConfig } from '@src/store/slices/common';
import { useSelector } from 'react-redux';
import { leadListFilters } from '@src/store/slices/insurance';
// import ReactSelect from '@common/ReactSelect';

const InsuranceListTopFilter = (props: any) => {
    const dispatch: any = useDispatch();

    const [selectedFilters, setSelectedFilters] = useState<any>({});
    const [masterConfig, setMasterConfig] = useState<any>({});
    const [subStatusList, setSubStatusList] = useState<any>([]);
    const insuranceState = useSelector((state: any) => state.insurance)
    const commonState = useSelector((state: any) => state.common)

    useEffect(() => {
        const getMasterConfigApi = () => {
            dispatch(getMasterConfig()).then((res: any) => {
                if (res && res.status === 200) {
                    if (res.data) {
                        setMasterConfig(res.data);
                    }
                }
            })
        }

        if(!commonState?.common || !Object.keys(commonState?.common)?.length) {
            getMasterConfigApi();
        } else {
            setMasterConfig(commonState.common)
        }
    // eslint-disable-next-line    
    }, [dispatch, commonState.common])


    useEffect(() => {
        if (Object.keys(insuranceState.insuranceListFilters).length && Object.keys(masterConfig).length) {
            if (insuranceState.insuranceListFilters.status_id_arr) {
                let fetchSubStatus = masterConfig['sub_status_list']?.filter((s: any) => insuranceState.insuranceListFilters.status_id_arr.includes(+s.status_id));
                setSubStatusList(fetchSubStatus)
            }
            setSelectedFilters({ ...insuranceState.insuranceListFilters });
        }

    }, [insuranceState.insuranceListFilters, masterConfig])


    /**
     * CHANGE HANDLER
     * @param selectedOption 
     * @param key 
     */
    const handleChange = (selectedOption: any, key: string) => {
        let updatedFilters = selectedFilters;

        if (key === "searchKeyword") {
            // setSelectedFilters({ ...selectedFilters, searchKeyword: selectedOption.target.value })
            updatedFilters = { ...updatedFilters, searchKeyword: selectedOption.target.value || undefined };
        } else if (key === "apptype") {
            // setSelectedFilters({ ...selectedFilters, [key]: selectedOption['value'] });
            updatedFilters = { ...updatedFilters, [key]: selectedOption?.map((option: any) => option.value) || [] };

        } else if (key === "raised_soa"){
            updatedFilters[key] = selectedOption ? selectedOption.value : null;
        } else {
            let selectedOptionsArray = selectedOption.map((v: any) => v.value);

            // setSelectedFilters({ ...selectedFilters, [key]: selectedOptionsArray });
            updatedFilters = { ...updatedFilters, [key]: selectedOptionsArray };


            if (key === "status_id_arr") {
                let fetchSubStatus = masterConfig['sub_status_list']?.filter((s: any) => selectedOptionsArray.includes(+s.status_id));
                //RESET SUBSTATUS IF STATUS UPDATED
                let updatedSubStatusFilter = updatedFilters?.sub_status_id_arr ? fetchSubStatus?.filter((el:any)=> updatedFilters?.sub_status_id_arr.includes(el.value)) : [];
                updatedSubStatusFilter = updatedSubStatusFilter.map((el:any)=> +el.value);
                
                updatedFilters['sub_status_id_arr'] = updatedSubStatusFilter?.length ? updatedSubStatusFilter : undefined;
                
                setSubStatusList(fetchSubStatus)
            }
        }
        setSelectedFilters(updatedFilters);

        // dispatch(leadListFilters(updatedFilters));

    };

    /**
     * UPDATE OVERALL FILTER
     * @param filterParams 
     */
    const updateFiltersData = (filterParams: any) => {
        let updatedFilters = {};
        if (filterParams && filterParams['type'] && filterParams['type'] === "reset") {
            // setSelectedFilters({});
            updatedFilters = {activeTab: selectedFilters?.activeTab || "all", page_number: 1,};
            setSubStatusList([]);

        } else {
            updatedFilters = { ...selectedFilters, ...filterParams };
        }
        setSelectedFilters(updatedFilters);

        dispatch(leadListFilters(updatedFilters));
        
        props.resetSort();

    }

    return (
        <React.Fragment>
            <div className="lead-list-filter">
                <div className="filter-filed-form">
                    <fieldset className="search-bx">
                        <div className="material" title='Search By Insurance ID, Customer Number, Plate Number'>
                            <input id="" type="text" placeholder="Search By Insurance ID, Cust." name="RoleName" className="form-input" onChange={(e) => handleChange(e, 'searchKeyword')} value={selectedFilters?.searchKeyword || ""} />
                            <i className="ic-search"></i>
                        </div>
                    </fieldset>
                    <fieldset className="multiselect-dropDown">
                        <div className={(selectedFilters['partner_id'] && selectedFilters['partner_id'].length) ? "active-label material animation-effect" : "material animation-effect "}>
                            <MultiSelect
                                key={"dropdown"}
                                options={masterConfig['partner_list'] || []}
                                value={masterConfig['partner_list']?.filter((v: any) => selectedFilters['partner_id']?.includes(v.value))}
                                onChange={(e: any) => handleChange(e, 'partner_id')}
                                isSelectAll={true}
                                placeholder={""}
                            />
                            <label data-label={"Insurance Partner"} className="form-label"></label>

                        </div>
                    </fieldset>

                    <fieldset className="multiselect-dropDown">
                        <div className={(selectedFilters['status_id_arr'] && masterConfig['status_list']?.filter((v: any) => selectedFilters['status_id_arr']?.includes(v.value))?.length) ? "active-label material animation-effect" : "material animation-effect "}>
                            <MultiSelect
                                options={masterConfig['status_list'] || []}
                                isSelectAll={true}
                                value={masterConfig['status_list']?.filter((v: any) => selectedFilters['status_id_arr']?.includes(v.value))}
                                onChange={(e: any) => handleChange(e, 'status_id_arr')}
                                placeholder={""}
                                isClearable={false}
                            />
                            <label data-label={"Status"} className="form-label"></label>

                        </div>
                    </fieldset>
                    <fieldset className="multiselect-dropDown">
                        <div className={(selectedFilters['sub_status_id_arr'] && subStatusList?.filter((v: any) => selectedFilters['sub_status_id_arr']?.includes(v.value))?.length ) ? "active-label material animation-effect" : "material animation-effect "}>
                            <MultiSelect
                                options={subStatusList || []}
                                isSelectAll={true}
                                value={subStatusList?.filter((v: any) => selectedFilters['sub_status_id_arr']?.includes(v.value))}
                                onChange={(e: any) => handleChange(e, 'sub_status_id_arr')}
                                placeholder={""}
                                isClearable={false}
                            />
                            <label data-label={"Sub Status"} className="form-label"></label>

                        </div>
                    </fieldset>

                    <fieldset className="multiselect-dropDown">
                        <div className={masterConfig['apptype']?.filter((v: any) => selectedFilters['apptype']?.includes(v.value))?.length ? "active-label material animation-effect" : "material animation-effect "}>
                            <MultiSelect
                                options={masterConfig['apptype'] || []}
                                isSelectAll={true}
                                value={masterConfig['apptype']?.filter((v: any) => selectedFilters['apptype']?.includes(v.value))}
                                onChange={(e: any) => handleChange(e, 'apptype')}
                                placeholder={""}
                                isClearable={false}
                            />
                            <label data-label={"Application Type"} className="form-label"></label>

                        </div>
                    </fieldset>

                    {
                        selectedFilters['apptype']?.includes(4) &&
                        <fieldset className="multiselect-dropDown">
                            <div className={masterConfig['raised_soa']?.some((v: any) => selectedFilters['raised_soa'] === v.value) ? "active-label material animation-effect" : "material animation-effect "}>
                                <MultiSelect
                                    options={masterConfig['raised_soa'] || []}
                                    isSelectAll={false}
                                    value={masterConfig['raised_soa']?.filter((v: any) => selectedFilters['raised_soa'] === v.value)}
                                    onChange={(e: any) => handleChange(e, 'raised_soa')}
                                    placeholder={""}
                                    isClearable={false}
                                />
                                <label data-label={"Raised SOA"} className="form-label"></label>

                            </div>
                        </fieldset>
                    }

                    

                    {/* <ReactSelect options={masterConfig['apptype'] || []} value={masterConfig['apptype']?.filter((v: any) => [selectedFilters['apptype']]?.includes(v.value))} handleChange={(e: any, fieldKey: string) => handleChange(e, fieldKey)} placeholder="Application Type" fieldKey="apptype" /> */}


                </div>
                <div className="more-filter">
                    <MoreFilterOption masterConfig={masterConfig} selectedFilters={selectedFilters} updateSelectedFilters={(moreFilterData: any) => updateFiltersData(moreFilterData)} submitHandler={(filterParams: any) => props.submitHandler(filterParams)} 
                    loader={(params: any) => props.loader(params)}/>
                </div>
            </div>
        </React.Fragment>
    );
};

export default InsuranceListTopFilter;
