import { Plans } from './Plans';
import { Addons } from './Addons';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { Tab, Nav } from 'react-bootstrap';
import InsurerDetails from './InsurerDetails';
import { useSectionList } from '@src/hooks/useSectionList';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { getAddonList } from '@src/component/insurance/create/FunctionList';
import { useNavigate } from 'react-router';
import { saveInsuranceAgency } from '@src/store/slices/insurance';
import { INS_TYPE_CTPL } from '@src/config/constant';

export const Insurer = forwardRef((props: any, ref) => {
    let { setParentPage, parentPage } = props
    const { fetchSectionItem } = useSectionList({})
    const [page, setPage] = useState<number>(1)
    const childRef = useRef<any>({})
    const [activeKey, setActiveKey] = useState<string>('1')
    const insurerFieldItem = fetchSectionItem('insurance_quote')
    const insurerDetails = fetchSectionItem('insurance_quote', 'quote_data')
    const subTabs = insurerDetails?.sub_tabs
    // const [versionSeat, setVersionSeat] = useState(0)
    const [selectedPartner, setSelectedPartner] = useState<any>({});

    const navigate = useNavigate()
    const dispatch: any = useDispatch()

    const insurance = useSelector((state: any) => state.insurance)
    const commonState = useSelector((state: any) => state.common)

    let { partner_id, rule_engine, insurance_type, partner_slug } = insurance?.createInsuranceAgency || {}

    const vehicleDetailsItem = fetchSectionItem('vehicle_details', 'vehicle_info')

    useImperativeHandle(ref, () => ({
        getData: () => vehicleDetailsItem.fields || []
    }))

    const handleNext = async () => {
        switch (activeKey) {
            // case '1': {
            //     if (!partner_id) {
            //         toast.error("Please select partner")
            //         break
            //     }
            //     if (page < 2) setPage(2)
            //     setActiveKey('2')
            //     break
            // }
            case '1': {
                const addonList = childRef.current.getData()
                if (!addonList?.length) {
                    toast.error("Please select addon to proceed")
                    break
                }
                const addons = getAddonList({ original_list: addonList }, null, rule_engine)
                const { checkValue, message } = await isValidAddon(addons)
                if (!checkValue) {
                    for (let msg of message) {
                        toast.error(msg);
                    }
                    break
                }
                dispatch(saveInsuranceAgency({
                    ...insurance.createInsuranceAgency,
                    addon_detail: addons
                }))
                if (page < 2) setPage(2)
                setActiveKey('2')
                break
            }
            case '2': {
                const isValid = validatePlan()
                if (!isValid) break
                const quoteData = childRef.current.getData()
                saveQuoteDetails(quoteData)
                navigate('/create-insurance-policy-agency?step=personal_details')
                setParentPage(4)
                break
            }
            default: {
                break
            }
        }
    }

    const validatePlan = () => {
        const quoteData = childRef.current.getData()
        //if validation flag is set to false then give error
        if (insurance_type !== INS_TYPE_CTPL && (!quoteData.base_issue_rate || +quoteData.base_issue_rate === 0)) {
            toast.error('Please enter valid base issue rate')
            return false
        }
        else if (!quoteData?.validation) {
            toast.error('Please select valid plan')
            return false
        }
        return true
    }

    const handleNavClick = (event: any) => {
        if (page >= event) {
            setActiveKey("" + event)
        }
    }

    const saveQuoteDetails = (params: any) => {
        let { total_premium, base_issue_rate, discount, net_premium } = params
        dispatch(saveInsuranceAgency({
            ...insurance.createInsuranceAgency, total_premium: +total_premium,
            base_issue_rate, discount,
            net_premium
        }))
    }

    const isValidAddon = async (addonList: any): Promise<{ checkValue: boolean, message: Array<any> }> => {
        let flag = true;
        let message: any = []
        if (addonList.length > 0) {
            for (let data of addonList) {
                if (data.id === 1) {
                    if (!data["coverage_amount"]) {
                        message.push("Please select coverge amount for Bodily Injured");
                        flag = false
                    }
                    if (!data["premium_amount"]) {
                        message.push("Please Enter Premium amount for Bodily Injured");
                        flag = false
                    }
                } else if (data.id === 2) {
                    if (!data["coverage_amount"]) {
                        message.push("Please select coverge amount for Property Damage");
                        flag = false
                    }
                    if (!data["premium_amount"]) {
                        message.push("Please Enter Premium amount for Property Damage");
                        flag = false
                    }
                } else if (data.id === 3) {
                    if (!data["coverage_amount"]) {
                        message.push("Please select coverge amount for Personal Accident");
                        flag = false
                    }
                    // if (rule_engine === 2 && !data["premium_amount"]) {
                    //     message.push("Please Enter Premium amount for Personal Accident");
                    //     flag = false
                    // }
                    if(!data["premium_amount"] && ['oona', 'milestone'].includes(partner_slug)){
                        let premiumAmtValidation = true;
                        if(data['milestone_coverage_amount_limit'] || data['oona_coverage_amount_limit']){
                            if(data['milestone_coverage_amount_limit'] === data['coverage_amount'] || data['oona_coverage_amount_limit'] === data['coverage_amount']){
                                premiumAmtValidation = false;
                            }
                        }

                        if(premiumAmtValidation || rule_engine === 2){

                            message.push("Please Enter Premium amount for Personal Accident");
                            flag = false
                        }
                    }

                    if (!data["seat_capacity"]) {
                        message.push("Please select seat capacity for Personal Accident");
                        flag = false
                    }
                }
            }

        }
        return new Promise((resolve) => resolve({ checkValue: flag, message }))
    }

    useEffect(() => {
        //if select insurer details is filled, then set cur page to 4
        if (parentPage > 3) {
            setPage(4)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(()=>{
        if(partner_id){
            let getSelectedPartner = commonState?.common?.partner_list?.find((el:any)=> el.value === partner_id) 
            setSelectedPartner(getSelectedPartner)
        }
    },[partner_id])

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <h2>{insurerFieldItem?.blocks?.[0]?.title}</h2>
                </div>

                <div className="col-md-4" key={selectedPartner?.slug}>
                    <div id={`partner-${selectedPartner.id}`} className={`insurer-list-item insurar-list-agency active`}>
                        <div className="agency-partner-logo">
                            <img src={selectedPartner?.logo} className="" alt={`partner-logo`} />
                        </div>
                        <div className="agency-partner-name">
                            {selectedPartner?.label}
                        </div>
                        <i className="ic-arrow_forward_ios"></i>
                    </div>
                </div >
            </div>
            <Tab.Container id="left-tabs-example" defaultActiveKey="1" activeKey={activeKey}>
                <Nav variant="pills" className={"flex-column tab-line"}>
                    <div className="tab-list m-md-b m-xl-t">
                        {
                            subTabs?.length
                                ?
                                subTabs.map((tab: any, idx: number) => {
                                    return (
                                        <Nav.Item key={tab.key + (idx + 1)}>
                                            <Nav.Link eventKey={idx + 1 + ""} onClick={() => { handleNavClick(idx + 1) }}>
                                                {
                                                    tab.title
                                                }
                                            </Nav.Link>
                                        </Nav.Item>
                                    )
                                })
                                : undefined
                        }
                    </div>

                </Nav>
                <Tab.Content>
                    {/* <Tab.Pane eventKey="1">
                        {
                            activeKey === '1' && <InsurerDetails fields={insurerDetails} setLoading={props.setLoading} setPage={props.setParentPage} />
                        }
                    </Tab.Pane> */}
                    <Tab.Pane eventKey="1">
                        {
                            activeKey === '1' && <Addons ref={childRef} setLoading={props.setLoading} page={page} setPage={props.setParentPage}/>
                        }

                    </Tab.Pane>
                    <Tab.Pane eventKey="2">
                        {
                            activeKey === '2' && <Plans ref={childRef} setLoading={props.setLoading} setPage={setPage} setActiveKey={setActiveKey} agency_id={props.agency_id}/>
                        }
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>

            <div className="col-md-12 f-right t-right p-lg-t">
                <button className="btn-primary " onClick={() => { handleNext() }}>Save & Continue</button>
            </div>
        </>

    )
})