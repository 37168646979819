
import { useEffect, useState } from "react";
import DocumentGallery from '../DocumentGallery';
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { ExpectedInstallmentDate, InsuranceDate } from "../../../../common/DatePicker";
import { updateLeadDetailsInStore, fetchLeadData, setInboundPaymentFromResponse } from '../../../../store/slices/insurance';
import { saveAgencyPaymentDetails } from '../../../../store/slices/agency';
import BankDetails from "./BankDetails";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import FullPageLoader from '@src/common/FullPageLoader';
import * as HELPER from "../../../../config/helper";
import { PAYMENT_TYPE_INBOUND, PAYMENT_TYPE_OUTBOUND, DOCUMENT_CATEGORY, ROLE_FEATURES, PAYMENT_TERM_INSTALLMENT, DOCUMENTS_CATEGORY_INBOUND, API_TYPES, INBOUND_PAYMENT_FROM_OTO_INSURANCE, INBOUND_PAYMENT_FROM_INSURANCE_PARTNER, REFERRAL_FEE_PAYOUT, INSURANCE_PAYMENT_FROM_INSURANCE_PARTNER_TO_OTO, INSURANCE_PAYMENT_FROM_AGENCY_TO_INSURANCE_PARTNER, INSURANCE_PAYMENT_FROM_OTO_TO_INSURANCE_PARTNER, INSURANCE_PAYMENT_FROM_AGENCY_TO_OTO_INSURANCE, REFERRAL_FEE_PAYOUT_OUTBOUND, SOURCE_WEB, REFERRAL_FEE_PAYOUT_WHT_EXCLUSIVE_RATE } from "@src/config/constant";
import LeadRemarksButton from '../../common/LeadRemarksButton';
import CurrencyInputField from "@src/common/CurrencyInputField";
import ReactSelect from '@common/ReactSelect';
import { times } from "lodash";
import React from "react";
import InputField from "@common/InputField";
import Documents from "./Documents";
import { getBulkPaymentList } from '../../../../store/slices/bulkPayment';
import ConfirmPopup from "@common/ConfirmPopUp";

interface AgencyInboundPaymentProps {
    formType: number;
    leadHashId: string;
    payment_type: number
}


const AgencyInboundOutBoundPaymentProps: React.FC<AgencyInboundPaymentProps> = (props) => {
    const [errors, setErrors] = useState<any>({});
    const [loading, setLoading] = useState(false);
    // eslint-disable-next-line
    const [leadDetails, setLeadDetails] = useState<any>({});

    const [submitClicked, setSubmitClicked] = useState<boolean>(false);

    const dispatch: any = useDispatch();
    const insuranceState = useSelector((state: any) => state.insurance);
    const navigate = useNavigate();

    let [amountLabel, setAmountLabel] = useState("");
    let [headerType, setHeaderType] = useState("");
    let [headerName, setHeaderName] = useState("");
    // eslint-disable-next-line
    const [bankDetails, setBankDetails] = useState<any>({});
    const [filesCount, setFilesCount] = useState<number>(0);
    const [inputFields, setInputFields] = useState<any>({ payment_method_id: null, payment_term_id: null, payment_tenure_id: null })
    // eslint-disable-next-line
    const [enableFutureDates, setEnableFutureDate] = useState(false);
    // eslint-disable-next-line
    const [buttonText, setButtonText] = useState("Verify & Next")
    const [saveBtnAccessibility, setSaveBtnAccessibility] = useState<boolean>(true)
    const [paymenyReferenceFields, setPaymenyReferenceFields] = useState<any>([]);

    const [documentDocId, setDocumentDocId] = useState<any>([]);
    const [documentFormType, setDocumentFormType] = useState("");

    const [minDateForAgency, setMinDateForAgency] = useState(null);
    const commonState = useSelector((state: any) => state.common);

    const [isDataAutoPrefilledRefNo, setIsDataAutoPrefilledRefNo] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [showSwitchPayee, setShowSwitchPayee] = useState<boolean>(true);


    // let paymentTermOptions = commonState?.common?.payment_term
    const displayInstallmentForm = ([INSURANCE_PAYMENT_FROM_AGENCY_TO_OTO_INSURANCE, INSURANCE_PAYMENT_FROM_AGENCY_TO_INSURANCE_PARTNER].includes(props.formType))


    const checkFutureDateApplication = () => {
        let enableFutureDates = commonState?.common?.payment_method?.find((v: any) => v.label === "Post Dated Cheque")
        enableFutureDates = (enableFutureDates && (inputFields.payment_method_id || insuranceState?.leadDetail?.payment_method_id) === enableFutureDates.value && (inputFields.payment_term_id || insuranceState?.leadDetail?.payment_term_id) === PAYMENT_TERM_INSTALLMENT) ? true : false

        return setEnableFutureDate(enableFutureDates)
    }

    const handleChange = async (key: any, event: any, idx: number) => {
        if ((key?.includes('payment_reference_no') || key?.includes('amount_date')) && idx >= 0) {
            setPaymenyReferenceFields((prev: any) => {
                if (key.includes('payment_reference_no')) {
                    prev[idx]['payment_reference_no'] = event.target.value;
                }
                if (key.includes('amount_date')) {

                    prev[idx]['amount_date'] = event
                }
                return prev
            })
        }
        errors[key] = '';
        setErrors({ ...errors });
    };


    const isFormValid = () => {
        let isValid = true;
        let errors: any = {}

        setErrors({})

        if (displayInstallmentForm) {
            if (!inputFields.payment_method_id) {
                errors['payment_method_id'] = "Please select payment method"
                isValid = false;
            }
            if (!inputFields.payment_term_id) {
                errors['payment_term_id'] = "Please select payment term"
                isValid = false;
            }
        }

        let getInboundPaymentDocs: any = [];

        if ([INSURANCE_PAYMENT_FROM_AGENCY_TO_OTO_INSURANCE, INSURANCE_PAYMENT_FROM_AGENCY_TO_INSURANCE_PARTNER].includes(props.formType)) {

            let docId = (props.formType === INSURANCE_PAYMENT_FROM_AGENCY_TO_OTO_INSURANCE) ? 'payment_from_agency_to_oto_insurance' : 'payment_from_agency_to_insurance_partner'
            getInboundPaymentDocs = insuranceState?.leadDetail?.images.filter((docs: any) => docs.doc_type_id === DOCUMENT_CATEGORY['postDisbursal']['childs'][docId]);

            if (!inputFields.payment_method_id) {
                errors['payment_method_id'] = "Please select payment method"
                isValid = false;
            }
            if (!inputFields.payment_term_id) {
                errors['payment_term_id'] = "Please select payment term"
                isValid = false;
            }
            if (!inputFields.payment_way) {
                errors['payment_way'] = "Please select payment way"
                isValid = false;
            }
            if (!inputFields.premium_amount) {
                errors['premium_amount'] = "Please enter premium amount"
                isValid = false;
            }
            if (!inputFields.credit_term) {
                errors['credit_term'] = "Please select credit term"
                isValid = false;
            }

            let premiumAmount:any = HELPER.removeCurrencyFormatting(inputFields['premium_amount']);

            if (premiumAmount > insuranceState?.leadDetail?.car_fmv) {
                errors['premium_amount'] = "Total Premium Amount should not be greater than FMV"
                isValid = false
            }

            let instalmentDocError = 0
            let refNumUnique: any = {}
            if (paymenyReferenceFields.length) {
                for (let [idx, referenceFields] of paymenyReferenceFields.entries()) {

                    if (idx === 0) {

                        if (!referenceFields["amount_date"]) {

                            errors[`amount_date_${idx}`] = inputFields.payment_tenure_id > 0 ? `Please enter premium date ${idx + 1}` : `Please enter premium date`;
                            isValid = false;

                        }

                        if (!referenceFields["payment_reference_no"]) {

                            errors[`payment_reference_no_${idx}`] = inputFields.payment_tenure_id > 0 ? `Please enter payment reference ${idx + 1}` : `Please enter payment reference`;
                            isValid = false;
                        }

                        if (!inputFields[`${idx + 1}_installment_file_count`] && inputFields?.payment_tenure_id) {
                            instalmentDocError = 1
                        }
                    }

                    if ((!referenceFields["amount_date"] && referenceFields["payment_reference_no"]) || (!referenceFields["amount_date"] && !referenceFields["payment_reference_no"] && !inputFields.payment_tenure_id)) {
                        errors[`amount_date_${idx}`] = inputFields.payment_tenure_id > 0 ? `Please enter premium date ${idx + 1}` : `Please enter premium date`;
                        isValid = false;
                    }
                    if ((!referenceFields["payment_reference_no"] && referenceFields["amount_date"]) || (!referenceFields["amount_date"] && !referenceFields["payment_reference_no"] && !inputFields.payment_tenure_id)) {
                        errors[`payment_reference_no_${idx}`] = inputFields.payment_tenure_id > 0 ? `Please enter payment reference ${idx + 1}` : `Please enter payment reference`;
                        isValid = false;
                    }
                    //CHECK INSTALMENT DOC ERROR

                    if (((referenceFields["payment_reference_no"] || referenceFields["amount_date"])) && !inputFields[`${idx + 1}_installment_file_count`] && inputFields?.payment_tenure_id) {
                        instalmentDocError = 1
                    }

                    if (referenceFields?.payment_reference_no?.trim()) {
                        refNumUnique[referenceFields?.payment_reference_no] = !refNumUnique[referenceFields?.payment_reference_no] ? 1 : refNumUnique[referenceFields?.payment_reference_no] + 1;
                        if (refNumUnique[referenceFields?.payment_reference_no] > 1) {
                            errors[`payment_reference_no_${idx}`] = `Reference no. should be unique`
                            isValid = true

                        }
                    }

                    //HANDLE IF DOC SELECTED BUT NOT DATA
                    if ((!referenceFields["payment_reference_no"] || !referenceFields["amount_date"]) && inputFields[`${idx + 1}_installment_file_count`] && inputFields?.payment_tenure_id) {
                        if (!referenceFields["amount_date"]) {
                            errors[`amount_date_${idx}`] = inputFields.payment_tenure_id > 0 ? `Please enter premium date ${idx + 1}` : `Please enter premium date`;

                        }

                        if ((!referenceFields["payment_reference_no"]) || (!referenceFields["amount_date"] && !referenceFields["payment_reference_no"] && !inputFields.payment_tenure_id)) {
                            errors[`payment_reference_no_${idx}`] = inputFields.payment_tenure_id > 0 ? `Please enter payment reference ${idx + 1}` : `Please enter payment reference`;
                            isValid = false;
                        }
                    }
                }
            }
            // if (instalmentDocError && !uploadedFiles?.length) {
            if (instalmentDocError) {
                isValid = false;
                toast.error("Please select payment documents")
            }
        } else if ([INSURANCE_PAYMENT_FROM_OTO_TO_INSURANCE_PARTNER, INSURANCE_PAYMENT_FROM_INSURANCE_PARTNER_TO_OTO].includes(props.formType)) {
            let docId = (props.formType === INSURANCE_PAYMENT_FROM_OTO_TO_INSURANCE_PARTNER) ? 'payment_from_oto_to_insurance_partner' : 'payment_from_insurance_partner_to_oto'

            getInboundPaymentDocs = insuranceState?.leadDetail?.images.filter((docs: any) => docs.doc_type_id === DOCUMENT_CATEGORY['postDisbursal']['childs'][docId]);

            if (!inputFields.commission_share || (+inputFields?.commission_share > 45 || +inputFields?.commission_share < 5)) {
                errors['commission_share'] = "Commission share should be 5%-45%"
                isValid = false;
            }

        } else if (props.payment_type === REFERRAL_FEE_PAYOUT_OUTBOUND) {
            if (!inputFields.referral_fee_scheme) {
                errors['referral_fee_scheme'] = "Please select referral fee scheme"
                isValid = false;
            }
            // if (!inputFields.referral_fee_share || (+inputFields?.referral_fee_share > 40 || +inputFields?.referral_fee_share < 0)) {
            //     errors['referral_fee_share'] = "Referral fee share should be 0%-40%"
            //     isValid = false;
            // }
            // if ((+inputFields?.referral_fee_share >= +insuranceState.leadDetail?.insurance_agency_commission_share)) {
            //     errors['referral_fee_share'] = "Referral fee share should be less than commission share"
            //     isValid = false;
            // }
            if (!inputFields.referral_payout_date_time) {
                errors['referral_payout_date_time'] = "Please select premium referral fee payout date & time"
                isValid = false;
            }
            // if (!inputFields.referral_fee_reference_no) {
            //     errors['referral_fee_reference_no'] = "Please enter referral fee reference number"
            //     isValid = false;
            // }
        }

        if (!getInboundPaymentDocs.length && !filesCount) {

            if ([INSURANCE_PAYMENT_FROM_AGENCY_TO_OTO_INSURANCE, INSURANCE_PAYMENT_FROM_AGENCY_TO_INSURANCE_PARTNER].includes(props.formType) && !inputFields.payment_tenure_id) {
                isValid = false;
                toast.error("Please select payment documents")
            } 
            // else if ([REFERRAL_FEE_PAYOUT].includes(props.formType)) { //FO REFERRAL PAYOUT
            //     isValid = false;
            //     toast.error("Please select payment documents")
            // } 
            else if ([INSURANCE_PAYMENT_FROM_OTO_TO_INSURANCE_PARTNER, INSURANCE_PAYMENT_FROM_INSURANCE_PARTNER_TO_OTO].includes(props.formType)) {
                isValid = false;
                toast.error("Please select payment documents")
            }
        }
        setErrors({ ...errors });
        return isValid;
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setSubmitClicked(false);
        // let postData = await getFormData();
        let postData: any = {};
        if ([INSURANCE_PAYMENT_FROM_AGENCY_TO_OTO_INSURANCE, INSURANCE_PAYMENT_FROM_AGENCY_TO_INSURANCE_PARTNER].includes(props.formType)) {
            postData = {
                'payment_from': props.formType,
                'lead_id': props.leadHashId,
                'payment_type': props.payment_type,
                'payment_method': inputFields.payment_method_id,
                'payment_term': inputFields.payment_term_id,
                'payment_tenure': inputFields.payment_tenure_id || 0,
                'credit_term': inputFields.credit_term,
                'payment_way': inputFields.payment_way,
                'payment_reference_no': inputFields.payment_reference_no,
                'payment_reference_arr': paymenyReferenceFields,
                'amount': inputFields.premium_amount ? HELPER.removeCurrencyFormatting(inputFields['premium_amount']) : 0
            }
        } else if ([INSURANCE_PAYMENT_FROM_OTO_TO_INSURANCE_PARTNER, INSURANCE_PAYMENT_FROM_INSURANCE_PARTNER_TO_OTO].includes(props.formType)) {
            postData = {
                'payment_from': props.formType,
                'lead_id': props.leadHashId,
                'payment_type': props.payment_type,
                'commission_share': inputFields.commission_share,
                'commission_earned_by_oto': inputFields.commission_earned_by_oto,
                'insurance_payment_released_amount': ([INSURANCE_PAYMENT_FROM_OTO_TO_INSURANCE_PARTNER].includes(props.formType)) ? inputFields.insurance_payment_released_amount : undefined
            }
        } else {
            postData = {
                'referral_fee_scheme': inputFields?.referral_fee_scheme,
                // 'referral_fee_share': inputFields?.referral_fee_share,
                'lead_id': props.leadHashId,
                'payment_type': props.payment_type,
                'payment_from': props.formType,
                'referral_fee_payout_wht_inclusive': HELPER.removeCurrencyFormatting(inputFields?.referral_fee_payout_wht_inclusive),
                'referral_fee_payout_wht_exclusive': inputFields?.referral_fee_payout_wht_exclusive,
                'referral_payout_date_time': inputFields?.referral_payout_date_time,
                'bank_name': insuranceState.leadDetail.outbound_agency_referral_bank_name,
                'bank_account_no': insuranceState.leadDetail.outbound_agency_referral_bank_account_no,
                'benificiary_name': insuranceState.leadDetail.outbound_agency_referral_beneficiary_name,
                'branch_name': insuranceState.leadDetail.outbound_agency_referral_branch_name,
                'referral_fee_reference_no': inputFields?.referral_fee_reference_no
            }

            // if(+postData?.['referral_fee_share'] is integer) {

            // }

        }

        //CHECK IF PAYEE HAS BEEN SWITCHED
        // if(!showSwitchPayee) {
        // if(insuranceState.leadDetail.inbound_payment_from === 4 && props.payment_type === 5 && (!insuranceState.leadDetail.insurance_agency_payment_id && insuranceState.leadDetail.bulk_payment_source && insuranceState.leadDetail.bulk_payment_source !== SOURCE_WEB) &&  [INSURANCE_PAYMENT_FROM_AGENCY_TO_OTO_INSURANCE, INSURANCE_PAYMENT_FROM_AGENCY_TO_INSURANCE_PARTNER].includes(props.formType)){


        //     let paymentType = (props.payment_type === INBOUND_PAYMENT_FROM_OTO_INSURANCE ? INBOUND_PAYMENT_FROM_INSURANCE_PARTNER : INBOUND_PAYMENT_FROM_OTO_INSURANCE);
        //     let paymentForm = (props.formType === INSURANCE_PAYMENT_FROM_AGENCY_TO_OTO_INSURANCE ? INSURANCE_PAYMENT_FROM_AGENCY_TO_INSURANCE_PARTNER : INSURANCE_PAYMENT_FROM_AGENCY_TO_OTO_INSURANCE);

        //     postData['payment_type'] = paymentType;
        //     postData['payment_from'] = paymentForm;

        //     // if(paymentType )
        // }
        
        if (isFormValid()) {
            // return false;
            setLoading(true);
            dispatch(saveAgencyPaymentDetails({ ...postData })).then((res: any) => {

                if (res && res.status === 200) {
                    toast.success(res.message);

                    if (res.data && Object.keys(res.data).length) {
                        setLeadDetails({ ...insuranceState.leadDetail, ...res.data });

                        let updatedState = { ...insuranceState.leadDetail, ...res.data }
                        dispatch(updateLeadDetailsInStore(updatedState));
                        
                        setSubmitClicked(true);

                        //FOR OUTBOUND SCREEN NAVIGATION HANDLING
                        setLoading(false);

                        
                    } else {
                        setLoading(false);

                    }


                } else {
                    setLoading(false);

                }
                setErrors({});
            }).catch((err: Error) => {
                setLoading(false);
            })
        }
    }

    // Redirect to the next page after successful update
    const nextPageRedirect = () => {

        if (+props.formType === INSURANCE_PAYMENT_FROM_AGENCY_TO_OTO_INSURANCE) {

            navigate(`/details/payment-from-oto-to-insurance-partner/${props.leadHashId}`);
        } else if (+props.formType === INSURANCE_PAYMENT_FROM_AGENCY_TO_INSURANCE_PARTNER) {

            navigate(`/details/payment-from-insurance-partner-to-oto/${props.leadHashId}`);
        } else {

            navigate(`/details/referral-fee-payout/${props.leadHashId}`);

        }

    }
    useEffect(() => {
        if (insuranceState.leadDetail && props.payment_type !== PAYMENT_TYPE_OUTBOUND) {
            let { payment_method_id, payment_term_id, payment_tenure_id, credit_term, payment_way, expected_payment_way } = insuranceState?.leadDetail

            let updatedInputFields: any = {
                payment_method_id,
                payment_tenure_id,
                payment_term_id,
                payment_tenure_label: (commonState?.common?.payment_tenure?.find((v: any) => payment_tenure_id === v.value))?.label,
                credit_term,
                payment_way: payment_way || expected_payment_way
            }
            let tenureLabel = inputFields.payment_tenure_label ? inputFields.payment_tenure_label : (insuranceState?.leadDetail?.payment_tenure_id ? insuranceState?.leadDetail?.pay_tenure : "")
            let tenureLableNumber = tenureLabel ? tenureLabel.split(" ")[0] : 0

            if ([INSURANCE_PAYMENT_FROM_AGENCY_TO_OTO_INSURANCE, INSURANCE_PAYMENT_FROM_AGENCY_TO_INSURANCE_PARTNER].includes(props.formType)) {

                if((!insuranceState.leadDetail.insurance_agency_payment_id && insuranceState.leadDetail.bulk_payment_source && insuranceState.leadDetail.bulk_payment_source !== SOURCE_WEB) &&  [INSURANCE_PAYMENT_FROM_AGENCY_TO_OTO_INSURANCE, INSURANCE_PAYMENT_FROM_AGENCY_TO_INSURANCE_PARTNER].includes(props.formType) && !showSwitchPayee){

                    setShowSwitchPayee(true)
                }
                inputFields["insurance_agency_payment_id"] = insuranceState.leadDetail.insurance_agency_payment_id;


                inputFields["premium_amount"] = insuranceState.leadDetail.insurance_agency_payment_premium_amount || insuranceState.leadDetail.insurance_agency_payment_expected_premium_amount || insuranceState.leadDetail.premium_amount || insuranceState.leadDetail.inbound_amount;
                // data["payment"] = insuranceState.leadDetail.pay_from_cust_to_partner_broker_payment_from;

                let paymentRefArray = insuranceState?.leadDetail?.payment_reference_arr ? JSON.parse(JSON.stringify(insuranceState?.leadDetail?.payment_reference_arr)) : [];
                if (!paymentRefArray?.length) {
                    // for(let tenure of tenureLabel)
                    if (tenureLabel) {
                        times((tenureLabel).split(" ")[0], (idx: number) => {

                            paymentRefArray = [
                                ...paymentRefArray,
                                {
                                    amount_date: (!idx && insuranceState?.leadDetail?.premium_amount_date) ? new Date(insuranceState?.leadDetail?.premium_amount_date) : "",
                                    payment_reference_no: (!idx && insuranceState.leadDetail?.payment_reference_no) ? insuranceState.leadDetail?.payment_reference_no : ""
                                }
                            ]

                        });
                    } else {
                        paymentRefArray = [{ amount_date: insuranceState?.leadDetail?.premium_amount_date ? new Date(insuranceState?.leadDetail?.premium_amount_date) : "", payment_reference_no: insuranceState.leadDetail?.payment_reference_no }]

                    }
                }

                let paymentRefFields: any = [];
                //IF COUNT NOT MATCHED THEN CREATE MISSING ENTRIES
                if (paymentRefArray.length !== +(tenureLableNumber)) {
                    times(tenureLableNumber - paymentRefArray.length, (idx: number) => {

                        paymentRefArray.push({ amount_date: "", payment_reference_no: "" })
                    })
                }
                for (let payRef of paymentRefArray) {
                    paymentRefFields.push({ payment_id: payRef?.payment_id, amount_date: payRef.amount_date ? new Date(payRef.amount_date) : "", payment_reference_no: payRef.payment_reference_no })

                }


                

                console.log(insuranceState.leadDetail.inbound_payment_from, props)
                if(insuranceState.leadDetail.inbound_payment_from === 4 && props.payment_type === 5 && (!insuranceState.leadDetail.insurance_agency_payment_id && insuranceState.leadDetail.bulk_payment_source && insuranceState.leadDetail.bulk_payment_source !== SOURCE_WEB) &&  [INSURANCE_PAYMENT_FROM_AGENCY_TO_OTO_INSURANCE, INSURANCE_PAYMENT_FROM_AGENCY_TO_INSURANCE_PARTNER].includes(props.formType)){
                    inputFields['premium_amount'] = "";
                    updatedInputFields = {
                        ...updatedInputFields,
                        payment_method_id:"",
                        payment_tenure_id:"",
                        payment_term_id:"",
                        payment_tenure_label: "",//(commonState?.common?.payment_tenure?.find((v: any) => payment_tenure_id === v.value))?.label,
                        credit_term:"",
                        payment_way: payment_way || expected_payment_way
                    }
                    setShowSwitchPayee(false)
                }else{
                    if (paymentRefFields?.length) {
                        setPaymenyReferenceFields(paymentRefFields)
                    }
                }
            }

            if ([INSURANCE_PAYMENT_FROM_OTO_TO_INSURANCE_PARTNER, INSURANCE_PAYMENT_FROM_INSURANCE_PARTNER_TO_OTO].includes(props.formType)) {

                inputFields["insurance_agency_commission_id"] = insuranceState.leadDetail.insurance_agency_commission_id;
                inputFields["commission_share"] = insuranceState.leadDetail.insurance_agency_commission_share;
                inputFields["insurance_payment_released_amount"] = insuranceState.leadDetail.insurance_agency_insurance_payment_released_amount;
                inputFields["commission_earned_by_oto"] = insuranceState.leadDetail.insurance_agency_commission_earned_by_oto;

                                        
                
            }
            if (props.formType === REFERRAL_FEE_PAYOUT) {
                
                inputFields["referral_fee_scheme"] = insuranceState.leadDetail.earned_referral_fee_scheme || insuranceState.leadDetail.referral_fee_scheme;
                // inputFields["referral_fee_share"] = insuranceState.leadDetail.referral_fee_share;
                inputFields["referral_fee_payout_wht_inclusive"] = insuranceState.leadDetail.referral_fee_payout_wht_inclusive || insuranceState.leadDetail.earned_referral_fee_payout;

                let referralFeePayoutWhtExclusive: any = (inputFields["referral_fee_payout_wht_inclusive"] * REFERRAL_FEE_PAYOUT_WHT_EXCLUSIVE_RATE).toFixed(2);
                inputFields["referral_fee_payout_wht_exclusive"] = insuranceState.leadDetail.referral_fee_payout_wht_exclusive || referralFeePayoutWhtExclusive;
                inputFields["referral_fee_payout_id"] = insuranceState.leadDetail.referral_fee_payout_id;
                inputFields["referral_payout_date_time"] = insuranceState.leadDetail.referral_payout_date_time ? new Date(insuranceState.leadDetail.referral_payout_date_time) : '';
                inputFields["tin_id_no"] = insuranceState.leadDetail.outbound_agency_tin_id_no || '';
                inputFields["referral_fee_reference_no"] = insuranceState.leadDetail.referral_fee_reference_no || '';



            }
            // setFormData({ ...data });
            setInputFields({ ...inputFields, ...updatedInputFields })
            setErrors({});

            // updateInputField()
        }

        let headerName = "";
        // eslint-disable-next-line react-hooks/exhaustive-deps
        amountLabel = props.payment_type === PAYMENT_TYPE_INBOUND ? "Premium Amount Received" : "Premium Amount Paid";
        // headerType = props.payment_type === REFERRAL_FEE_PAYOUT ? "Outbound Payment" : "Inbound Payment"; // eslint-disable-line react-hooks/exhaustive-deps
        let access = null;

        if (props.payment_type === INBOUND_PAYMENT_FROM_OTO_INSURANCE) {
            if (props.formType === INSURANCE_PAYMENT_FROM_AGENCY_TO_OTO_INSURANCE) {
                headerName = "Payment From Agency to OTO Insurance";
                amountLabel = "Premium Amount Received";
                access = ROLE_FEATURES['payment_from_customer']
            } else if (props.formType === INSURANCE_PAYMENT_FROM_OTO_TO_INSURANCE_PARTNER) {
                headerName = "Payment From OTO to Insurance Partner";
                amountLabel = "Premium Amount Received";
                access = ROLE_FEATURES['payment_from_sales_team']

            }
        } else if (props.payment_type === INBOUND_PAYMENT_FROM_INSURANCE_PARTNER) {
            if (props.formType === INSURANCE_PAYMENT_FROM_AGENCY_TO_INSURANCE_PARTNER) {
                headerName = "Payment From Agency to Insurance Partner";
                amountLabel = "Premium Amount Received";
                access = ROLE_FEATURES['payment_from_customer']
            } else if (props.formType === INSURANCE_PAYMENT_FROM_INSURANCE_PARTNER_TO_OTO) {
                headerName = "Payment From Insurance Partner to OTO";
                amountLabel = "Premium Amount Received";
                access = ROLE_FEATURES['payment_from_sales_team']

            }
        }
        else {
            headerName = "Referral Fee Payout";
            amountLabel = "Premium Amount Received";
            // eslint-disable-next-line
            headerType = "";
            access = ROLE_FEATURES['payment_from_customer_to_partner_broker']
        }
        setAmountLabel(amountLabel);
        setHeaderType(headerType);
        setHeaderName(headerName);
        checkFutureDateApplication()

        handleBtnAccessibility(access)

    }, [props.payment_type, props.formType, insuranceState.leadDetail]);


    useEffect(() => {
        let now: any = new Date();
        let oneYrBefore: any = new Date();
        oneYrBefore.setYear(now.getYear() - 1);

        setMinDateForAgency(oneYrBefore)

    }, [])


    useEffect(() => {
        if (inputFields.payment_tenure_id) {

            let updatedFields = { ...inputFields }
            let tenureLabel = inputFields.payment_tenure_label ? inputFields.payment_tenure_label : insuranceState?.leadDetail?.pay_tenure
            if (tenureLabel) {
                times((tenureLabel).split(" ")[0], (idx: number) => {
                    updatedFields = { ...updatedFields, [idx + 1 + "_installment_file_count"]: updatedFields[idx + 1 + "_installment_file_count"] ?? 0 }
                })
            }
            setInputFields((prev: any) => {
                return { ...prev, ...updatedFields }
            })

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputFields.payment_tenure_id, insuranceState.leadDetail])

    const resolveFileUpload = (filesStatus: any) => {
        setLoading(false);
        if (filesStatus.status) {
            dispatch(updateLeadDetailsInStore({ ...insuranceState.leadDetail, images: [...insuranceState.leadDetail.images, ...filesStatus.data] }));
            nextPageRedirect();
        }
    }

    const getBankDetail = (params: any) => {
        setBankDetails({ ...params });
    }

    const updatedFileCount = (param: any) => {
        if (param?.type === 'installment') {
            setInputFields((prev: any) => {
                return { ...prev, [param.key]: param.value }
            })
        }
        else {
            setFilesCount(param);
            setErrors((prev: any) => ({ ...prev, customerToPartnerDocument: '' }))
        }
    }

    const changeHandler = (data: any, key: string) => {
        if ([INSURANCE_PAYMENT_FROM_OTO_TO_INSURANCE_PARTNER, INSURANCE_PAYMENT_FROM_INSURANCE_PARTNER_TO_OTO].includes(props.formType)) {
            if (key === "commission_share") {

                let commisionEarnedByOto: any = ((data?.target?.value * insuranceState.leadDetail.net_premium) / 100).toFixed(2);
                let insurancePaymentReleasedAmount: any = (insuranceState.leadDetail.gross_premium - commisionEarnedByOto).toFixed(2);

                inputFields["commission_share"] = data?.target?.value
                inputFields["commission_earned_by_oto"] = commisionEarnedByOto
                inputFields["insurance_payment_released_amount"] = insurancePaymentReleasedAmount

            }
            setInputFields(inputFields)

        } else if (props.formType === REFERRAL_FEE_PAYOUT) {

            // if (key === "referral_fee_share") {
            //     let referralFeePayoutWhtInclusive: any = ((data?.target?.value * insuranceState.leadDetail.net_premium) / 100).toFixed(2);
            //     let referralFeePayoutWhtExclusive: any = ((referralFeePayoutWhtInclusive * 90) / 100).toFixed(2);
            //     inputFields["referral_fee_share"] = data?.target?.value;
            //     inputFields["referral_fee_payout_wht_inclusive"] = referralFeePayoutWhtInclusive;
            //     inputFields["referral_fee_payout_wht_exclusive"] = referralFeePayoutWhtExclusive;
            // } else 

            if (key === "referral_fee_payout_wht_inclusive") {
                inputFields[key] = data?.target?.value || ""

                let rawValue:any = HELPER.removeCurrencyFormatting(inputFields["referral_fee_payout_wht_inclusive"])
                let referralFeePayoutWhtExclusive: any = ( rawValue * REFERRAL_FEE_PAYOUT_WHT_EXCLUSIVE_RATE).toFixed(2);
                
                inputFields["referral_fee_payout_wht_exclusive"] = referralFeePayoutWhtExclusive;

            } else if (["referral_fee_reference_no"].includes(key)) {
                inputFields[key] = data?.target?.value || ""

            } else {

                inputFields[key] = data?.target?.value || data?.value || data || ''
            }

            setInputFields(inputFields)

        } else {
            
            let updatedFields = { ...inputFields }
            if (key === 'payment_method_id') {
                updatedFields = { ...updatedFields, payment_term_id: null, payment_term_label: null, payment_tenure_id: null, payment_tenure_label: null, expected_installment: null }
            }
            else if (key === 'payment_term_id') {
                updatedFields = { ...updatedFields, payment_tenure_id: null, payment_tenure_label: null }
                let paymentReArr: any = [];
                let paymentRefobj = { payment_id: '', amount_date: '', payment_reference_no: '' }
                paymentReArr.push(paymentRefobj);
                setPaymenyReferenceFields(paymentReArr)
            }


            //reset back to original expected installment amounts
            if (key === 'payment_tenure_id') {

                let tenureLabel = data?.label?.split(" ")[0]

                let paymentRefArray: any = []

                times((tenureLabel).split(" ")[0], (idx: number) => {

                    paymentRefArray = [
                        ...paymentRefArray,
                        {
                            amount_date: (paymenyReferenceFields?.[idx]?.['amount_date']) ? new Date(paymenyReferenceFields?.[idx]?.['amount_date']) : "",
                            payment_reference_no: (paymenyReferenceFields?.[idx]?.['payment_reference_no']) ? paymenyReferenceFields?.[idx]?.['payment_reference_no'] : ""
                        }]

                });

                setPaymenyReferenceFields(paymentRefArray)
            }

            // }
            let updatedData = {
                ...updatedFields,
                [key]: data?.value || data?.target?.value || "",
            }

            if (key.indexOf('_id') !== -1) {
                updatedData[key.replace('_id', "_label")] = data?.label

            }

            setInputFields(updatedData)
            // }

            checkFutureDateApplication()
        }
        setErrors((prev: any) => {
            return { ...prev, [key]: '' }
        })
    }

    useEffect(() => {
        if (insuranceState.leadDetail.customer_api_call) {
            //calling api to fetch installment details
            if (![PAYMENT_TYPE_OUTBOUND, REFERRAL_FEE_PAYOUT].includes(props.payment_type)) callPaymentAPI()


            if (props.payment_type === REFERRAL_FEE_PAYOUT) {
                setLoading(true)
                fetchLeadData({ "lead_id": props.leadHashId, apiType: API_TYPES['outboundData'] }).then((res: any) => {
                    if (res && res.data && Object.keys(res.data).length) {

                        let updatedLeadDetails = { ...res.data.bankDetails }

                        setErrors({});

                        dispatch(updateLeadDetailsInStore({ ...insuranceState.leadDetail, ...updatedLeadDetails }))
                    }
                }).finally(() => setLoading(false))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.formType, props.payment_type, insuranceState.leadDetail.customer_api_call])

    useEffect(() => {
        if (props.payment_type !== PAYMENT_TYPE_OUTBOUND) {
            //for updating installment document count
            if (insuranceState?.leadDetail?.images) {
                let installmentFileObj: any = {}
                insuranceState.leadDetail.images.forEach((ele: any) => {
                    if (ele && ele?.doc_id >= DOCUMENTS_CATEGORY_INBOUND['1_installment_file']) {
                        let key = Object.keys(DOCUMENTS_CATEGORY_INBOUND).find((e: any) => DOCUMENTS_CATEGORY_INBOUND[e as keyof typeof DOCUMENTS_CATEGORY_INBOUND] === ele.doc_id)
                        installmentFileObj = { ...installmentFileObj, [key + "_count"]: installmentFileObj[key + "_count"] ? installmentFileObj[key + "_count"] + 1 : 1 }
                    }
                })

                setInputFields((prev: any) => {
                    return { ...prev, ...installmentFileObj }
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insuranceState.leadDetail.images])

    const handleBtnAccessibility = (access: any) => {
        let pageSlug = "";

        if (+props.formType === INSURANCE_PAYMENT_FROM_AGENCY_TO_OTO_INSURANCE) {
            pageSlug = "payment_from_agency_to_oto_insurance";
        } else if (+props.formType === INSURANCE_PAYMENT_FROM_AGENCY_TO_INSURANCE_PARTNER) {
            pageSlug = "payment_from_agency_to_insurance_partner";
        } else if (+props.formType === INSURANCE_PAYMENT_FROM_OTO_TO_INSURANCE_PARTNER) {
            pageSlug = "payment_from_oto_to_insurance_partner";
        } else if (+props.formType === INSURANCE_PAYMENT_FROM_INSURANCE_PARTNER_TO_OTO) {
            pageSlug = "payment_from_insurance_partner_to_oto";
        } else {
            pageSlug = "referral_fee_payout";
        }
        // ? " : (+props.formType === INSURANCE_PAYMENT_FROM_OTO_TO_INSURANCE_PARTNER ? "payment_from_oto_to_insurance_partner" : "referral_fee_payout"));

        let saveBtnAccessibility = HELPER.isUserHasUpdateAccess({
            permissionID: access, accessType: 'edit', leadDetails: insuranceState.leadDetail,
            pageSlug: pageSlug,
            pageChildSlug: (+props.payment_type === INBOUND_PAYMENT_FROM_OTO_INSURANCE ? "oto_insurance" : (+props.payment_type === INBOUND_PAYMENT_FROM_INSURANCE_PARTNER ? "insurance_partner" : "referral_fee_payout")), pay_tenure: (insuranceState?.leadDetail?.payment_tenure_id ? (insuranceState?.leadDetail?.pay_tenure || inputFields.payment_tenure_label) : null)
        })
        setSaveBtnAccessibility(saveBtnAccessibility)
    }

    const callPaymentAPI = () => {
        setLoading(true)
        fetchLeadData({ "lead_id": props.leadHashId, apiType: "fetchAgencyPaymentsData", payment_type: props.payment_type, payment_from: props.formType }).then((res: any) => {

            if (res && res.data && Object.keys(res.data).length) {
                dispatch(updateLeadDetailsInStore({ ...insuranceState.leadDetail, ...res.data }))
            }
        }).finally(() => setLoading(false))
    }


    useEffect(() => {

        updateDocumentDocIds(props.formType, props.payment_type)

    }, [props.formType, props.payment_type])

    const updateDocumentDocIds = (formType: any, payment_type: any) => {
        let documentFormTypeState: any;
        let documentDocIdState: number[] = [];

        if ([REFERRAL_FEE_PAYOUT_OUTBOUND].includes(payment_type)) {
            documentFormTypeState = "referral_fee_payout"
            documentDocIdState = [DOCUMENT_CATEGORY['postDisbursal']['childs']['referral_fee_payout']];

        } else if ([INSURANCE_PAYMENT_FROM_AGENCY_TO_OTO_INSURANCE].includes(formType)) {
            documentFormTypeState = "payment_from_agency_to_oto_insurance"
            documentDocIdState = [DOCUMENT_CATEGORY['postDisbursal']['childs']['payment_from_agency_to_oto_insurance'], 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26]


        } else if ([INSURANCE_PAYMENT_FROM_OTO_TO_INSURANCE_PARTNER].includes(formType)) {
            documentFormTypeState = "payment_from_oto_to_insurance_partner"
            documentDocIdState = [DOCUMENT_CATEGORY['postDisbursal']['childs']['payment_from_oto_to_insurance_partner']]

        } else if ([INSURANCE_PAYMENT_FROM_AGENCY_TO_INSURANCE_PARTNER].includes(formType)) {
            documentFormTypeState = "payment_from_agency_to_insurance_partner"
            documentDocIdState = [DOCUMENT_CATEGORY['postDisbursal']['childs']['payment_from_agency_to_insurance_partner'], 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26]

        } else if ([INSURANCE_PAYMENT_FROM_INSURANCE_PARTNER_TO_OTO].includes(formType)) {
            documentFormTypeState = "payment_from_insurance_partner_to_oto"
            documentDocIdState = [DOCUMENT_CATEGORY['postDisbursal']['childs']['payment_from_insurance_partner_to_oto']]

        }

        setDocumentDocId(documentDocIdState)
        setDocumentFormType(documentFormTypeState)
    }

    const getReference = async (e: any, idx: number) => {
        e.preventDefault();
        let leadDetail: any = JSON.parse(JSON.stringify(insuranceState?.leadDetail));
        if (e.target.value) {
            let paymentobj: any = {
                payment_reference_no: e.target.value
            }
            if (!leadDetail?.bulk_payment_id) {
                getReferenceData(paymentobj, idx);
            }
        } else {
            //IF DATA PREFILLED THEN REMOVE THE DATA ON REF NO RESET
            if (isDataAutoPrefilledRefNo) {
                let doc_id = 15;

                let payment_reference: any = leadDetail?.payment_reference_arr;
                if (payment_reference?.length) {
                    payment_reference[idx]["payment_reference_no"] = '';
                }
                if (leadDetail && leadDetail?.payment_term_id === 1) {
                    doc_id = (leadDetail && leadDetail?.paid_to_whom === 4) ? 63 : 65;
                } else {
                    doc_id = doc_id + idx;
                }
                let updateImages: any = leadDetail?.images.filter((el: any) => el.doc_id !== doc_id);
                leadDetail = {
                    ...leadDetail,
                    images: updateImages,
                    payment_reference_arr: payment_reference,
                    is_payment_freezed: null,
                    bulk_payment_id: null
                }

                setLeadDetails({ ...insuranceState.leadDetail, ...leadDetail });
                let updatedState = { ...insuranceState.leadDetail, ...leadDetail }

                dispatch(updateLeadDetailsInStore(updatedState));
            }
        }


    }
    const getReferenceData = (params: object, idx: number) => {
        setLoading(true);
        getBulkPaymentList.getReferencePaymentData(params).then((res: any) => {
            if (res && res.status === 200) {
                let data: any = res?.data || {};
                if (data && Object.keys(data).length) {

                    let leadDeatils: any = insuranceState.leadDetail;
                    let updateImages: any = leadDeatils.images;
                    updateImages = data?.images?.length ? updateImages?.concat(data.images) : updateImages;
                    leadDeatils = {
                        ...leadDeatils,
                        images: updateImages,
                        payment_reference_arr: data.payment_reference_arr, payment_method_id: data.payment_method,
                        payment_tenure_id: data.payment_tenure,
                        payment_term_id: data.payment_term,
                        credit_term: data.credit_terms,
                        payment_way: data.payment_way,
                        bulk_payment_id: data.bulk_payment_id,
                        is_payment_freezed: data.is_payment_freezed
                    }
                    console.log("leadDeatilsleadDeatils", leadDeatils)
                    setLeadDetails({ ...insuranceState.leadDetail, ...leadDeatils });
                    let updatedState = { ...insuranceState.leadDetail, ...leadDeatils }

                    dispatch(updateLeadDetailsInStore(updatedState));

                    setIsDataAutoPrefilledRefNo(true)

                }
            }
            setLoading(false);
        })
    }

    /**
     * SWITCH BACK PAYEE
     */
    const switchPayee = () => {
        // let amountLabel = "";
        // let access = 0;
        // if (props.payment_type === INBOUND_PAYMENT_FROM_OTO_INSURANCE) {
        //     if (props.formType === INSURANCE_PAYMENT_FROM_AGENCY_TO_OTO_INSURANCE) {
                
        //         headerName = "Payment From Agency to Insurance Partner";
        //         amountLabel = "Premium Amount Received";
        //         access = ROLE_FEATURES['payment_from_sales_team']
        //     } else if (props.formType === INSURANCE_PAYMENT_FROM_OTO_TO_INSURANCE_PARTNER) {
        //         headerName = "Payment From Insurance Partner to OTO";
        //         amountLabel = "Premium Amount Received";
        //         access = ROLE_FEATURES['payment_from_customer']

        //     }
        // } else if (props.payment_type === INBOUND_PAYMENT_FROM_INSURANCE_PARTNER) {
        //     if (props.formType === INSURANCE_PAYMENT_FROM_AGENCY_TO_INSURANCE_PARTNER) {
                
        //         headerName = "Payment From Agency to OTO Insurance";
        //         amountLabel = "Premium Amount Received";
        //         access = ROLE_FEATURES['payment_from_sales_team']
        //     } else if (props.formType === INSURANCE_PAYMENT_FROM_INSURANCE_PARTNER_TO_OTO) {
        //         headerName = "Payment From OTO to Insurance Partner";
        //         amountLabel = "Premium Amount Received";
        //         access = ROLE_FEATURES['payment_from_customer']

        //     }
        // }

        // setAmountLabel(amountLabel);
        // setHeaderType(headerType);
        // setHeaderName(headerName);
        // checkFutureDateApplication()

        // handleBtnAccessibility(access)

        // let paymentType = (props.payment_type === INBOUND_PAYMENT_FROM_OTO_INSURANCE ? INBOUND_PAYMENT_FROM_INSURANCE_PARTNER : INBOUND_PAYMENT_FROM_OTO_INSURANCE);
        // let paymentForm = (props.formType === INSURANCE_PAYMENT_FROM_AGENCY_TO_OTO_INSURANCE ? INSURANCE_PAYMENT_FROM_OTO_TO_INSURANCE_PARTNER : INSURANCE_PAYMENT_FROM_AGENCY_TO_OTO_INSURANCE);

        // updateDocumentDocIds(paymentForm, paymentType)

        dispatch(setInboundPaymentFromResponse((props.payment_type === INBOUND_PAYMENT_FROM_INSURANCE_PARTNER ? "oto_insurance" : "insurance_partner")));

        if (+props.formType === INSURANCE_PAYMENT_FROM_AGENCY_TO_OTO_INSURANCE) {

            navigate(`/details/payment-from-agency-to-insurance-partner/${props.leadHashId}`);
        } else if (+props.formType === INSURANCE_PAYMENT_FROM_AGENCY_TO_INSURANCE_PARTNER) {

            navigate(`/details/payment-from-agency-to-oto-insurance/${props.leadHashId}`);
        }


    }

    const confirmNo = () => {
        setShowConfirmation(false);
    }

    const confirmYes = async () => {

        switchPayee();
        setShowSwitchPayee(false)

        setInputFields((prev:any)=>({
            ...prev,
            payment_method_id: 0,
            payment_term_id: 0,
            payment_tenure_id:0,
            // payment_way: 0,
            credit_term: 0,
            premium_amount: 0

        }))

        setPaymenyReferenceFields([])
        setDocumentDocId([])
        setShowConfirmation(false);


    }

    const getPremiumAmountType = () => {

        let amountType = "";
        if((""+insuranceState.leadDetail.gross_premium) === (""+HELPER.removeCurrencyFormatting(inputFields['premium_amount']))){
            amountType = "Gross Premium"
        }else if(""+insuranceState.leadDetail.pending_payment_net_of_commission === ""+inputFields.premium_amount){
            amountType = "Pending Payment Net of Commissions"
        }else{
            amountType = "User Input"
        }
        return amountType
    }
    
    return (
        <div className="image-form-outer">
            <FullPageLoader show={loading} />

            <div className="image-tab-panel">
                <DocumentGallery formType={(props.payment_type === PAYMENT_TYPE_INBOUND) ? "inbound_payment" : props.payment_type === PAYMENT_TYPE_OUTBOUND ? "outbound_payment" : "payment_from_customer_to_partner_broker"} payment_from={props.formType} />
            </div>
            <div className="form-tab-right-panel">
                <div className="lead-detail-from-outer">
                    <div className="lead-form-heading">
                        <span className="main-heading">{headerType}</span>
                    </div>
                    <div className="lead-form-filed radio-btn payment-paid-form agency-payment-form">
                        <div className="row">
                            <div className="col-md-12">
                                
                                <p className="agency-payment-headers">
                                    <h3>{headerName}</h3>
                                    {
                                        (showSwitchPayee && !insuranceState.leadDetail.insurance_agency_payment_id && insuranceState.leadDetail.bulk_payment_source && insuranceState.leadDetail.bulk_payment_source !== SOURCE_WEB) &&  [INSURANCE_PAYMENT_FROM_AGENCY_TO_OTO_INSURANCE, INSURANCE_PAYMENT_FROM_AGENCY_TO_INSURANCE_PARTNER].includes(props.formType) &&
                                        <button className="switchPayee btn-line" onClick={()=>setShowConfirmation(true)}><i className="ic-spinner9"></i>Switch Payee</button>
                                    }
                                </p>
                            </div>


                            {
                                // displayInstallmentForm && paymentTermOptions?.find((v: any) => inputFields?.payment_term_id === v.value)?.label === "Installment"
                                [INSURANCE_PAYMENT_FROM_AGENCY_TO_OTO_INSURANCE, INSURANCE_PAYMENT_FROM_AGENCY_TO_INSURANCE_PARTNER].includes(props.formType)
                                    ?
                                    <>

                                        <ReactSelect
                                            options={commonState?.common?.payment_method_agency}
                                            value={commonState?.common?.payment_method_agency?.find((v: any) => inputFields.payment_method_id === v.value) || null}
                                            placeholder={"Payment Method*"}
                                            isDisabled={insuranceState.leadDetail.isPaymentWayDisabled || insuranceState.leadDetail.insurance_agency_payment_id || insuranceState?.leadDetail?.is_payment_freezed}
                                            fieldKey="payment_method_id"
                                            handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)} fieldSetClassName="col-md-6"
                                            errorField={errors.payment_method_id}
                                        />

                                        <ReactSelect
                                            options={commonState?.common?.payment_term}
                                            value={commonState?.common?.payment_term?.find((v: any) => inputFields.payment_term_id === v.value) || null}
                                            placeholder={"Payment Term*"}
                                            isDisabled={insuranceState.leadDetail.isPaymentWayDisabled || insuranceState.leadDetail.insurance_agency_payment_id || insuranceState?.leadDetail?.is_payment_freezed}
                                            handleChange={(e: any, fieldKey: string) => changeHandler(e, "payment_term_id")} fieldKey="payment_term_id"
                                            fieldSetClassName="col-md-6"
                                            errorField={errors.payment_term_id} />

                                        {
                                            (inputFields?.payment_tenure_id > 0 || inputFields?.payment_term_id === 2) &&
                                            <ReactSelect
                                                options={commonState?.common?.payment_tenure?.filter((pay: any) => pay.payment_method === inputFields.payment_method_id && pay.payment_term === inputFields.payment_term_id)?.map((el:any)=>({...el, label: el.label_for_agency}))}
                                                value={commonState?.common?.payment_tenure?.find((v: any) => inputFields.payment_tenure_id === v.value) || null}
                                                placeholder={"Number of Payments*"}
                                                isDisabled={(insuranceState.leadDetail.insurance_agency_payment_id || insuranceState?.leadDetail?.is_payment_freezed) ? true : false}
                                                fieldKey="payment_tenure_id"
                                                handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)}
                                                fieldSetClassName="col-md-6"
                                                errorField={errors.payment_tenure_id} />
                                        }

                                        <ReactSelect
                                            options={commonState?.common?.payment_way}
                                            value={commonState?.common?.payment_way?.find((v: any) => inputFields.payment_way === +v.value) || []}
                                            placeholder={"Payment Way*"}
                                            isDisabled={insuranceState.leadDetail.bulk_payment_id || insuranceState.leadDetail.insurance_agency_payment_id || insuranceState?.leadDetail?.is_payment_freezed}
                                            handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)} fieldKey="payment_way"
                                            fieldSetClassName="col-md-6"
                                            errorField={errors['payment_way']}

                                        />


                                        <ReactSelect
                                            options={commonState?.common?.agency_credit_terms}
                                            value={commonState?.common?.agency_credit_terms?.find((v: any) => +inputFields?.credit_term === v.value) || null}
                                            placeholder={"Credit Term*"}
                                            isDisabled={insuranceState.leadDetail.insurance_agency_payment_id || insuranceState?.leadDetail?.is_payment_freezed}
                                            handleChange={(e: any, fieldKey: string) => changeHandler(e, "credit_term")} fieldKey="credit_term"
                                            fieldSetClassName="col-md-6"
                                            errorField={errors.credit_term} />


                                        <fieldset className="col-md-6">
                                            <div className="material">
                                                {
                                                    <CurrencyInputField
                                                        inputProps={{
                                                            id: "premium_amount",
                                                            type: "text",
                                                            name: "premium_amount",
                                                            placeholder: " ",
                                                            value: inputFields.premium_amount,
                                                            className: "form-input",
                                                            disabled: insuranceState.leadDetail.insurance_agency_payment_id
                                                        }}

                                                        onChange={(e: any) => changeHandler(e, "premium_amount")}
                                                    />
                                                }

                                                <label data-label={"Total Premium Amount Received*"} className="form-label"></label>
                                                <span className="error-txt">
                                                    {errors.premium_amount || ""}
                                                </span>
                                            </div>
                                        </fieldset>

                                        <fieldset className="col-md-6">
                                            <div className="material">
                                                {
                                                    <InputField
                                                        inputProps={{
                                                            id: "premium_amount_type",
                                                            type: "text",
                                                            name: "premium_amount_type",
                                                            placeholder: " ",
                                                            value: getPremiumAmountType(),
                                                            className: "form-input",
                                                            disabled: true
                                                        }}

                                                        onChange={(e: any) => changeHandler(e, "premium_amount")}
                                                    />
                                                }

                                                {/* <label data-label={"Total Premium Amount Received*"} className="form-label"></label> */}
                                                {/* <span className="error-txt">
                                                    {errors.premium_amount || ""}
                                                </span> */}
                                            </div>
                                        </fieldset>

                                        {
                                            paymenyReferenceFields && paymenyReferenceFields?.map((e: any, key: number) =>
                                                <>
                                                    <fieldset className="col-md-12">
                                                        <div className={(e.amount_date) ? "active-label material animation-effect" : "material animation-effect "}>
                                                            {/* <InsuranceDate
                                                                text={amountLabel + ` Date & Time ${inputFields?.payment_tenure_id > 0 ? key + 1 : ''}*`} 
                                                                insurancePolicyDate={e.amount_date}
                                                                onChange={(ev: any) => handleChange(`amount_date_${key}`, ev, key)}
                                                                disabled={e.payment_id}
                                                                minDate={minDateForAgency}
                                                                enableAllDates={true}
                                                            /> */}
                                                            <ExpectedInstallmentDate
                                                                onChange={(ev: any) => handleChange(`amount_date_${key}`, ev, key)}
                                                                selected={e.amount_date}
                                                                dateFormat="dd MMM, yyyy h:mm aa"
                                                                // disabled={e.payment_id || insuranceState?.leadDetail?.is_payment_freezed}
                                                                //|| (key>0 && !paymenyReferenceFields?.[key-1]?.payment_reference_no)
                                                                disabled={e.payment_id && (insuranceState?.leadDetail?.insurance_agency_payment_id || insuranceState?.leadDetail?.is_payment_freezed)}
                                                                installmentNumber={key + 1}
                                                                prevInstallmentDate={paymenyReferenceFields?.[key - 1]?.amount_date || null}
                                                                text={`Premium  ${inputFields?.payment_tenure_id > 0 ? key + 1 : ''} Date${key > 0 ? "" : "*"}`}
                                                                source="detailSection"
                                                                showTimeSelect={true}

                                                                minDateAgency={new Date((new Date().getFullYear() - 1) + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate())}
                                                            />
                                                            <span className="error-txt">
                                                                {errors[`amount_date_${key}`] || ""}
                                                            </span>
                                                        </div>
                                                    </fieldset>

                                                    <fieldset className="col-md-12">
                                                        <div className="material" title={inputFields.payment_reference_no}>
                                                            <InputField
                                                                inputProps={{
                                                                    id: `payment_reference_no_${key}`,
                                                                    name: `payment_reference_no_${key}`,
                                                                    className: "form-input",
                                                                    type: "text",
                                                                    value: e.payment_reference_no,
                                                                    maxLength: "",
                                                                    // disabled: e.payment_id,
                                                                    disabled: e.payment_id && (insuranceState?.leadDetail?.insurance_agency_payment_id || insuranceState?.leadDetail?.is_payment_freezed),

                                                                    // disabled: e.payment_id || insuranceState?.leadDetail?.is_payment_freezed,
                                                                    placeholder: " "
                                                                }}
                                                                onChange={(ev: any) => handleChange(`payment_reference_no_${key}`, ev, key)}
                                                                // onBlur={(e: any) => { getReference(e, key) }}
                                                            />
                                                            <label data-label={`Payment  ${inputFields?.payment_tenure_id > 0 ? key + 1 : ''} Reference${key > 0 ? "" : "*"}`} className="form-label"></label>
                                                            {
                                                                errors[`payment_reference_no_${key}`] ?
                                                                    <span className="error-txt">{errors[`payment_reference_no_${key}`]}</span>
                                                                    : ''
                                                            }
                                                        </div>
                                                    </fieldset>
                                                </>
                                            )
                                        }

                                    </>
                                    : ''
                            }


                            {

                                [INSURANCE_PAYMENT_FROM_OTO_TO_INSURANCE_PARTNER, INSURANCE_PAYMENT_FROM_INSURANCE_PARTNER_TO_OTO].includes(props.formType)
                                    ?
                                    <>
                                        <fieldset className="col-md-12">
                                            <div className="material">
                                                {
                                                    <InputField
                                                        inputProps={{
                                                            id: "percentage",
                                                            type: "text",
                                                            name: "commission_share",
                                                            placeholder: " ",
                                                            value: inputFields.commission_share || "",
                                                            className: "form-input",
                                                            disabled: false,
                                                            maxLength: "100"

                                                        }}

                                                        onChange={(e: any) => changeHandler(e, "commission_share")}
                                                    />
                                                }

                                                <label data-label={"Commision Share*"} className="form-label"></label>
                                                <span className="error-txt">
                                                    {errors.commission_share || ""}
                                                </span>
                                            </div>
                                        </fieldset>

                                        <fieldset className="col-md-12">
                                            <div className="material">
                                                {
                                                    <CurrencyInputField
                                                        inputProps={{
                                                            id: "commission_earned_by_oto",
                                                            type: "text",
                                                            name: "commission_earned_by_oto",
                                                            placeholder: " ",
                                                            value: inputFields.commission_earned_by_oto,
                                                            className: "form-input",
                                                            disabled: true
                                                        }}

                                                        onChange={(e: any) => changeHandler(e, "commission_earned_by_oto")}
                                                    />
                                                }

                                                <label data-label={"Commision Earnerd by OTO"} className="form-label"></label>
                                                <span className="error-txt">
                                                    {errors.commission_earned_by_oto || ""}
                                                </span>
                                            </div>
                                        </fieldset>

                                        {
                                            [INSURANCE_PAYMENT_FROM_OTO_TO_INSURANCE_PARTNER].includes(props.formType) &&

                                            <fieldset className="col-md-12">
                                                <div className="material">
                                                    {
                                                        <CurrencyInputField
                                                            inputProps={{
                                                                id: "insurance_payment_released_amount",
                                                                type: "text",
                                                                name: "insurance_payment_released_amount",
                                                                placeholder: " ",
                                                                value: inputFields.insurance_payment_released_amount,
                                                                className: "form-input",
                                                                disabled: true
                                                            }}

                                                            onChange={(e: any) => changeHandler(e, "insurance_payment_released_amount")}
                                                        />
                                                    }

                                                    <label data-label={"Insurance Payment Released Amount"} className="form-label"></label>
                                                    <span className="error-txt">
                                                        {errors.insurance_payment_released_amount || ""}
                                                    </span>
                                                </div>
                                            </fieldset>
                                        }

                                    </>
                                    : ""

                            }

                            {

                                props.formType === REFERRAL_FEE_PAYOUT
                                    ?
                                    <>

                                        <ReactSelect
                                            options={commonState?.common?.referral_fee_scheme}
                                            value={commonState?.common?.referral_fee_scheme?.find((v: any) => inputFields.referral_fee_scheme === v.value) || null}
                                            placeholder={"Referral Fee Scheme*"}
                                            fieldKey="referral_fee_scheme"
                                            handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)}
                                            fieldSetClassName="col-md-12"
                                            errorField={errors.referral_fee_scheme} 
                                            isDisabled={insuranceState.leadDetail.earned_referral_fee_scheme ? true : false}
                                            />

                                        {/* <fieldset className="col-md-12">
                                            <div className="material">
                                                {
                                                    <InputField
                                                        inputProps={{
                                                            id: "percentage",
                                                            type: "text",
                                                            name: "referral_fee_share",
                                                            placeholder: " ",
                                                            value: inputFields.referral_fee_share || "",
                                                            className: "form-input",
                                                            disabled: false,
                                                            maxLength: "40"

                                                        }}

                                                        onChange={(e: any) => changeHandler(e, "referral_fee_share")}
                                                    />
                                                }

                                                <label data-label={"Referral Fee Share*"} className="form-label"></label>
                                                <span className="error-txt">
                                                    {errors.referral_fee_share || ""}
                                                </span>
                                            </div>
                                        </fieldset> */}

                                        <fieldset className="col-md-12">
                                            <div className="material">
                                                {
                                                    <CurrencyInputField
                                                        inputProps={{
                                                            id: "referral_fee_payout_wht_inclusive",
                                                            type: "text",
                                                            name: "referral_fee_payout_wht_inclusive",
                                                            placeholder: " ",
                                                            value: inputFields.referral_fee_payout_wht_inclusive,
                                                            className: "form-input",
                                                            // disabled: true
                                                        }}

                                                        onChange={(e: any) => changeHandler(e, "referral_fee_payout_wht_inclusive")}
                                                    />
                                                }

                                                <label data-label={"Referral Fee Payout (WHT Inclusive)"} className="form-label"></label>
                                                <span className="error-txt">
                                                    {errors.referral_fee_payout_wht_inclusive || ""}
                                                </span>
                                            </div>
                                        </fieldset>

                                        <fieldset className="col-md-12">
                                            <div className="material">
                                                {
                                                    <CurrencyInputField
                                                        inputProps={{
                                                            id: "referral_fee_payout_wht_exclusive",
                                                            type: "text",
                                                            name: "referral_fee_payout_wht_exclusive",
                                                            placeholder: " ",
                                                            value: inputFields.referral_fee_payout_wht_exclusive,
                                                            className: "form-input",
                                                            disabled: true
                                                        }}

                                                        onChange={(e: any) => changeHandler(e, "referral_fee_payout_wht_exclusive")}
                                                    />
                                                }

                                                <label data-label={"Referral Fee Payout (WHT Exclusive)"} className="form-label"></label>
                                                <span className="error-txt">
                                                    {errors.referral_fee_payout_wht_exclusive || ""}
                                                </span>
                                            </div>
                                        </fieldset>

                                        <fieldset className="col-md-12">
                                            <div className={(inputFields.referral_payout_date_time) ? "active-label material animation-effect" : "material animation-effect "}>
                                                <InsuranceDate
                                                    text={"Referral Fee Payout Date & Time*"}
                                                    insurancePolicyDate={inputFields.referral_payout_date_time}
                                                    minDate={minDateForAgency}
                                                    enableAllDates={true}
                                                    onChange={(ev: any) => changeHandler(ev, `referral_payout_date_time`)}
                                                />
                                                <span className="error-txt">
                                                    {errors.referral_payout_date_time || ""}
                                                </span>
                                            </div>
                                        </fieldset>
                                    </>
                                    : ""

                            }


                            {[PAYMENT_TYPE_OUTBOUND, REFERRAL_FEE_PAYOUT_OUTBOUND].includes(props.payment_type) && <BankDetails getBankDetail={getBankDetail} errors={errors} formType={props.formType} updateErrors={(data: any) => setErrors({ ...data })} />}


                            {

                                props.formType === REFERRAL_FEE_PAYOUT
                                    ?
                                    <>
                                        <fieldset className="col-md-12">
                                            <div className="material">
                                                {
                                                    <InputField
                                                        inputProps={{
                                                            id: "tin_id_no",
                                                            type: "text",
                                                            name: "tin_id_no",
                                                            placeholder: " ",
                                                            value: inputFields.tin_id_no,
                                                            className: "form-input",
                                                            disabled: true,
                                                            maxLength: "40"

                                                        }}

                                                        onChange={(e: any) => changeHandler(e, "tin_id_no")}
                                                    />
                                                }

                                                <label data-label={"TIN-ID*"} className="form-label"></label>
                                                <span className="error-txt">
                                                    {errors.tin_id_no || ""}
                                                </span>
                                            </div>
                                        </fieldset>


                                        <fieldset className="col-md-12">
                                            <div className="material">
                                                {
                                                    <InputField
                                                        inputProps={{
                                                            id: "text",
                                                            type: "text",
                                                            name: "referral_fee_reference_no",
                                                            placeholder: " ",
                                                            value: inputFields.referral_fee_reference_no || "",
                                                            className: "form-input",
                                                            disabled: false,
                                                            maxLength: "40"

                                                        }}

                                                        onChange={(e: any) => changeHandler(e, "referral_fee_reference_no")}
                                                    />
                                                }

                                                <label data-label={"Referral fee reference number"} className="form-label"></label>
                                                <span className="error-txt">
                                                    {errors.referral_fee_reference_no || ""}
                                                </span>
                                            </div>
                                        </fieldset>
                                    </>
                                    : ""
                            }

                        </div>
                        <Documents docTypeId={[DOCUMENT_CATEGORY['postDisbursal']['doc_type_id']]}
                            docId={documentDocId}
                            formType={documentFormType}
                            resolveFileUpload={resolveFileUpload}
                            submitClicked={submitClicked}
                            updatedFileCount={updatedFileCount}
                            errField={errors}
                            installmentDetails={paymenyReferenceFields ? inputFields : {}}
                            leadDetails={insuranceState.leadDetail}
                            setLoading={setLoading}
                            isDataAutoPrefilledRefNo={isDataAutoPrefilledRefNo}
                            updateIsDataAutoPrefilledRefNo={(value: any) => setIsDataAutoPrefilledRefNo(value)}
                        />


                        <div className="btn-save-remarks m-lg-t">
                            {
                                saveBtnAccessibility
                                    ?
                                    <button type="button" className="btn-primary" onClick={(e: any) => handleSubmit(e)}>{buttonText}</button>
                                    :
                                    <button type="button" className="btn-primary" disabled>{buttonText}</button>

                            }
                            <LeadRemarksButton leadId={props.leadHashId} />
                        </div>

                        {showConfirmation && (
                            <ConfirmPopup
                                confirmNo={confirmNo}
                                confirmYes={confirmYes}
                                message={"are you sure you want to switch payee?"} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AgencyInboundOutBoundPaymentProps;