import {useState,useEffect} from 'react' 
import InsuranceService from 'services/InsuranceService';
import DynamicFormField from '../create/DynamicFormField';  
import { APP_TYPE_AGENCY, APP_TYPE_WITH_UCF, INS_TYPE_COMPREHENSIVE} from '@src/config/constant';


import { getFormConfig } from '@src/store/slices/insurance';
import { useDispatch, useSelector } from 'react-redux';
import { getMasterConfig } from '@src/store/slices/common';

const  InsuranceForm = (props:any) => {
 
    const [sectionList, setSectionList] = useState([]) 
      // eslint-disable-next-line
    const [masterData, setMasterData] = useState<any>(undefined) 
    const dispatch: any = useDispatch();
    const insuranceState = useSelector((state: any) => state.insurance);
    const commonState = useSelector((state: any) => state.common);


    useEffect(()=>{
        fetchMasterConfig() 
        // eslint-disable-next-line
    },[]);

    const showHideAddons = (action: any) => {
        props.showHideAddons(action); 
    }

    const fetchMasterConfig = async () => { 
        let result = null
        if (!commonState.common || !Object.keys(commonState?.common)?.length) {
            result = await dispatch(getMasterConfig())
        } else {
            result = { data: commonState.common }
        }
        if (result?.data) {
            setMasterData(result.data)
            fetchFormConfig(result.data) 
        }  
    } 

    const fetchFormConfig = async (record:any) => { 
        let applicationType =props?.leadDetails?.rule_engine_id;
        let masterData = record;
        const leadDetails:any = { 
            make_year:props?.leadDetails?.mfg_year,
            insurance_type:props.leadDetails.insurance_type_id, 
            car_fmv: props.leadDetails.fmv_car ,
            vehicle_usage: props.leadDetails.vehicle_id,
            vehicle_type:props.leadDetails.vehicle_type_id,
            tenure:props.leadDetails.tenure,
            acc_price:props.leadDetails.accessories_price ,
            acc_description: props.leadDetails.accessories_description,
            car_under_finance: props.leadDetails.is_under_financing,
            insurance_exists: props.leadDetails.insurance_exists,
            make: props.leadDetails.make,
            make_name: props.leadDetails.make_name,
            model: props.leadDetails.model,
            model_name: props.leadDetails.model_name,
            version: props.leadDetails.version,
            version_name: props.leadDetails.version_name,
            insurer_name: props.leadDetails.insurer_name,
            expiration_date: props.leadDetails.insurance_expire_date,
            financier: props.leadDetails.financier_id,
            finance_from_date: props.leadDetails.financing_from_date,
            finance_to_date: props.leadDetails.financing_to_date,
            financier_name: props.leadDetails.financier_name,
        } 

        if(props?.leadDetails?.application_type_id === APP_TYPE_WITH_UCF) {
            leadDetails['proposed_fmv'] = props?.leadDetails?.quotes?.proposed_fmv || 0
        }

        let result = null
        // if (!insuranceState?.formConfig?.length) {
        result = await dispatch(getFormConfig(applicationType)) // adding response to redux --- await InsuranceService.getFormConfig(applicationType)
        // } else result = insuranceState.formConfig

        if (result?.insuranceFormFields?.length) {
            // let calculateFmv = await fetchFmv(props.leadDetails); //no need to fetch car price
            let tempList = JSON.parse(JSON.stringify(result?.insuranceFormFields));
            if(masterData){
                if(props.leadDetails.application_type_id === APP_TYPE_AGENCY && tempList?.[0]?.['blocks']?.[0]?.['fields']){
                    
                    //GET OTHER FINANCIER FIELD
                    let otherFinancierName = result.agencyAppFormField?.[1]?.['blocks']?.[0]?.['fields']?.find((elm:any)=> elm.name == 'financier_name')
                    tempList?.[0]?.['blocks']?.[0]?.['fields']?.splice(18, 0, otherFinancierName);

                    tempList[0]['blocks'][0]['fields'] = tempList?.[0]?.['blocks']?.[0]?.['fields']?.map((elm:any)=>{
                        if(elm?.name === 'car_under_finance') {
                            elm.label = "Existing loan against the vehicle"
                        }

                        return elm
                    })
                }

                // eslint-disable-next-line 
                tempList.map((sectionItem: any) => {
                    // eslint-disable-next-line 
                    sectionItem?.blocks?.map((blockItem: any) => {
                        // eslint-disable-next-line 
                        blockItem?.fields?.map((fieldItem: any, idx: number) => {

                            if (fieldItem.name === 'make_year') {
                                fieldItem['options'] =  masterData[fieldItem.name][props.insuranceType === INS_TYPE_COMPREHENSIVE ? 'mgf_comprehensive':'mgf_ctpl']
                            } else if (fieldItem.name in masterData) {
                                fieldItem['options'] = masterData[fieldItem.name]

                                if (fieldItem.name === 'insurance_type') {                                    
                                    fieldItem['is_enable'] =false
                                    
                                }
                            }  
                            if (leadDetails.hasOwnProperty(fieldItem.name) ) {
                                fieldItem.value = leadDetails[fieldItem.name]
                            }

                            //CHANGES FOR AGENCY FLOW
                            if (fieldItem.name === 'financier' && props.leadDetails.application_type_id === APP_TYPE_AGENCY) {
                                fieldItem.options = [...fieldItem.options, {value: 0, label: "Other"}]
                            }

                            if (fieldItem.name === "insurance_exists" && props.leadDetails.application_type_id === APP_TYPE_AGENCY) {
                                fieldItem.is_show = false
                            }
                            // if (fieldItem.name === 'car_fmv') {
                            //     fieldItem['value'] = calculateFmv; //no need to fetch car price
                            // }
                            
                        })
                    })
                })
            }  
            setSectionList(tempList)
        } 
    }
    const onValueChange = (fieldItem:any) => { 
        props.onValueChange(fieldItem);
    }


    const onSaveNextPress = () => {
        props.calculateInsurance(sectionList); 
    } 

    
    const fetchFmv = async (leadDetails: any) => {
        const params = {
            rule_engine: leadDetails.rule_engine_id,
            vehicle_usage: leadDetails.vehicle_id,
            version_id: leadDetails.version,
            mfg_year: leadDetails?.mfg_year,
            apptype: leadDetails.application_type_id
        }
        let response:any = {};
        if(leadDetails.version){
            response = await InsuranceService.getCarFMV(params);
        }
        return response?.data?.data?.price?.price || leadDetails.car_fmv;
    }
    return (
        <div className="loan-form-filed-scroll">
            <div className="loan-filed-sec">
                <div className="form-group-bx">
                    <h3>Universal Application Form</h3>
                    <div className="row">
                        
                        <DynamicFormField
                            fields={sectionList?.[0]?.['blocks']?.[0]['fields']}
                            applicationType={props?.leadDetails?.rule_engine_id}
                            onValueChange={onValueChange}
                            onVersionSelect={props.onVersionSelect}
                            rowClass="col-md-6"
                            hideFieldType={["select_addon"]}
                            formType="EDIT"
                            apptype={props?.leadDetails?.application_type_id}
                        />
                        
                        <fieldset className="form-filed col-md-6">
                            <div className='addons addons-filed'>
                                <div className="addonwrapper">
                                    <label className="selectedWrapper">{props.addonCount} Add Ons Selected</label>
                                    <button className="btn-line btn-addon" onClick={showHideAddons.bind(this, true)}>Select Add Ons  <i className="ic-keyboard_arrow_right"></i></button>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div className="btn-save-remarks">
                    <button type="button" className="btn-primary" onClick={onSaveNextPress}>Calculate</button>
                    <button type="button" className="btn-line" onClick={props.toggleswitch.bind(this, 'SUMMARY')}>Cancel</button>
                </div>

            </div>
        </div>
    )
}

export default InsuranceForm